import { AWFormFieldErrorName, AWFormFieldErrors, AWFormFieldTexts, AWFormVisibleFields } from './forms.types';

const valueSpecificTypes = ['options', 'labels', 'hints'];

export const getText = (labels: AWFormFieldTexts, name: string, type: 'label' | 'labels' | 'placeholder' | 'options' | 'hint' | 'hints', value?: string): string | null => {
  const sanitizedName = name.replace(/\[[0-9]*\]/gi, '[*]').replace(/\.[0-9]*\./, '.*.');
  if(!labels[sanitizedName]){
    return null;
  }
  if(!labels[sanitizedName]![type]){
    return null;
  }
  if(valueSpecificTypes.indexOf(type) !== -1 && value){
    return (labels[sanitizedName][type] as { [value: string]: string })[value]
      ? (labels[sanitizedName][type] as { [value: string]: string })[value]
      : type !== 'hints' ? value : null; // Specific for 'hints', if not provided, we should not display anything
  }
  return labels[sanitizedName]![type] ? labels[sanitizedName]![type]! as string : null;
}

export const getErrorText = (errors: AWFormFieldErrors, name: AWFormFieldErrorName): string => {
  if(!errors[name]){
    return name;
  }
  return errors[name]!;
}


export const shouldDisplayField = (fieldName: string, visibleFields?: AWFormVisibleFields) => {
  return visibleFields ? visibleFields[fieldName] : true;
};