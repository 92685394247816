import React, { FunctionComponent, ReactNode } from 'react';

interface AWCircularBadgeProps {
  children?: ReactNode,
  color?: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info',
  [x: string]: any;
}

const AWCircularBadge: FunctionComponent<AWCircularBadgeProps> = ({ children, className, color = 'secondary', ...otherProps }) => (
  <div  {...otherProps} className={`aw-circular-badge ${color}${className ? ' ' + className : ''}`}>
    { children != null &&
    <div className="text-container">
        <div className="text">{children}</div>
    </div>
    }
  </div>
);

export default AWCircularBadge;