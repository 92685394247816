import React, {FunctionComponent} from "react"

const AWIconBatteyLevel20: FunctionComponent<{ [prop: string]: any }> = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg"
            viewBox="0 56 192 80"
            enableBackground="new 0 56 192 80"
             { ...props }>
            <path
              d="M 87.13,122.00
              C 87.13,122.00 37.00,122.00 37.00,122.00
              37.00,122.00 37.00,70.00 37.00,70.00
              37.00,70.00 79.77,70.00 79.77,70.00
              79.77,70.00 82.74,64.00 82.74,64.00
              82.74,64.00 40.00,64.00 40.00,64.00
              35.00,64.00 31.00,68.00 31.00,73.00
              31.00,73.00 31.00,119.00 31.00,119.00
              31.00,124.00 35.00,128.00 40.00,128.00
              40.00,128.00 86.51,128.00 86.51,128.00
              86.51,128.00 87.13,122.00 87.13,122.00 Z
              M 109.25,128.00
              C 109.25,128.00 140.00,128.00 140.00,128.00
              145.00,128.00 149.00,124.00 149.00,119.00
              149.00,119.00 149.00,110.00 149.00,110.00
              152.00,110.00 155.00,110.00 155.00,110.00
              158.00,110.00 161.00,107.00 161.00,104.00
              161.00,104.00 161.00,88.00 161.00,88.00
              161.00,85.00 159.00,82.00 156.00,82.00
              156.00,82.00 152.00,82.00 149.00,82.00
              149.00,82.00 149.00,73.00 149.00,73.00
              149.00,68.00 145.00,64.00 140.00,64.00
              140.00,64.00 105.49,64.00 105.49,64.00
              105.49,64.00 104.87,70.00 104.87,70.00
              104.87,70.00 143.00,70.00 143.00,70.00
              143.00,70.00 143.00,122.00 143.00,122.00
              143.00,122.00 112.21,122.00 112.21,122.00
              112.21,122.00 109.25,128.00 109.25,128.00 Z" />
            <path
              d="M 61.60,119.00 61.60,107.00 61.40,107.00
              61.60,106.60 61.60,73.00 40.00,73.00
              40.00,119.00 Z" />
            <path
              d="M 64.60,73.00 64.60,100.75 78.30,73.00 Z
              M 64.60,119.00 84.20,119.00 84.20,107.00
              64.60,107.00 Z" />
            <path
              d="M 104.80,85.00 104.80,73.00 104.58,73.00
              103.35,85.00 Z
              M 88.60,107.00 87.20,107.00 87.20,119.00
              87.40,119.00 Z" />
            <path
              d="M 123.40,119.00 123.40,99.35 113.70,119.00 Z
              M 123.40,73.00 107.80,73.00 107.80,85.00
              123.40,85.00 Z" />
            <path
              d="M 126.40,85.00 130.50,85.00 126.40,93.25
              126.40,119.00 140.00,119.00 140.00,73.00
              126.40,73.00 Z" />
            <path
              d="M 126.00,88.00 85.00,171.00 92.00,104.00
              66.00,104.00 107.00,21.00 100.00,88.00 Z" />
        </svg>
    )
}

export default AWIconBatteyLevel20