import React, {FunctionComponent} from "react"

const AWIconBatteyLevel20: FunctionComponent<{ [prop: string]: any }> = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg"
            viewBox="0 56 192 80"
            enableBackground="new 0 56 192 80"
             { ...props }>
            <path
              d="M 40.00,128.00
                C 35.00,128.00 31.00,124.00 31.00,119.00
                31.00,119.00 31.00,73.00 31.00,73.00
                31.00,68.00 35.00,64.00 40.00,64.00
                40.00,64.00 140.00,64.00 140.00,64.00
                145.00,64.00 149.00,68.00 149.00,73.00
                149.00,73.00 149.00,82.00 149.00,82.00
                152.00,82.00 156.00,82.00 156.00,82.00
                159.00,82.00 161.00,85.00 161.00,88.00
                161.00,88.00 161.00,104.00 161.00,104.00
                161.00,107.00 158.00,110.00 155.00,110.00
                155.00,110.00 152.00,110.00 149.00,110.00
                149.00,110.00 149.00,119.00 149.00,119.00
                149.00,124.00 145.00,128.00 140.00,128.00
                140.00,128.00 40.00,128.00 40.00,128.00 Z
                M 37.00,70.00
                C 37.00,70.00 37.00,122.00 37.00,122.00
                37.00,122.00 143.00,122.00 143.00,122.00
                143.00,122.00 143.00,70.00 143.00,70.00
                143.00,70.00 37.00,70.00 37.00,70.00 Z" />
            <path
              d="M 61.60,73.00 61.60,119.00 40.00,119.00
                40.00,73.00 Z" />
            <path
              d="M 84.20,73.00 84.20,119.00 64.60,119.00
                64.60,73.00 Z" />
        </svg>
    )
}

export default AWIconBatteyLevel20