import React, {FunctionComponent} from "react"

const AWIconSDI: FunctionComponent<{ [prop: string]: any }> = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 180 180"
            id="svg2"
            version="1.1"
            { ...props }>
            <defs
                id="defs4"/>
            <g id="layer1"
                transform="translate(-239.90362,-324.1048)">
                <g id="g4296">
                    <path
                        id="path4238"
                        d="m 329.77148,331.19141 a 82.780373,82.780373 0 0 0 -82.78125,82.77929 82.780373,82.780373 0 0 0 82.78125,82.78125 82.780373,82.780373 0 0 0 82.78125,-82.78125 82.780373,82.780373 0 0 0 -82.78125,-82.77929 z m 0,15.7832 a 66.995979,66.995979 0 0 1 66.12696,56.47461 l 9.63867,0 0,21.04492 -9.68359,0 a 66.995979,66.995979 0 0 1 -66.08204,56.47266 66.995979,66.995979 0 0 1 -66.12695,-56.47266 l -9.63867,0 0,-21.04492 9.68359,0 a 66.995979,66.995979 0 0 1 66.08203,-56.47461 z"
                        style={{
                            fill: '#ffffff',
                            fillOpacity: 1,
                            stroke: 'none',
                            strokeWidth: 24,
                            strokeMiterlimit: 4,
                            strokeDasharray: 'none',
                            strokeOpacity: 1
                        }}/>
                    <path
                        id="path4266"
                        d="m 329.77147,369.4248 a 44.547066,44.547066 0 0 0 -44.54687,44.54687 44.547066,44.547066 0 0 0 44.54687,44.54688 44.547066,44.547066 0 0 0 44.54688,-44.54688 44.547066,44.547066 0 0 0 -44.54688,-44.54687 z m 0,15.43359 a 29.113436,29.113436 0 0 1 29.11328,29.11328 29.113436,29.113436 0 0 1 -29.11328,29.11328 29.113436,29.113436 0 0 1 -29.11328,-29.11328 29.113436,29.113436 0 0 1 29.11328,-29.11328 z"
                        style={{
                            opacity: 1,
                            fill: '#ffffff',
                            fillOpacity: 1,
                            stroke: 'none',
                            strokeWidth: 24,
                            strokeMiterlimit: 4,
                            strokeDasharray: 'none',
                            strokeOpacity: 1
                        }}/>
                    <circle
                        r="5.9629927"
                        cy="413.97168"
                        cx="329.77148"
                        id="path4286"
                        style={{
                            opacity: 1,
                            fill: '#ffffff',
                            fillOpacity: 1,
                            stroke: 'none',
                            strokeWidth: 24,
                            strokeMiterlimit: 4,
                            strokeDasharray: 'none',
                            strokeOpacity: 1
                        }}/>
                </g>
            </g>
        </svg>
    )
}

export default AWIconSDI