import React, {
    Fragment,
    FunctionComponent,
    ReactEventHandler,
    ReactNode,
    useState
} from 'react';
import {
    Badge,
    Button,
    Collapse,
    DropdownItem,
    Nav,
    Navbar,
    NavbarBrand,
    NavItem,
    NavLink,
} from 'reactstrap';

import AWIcon, {AWIconName} from "../icon";
import {isString} from "../../utils/string-utils";
import AWAviwestLogo from "../logo/aviwest";
import AWDropdown, { AWDropdownItem, AWDropdownMenu, AWDropdownToggle } from '../dropdown';

interface AWMenuItem {
    active?: boolean,
    as?: string | ReactNode | any,
    badge?: number | string | ReactNode,
    badgeColor?: string,
    icon?: AWIconName | ReactNode,
    title: string | ReactNode,
    [x: string]: any
}

interface AWNavBarProps {
    beta?: boolean,
    title?: string,
    collapsible?: boolean,
    logo?: ReactNode,
    leftInfo?: ReactNode,
    onBurgerMenuClick?: () => void,
    onLogoClick?: () => void,
    tabs?: Array<AWMenuItem>,
    rightNav?: ReactNode;
    rightTrigger?: ReactNode;
    rightMenu?: Array<Array<AWMenuItem>> // Double entries array, used as sections
    [x: string]: any
}

const AWNavBar: FunctionComponent<AWNavBarProps> = (props) => {
    const {
        beta,
        title,
        className,
        collapsible,
        logo,
        leftInfo,
        onBurgerMenuClick,
        onLogoClick,
        tabs,
        rightTrigger,
        rightMenu,
        rightNav,
        ...otherProps
    } = props;

    const [collapsed, setCollapsed] = useState(true);

    const toggleMenu = () => {
        setCollapsed(!collapsed);
    }

    return (
        <Navbar {...otherProps}
                className={`aw-navbar${className ? ' ' + className : ''}`}
                dark
                expand="md"
                fixed="top">
            <div className='aw-navbar-side'>
                { !collapsible && onBurgerMenuClick &&
                <Button className="basic burger-menu"
                onClick={onBurgerMenuClick as ReactEventHandler}>
                    <AWIcon name="burger_menu"/>
                </Button>
                }
                { collapsible &&
                <Button className="basic burger-menu"
                onClick={toggleMenu}>
                    <AWIcon name="burger_menu"/>
                </Button>
                }
                <NavbarBrand tag={onLogoClick ? 'a' : 'span'} onClick={onLogoClick as ReactEventHandler}>
                    {logo ?? <AWAviwestLogo width={120} inverted={true}/>}
                </NavbarBrand>
                { title && <span className={`navbar-title ${beta ? '' : 'mr-3'}`}>{title}</span>}
                { beta && <small className="pb-3 mr-3">BETA UI</small> }
                { leftInfo && leftInfo }
            </div>
            { tabs != null &&
            <Collapse isOpen={!collapsed}
                      navbar>
                <Nav className="aw-menu"
                     navbar>
                    { tabs.map(({active, as, badge, badgeColor, icon, title, ...otherProps}, index) => (
                        <NavItem key={index}>
                            <NavLink tag={as as any} active={active} {...otherProps}>
                                { icon != null && isString(icon) && <AWIcon name={icon as AWIconName}/> }
                                { icon != null && !isString(icon) && icon }
                                <span className="title">{title}</span>
                                { badge && <Badge color={badgeColor ? badgeColor : 'warning'}>{badge}</Badge>}
                            </NavLink>
                        </NavItem>
                    ))}
                    { collapsible && rightMenu &&
                    <Fragment>
                        <div className="right-menu-trigger at-most-medium">
                            {rightTrigger}
                        </div>
                        {/*<hr className="at-most-medium"/>*/}
                        {rightMenu.map((menuSection, index) => (
                            <Fragment key={index}>
                                {menuSection.map(({active, as, badge, badgeColor, icon, title, ...otherProps}, index) => (
                                    <NavItem key={index} className="at-most-medium">
                                        <NavLink tag={as as any}
                                                 key={index}
                                                 className={`secondary${icon != null ? ' icon' : ''}`}
                                                 {...otherProps}>
                                            {icon != null && isString(icon) && <AWIcon name={icon as AWIconName}/>}
                                            {icon != null && !isString(icon) && icon}
                                            <span className="title">{title}</span>
                                            { badge && <Badge color={badgeColor ? badgeColor : 'warning'}>{badge}</Badge>}
                                        </NavLink>
                                    </NavItem>
                                ))}
                                {index < menuSection.length - 1 && <DropdownItem divider/>}
                            </Fragment>
                        ))}
                    </Fragment>
                    }
                </Nav>
            </Collapse>
            }
            <div className='aw-navbar-side'>
                { rightNav }
                { !rightNav && rightMenu && (
                    <Nav className="at-least-medium right"
                    navbar>
                    <AWDropdown nav inNavbar>
                        <AWDropdownToggle nav caret>
                            {rightTrigger}
                        </AWDropdownToggle>
                        {rightMenu && (
                            <AWDropdownMenu right positionFixed>
                            {rightMenu.map((menuSection, index) => (
                                <Fragment key={index}>
                                    {menuSection.map(({active, as, badge, badgeColor, icon, title, ...otherProps}, index) => (
                                        <AWDropdownItem tag={as as any}
                                        key={index}
                                        className={ `${icon != null ? "icon" : ""} ${badge != null ? "with-badge" : ""}` }
                                        {...otherProps}>
                                            {icon != null && isString(icon) && <AWIcon name={icon as AWIconName}/>}
                                            {icon != null && !isString(icon) && icon}
                                            {title}
                                            { badge && <Badge color={badgeColor ? badgeColor : 'warning'}>{badge}</Badge>}
                                        </AWDropdownItem>
                                    ))}
                                    {index < menuSection.length && <AWDropdownItem divider/>}
                                </Fragment>
                            ))}
                        </AWDropdownMenu>
                        )}
                    </AWDropdown>
                </Nav>
                )}
            </div>
        </Navbar>
    );
};

export default AWNavBar;