import React, {FunctionComponent} from "react"

const AWIconVideoReturn: FunctionComponent<{ [prop: string]: any }> = (props) => {
    return (
        <svg version="1.1"
             id="Capa_1"
             xmlns="http://www.w3.org/2000/svg"
             x="0px"
             y="0px"
             viewBox="0 0 173 145"
             enableBackground="new 0 0 173 145"
             { ...props }>
            <g>
                <path d="m 133.87,95.27 c 0,0 0,31.85 0,31.85 0,5.62 -4.5,10.12 -10.13,10.12 0,0 -105.74,0 -105.74,0 -5.63,0 -10.13,-4.5 -10.13,-10.12 0,0 0,-54 0,-54 C 7.87,67.5 12.37,63 18,63 c 0,0 61.36,0 61.36,0 0,0 -1.85,-7.88 -1.85,-7.88 0,0 -66.27,0 -66.27,0 C 5.06,55.12 0,60.18 0,66.37 c 0,0 0,67.5 0,67.5 0,6.19 5.06,11.25 11.24,11.25 0,0 119.26,0 119.26,0 6.18,0 11.24,-5.06 11.24,-11.25 0,0 0,-40.62 0,-40.62 0,0 -7.87,2.02 -7.87,2.02 z"/>
                <path d="m 53.07,71.38 c 0,0 0,57.71 0,57.71 0,0 49.99,-28.86 49.99,-28.86 0,0 -49.99,-28.85 -49.99,-28.85 z"/>
                <path d="m 120.19,39.37 c 16.4,2.86 27.71,16.81 27.71,33.87 0,2.72 -0.33,5.45 -0.94,7.99 4.44,-5.8 8.17,-12.92 8.17,-20.76 0,-19.11 -15.64,-34.6 -34.94,-34.6 0,0 0,-9.65 0,-9.65 0,0 0,0 0,0 0,-0.56 -0.45,-1.02 -1.01,-1.02 -0.2,0 -0.38,0.05 -0.54,0.15 0,0 -27.44,16.85 -27.44,16.85 -0.29,0.18 -0.49,0.5 -0.49,0.87 0,0.37 0.2,0.69 0.49,0.86 0,0 0,0 0,0 0,0 27.44,16.85 27.44,16.85 0.16,0.1 0.34,0.16 0.54,0.16 0.56,0 1.01,-0.46 1.01,-1.02 0,0 0,0 0,0 0,0 0,-10.55 0,-10.55 z m 4.68,57.37 C 98.21,96.74 76.5,75.03 76.5,48.37 76.5,21.71 98.21,0 124.87,0 c 26.66,0 48.37,21.71 48.37,48.37 0,26.66 -21.82,48.37 -48.37,48.37 z"/>
            </g>
        </svg>
    )
}

export default AWIconVideoReturn