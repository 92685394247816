import React, {FunctionComponent, ReactNode} from "react"

type AWHeadingProps = {
    collapsible?: boolean;
    dividing?: boolean;
    priority?: number;
    renderActions?: () => ReactNode | null;
    renderLink?: () => ReactNode | null;
    [x: string]: any;
}

const AWHeading: FunctionComponent<AWHeadingProps> = ({ children, collapsible = false, dividing = false, priority = 3, renderActions, renderLink, ...otherProps }) => {
    const Tag = `h${priority}` as 'h3'
    return (
        <div {...otherProps} className={`aw-heading${dividing ? ' dividing' : ''}`}>
            <Tag className="title">{children}</Tag>
            { renderLink && <div className="link">{renderLink()}</div> }
            { renderActions && <div className="actions">{ renderActions() }</div> }
        </div>
    )
}

export default AWHeading