import React, {FunctionComponent} from 'react';
import { Button } from 'reactstrap';

import AWIcon, {AWIconName} from "../icon";

interface AWBannerAction {
    title: string
    onClick: () => void
}

interface AWBannerIcon {
    name: AWIconName
    rotating?: boolean
}

interface AWBannerProps {
    action?: AWBannerAction;
    actions?: AWBannerAction[];
    icon?: AWBannerIcon;
    level?: 'info' | 'success' | 'warning' | 'error';
    message: string;
    onDismiss?: () => void;
    position?: 'top' | 'bottom';
    title?: string;
    [x: string]: any;
}

const AWBanner: FunctionComponent<AWBannerProps> = ({ action, actions, className, icon, level = 'info', message, onDismiss, position= 'bottom', title , ...otherProps }) => {
    const iconName = icon ? icon.name : null;
    const iconRotating = icon ? icon.rotating : false;
    return (
        <div  { ...otherProps }
              className={ `aw-banner ${position} ${level}${className ? ' ' + className : ''}` }>
            { iconName && <AWIcon name={iconName} rotating={iconRotating}/> }
            <div className="message">
                { title &&
                <div className="title">
                    { title }
                </div>
                }
                { message }
            </div>
            <div className="actions">
                { actions != null ? actions.map((action) => (
                  <Button outline
                          onClick={ action.onClick }>
                    {action.title}
                  </Button>
                )) : action != null ? (
                  <Button outline
                          onClick={ action.onClick }>
                    {action.title}
                  </Button>
                ) : null }
                { onDismiss != null &&
                <Button className="basic icon"
                        onClick={ () => onDismiss() }>
                    <AWIcon name="close"/>
                </Button>
                }
            </div>
        </div>
    )
};

export default AWBanner;