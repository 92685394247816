import React, {FunctionComponent} from "react"

const AWIconNetworkInterfaceWifiAp: FunctionComponent<{ [prop: string]: any }> = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 192 192"
            enableBackground="new 0 0 192 192"
             { ...props }>
            <path
                    d="M 54.05,75.98 54.05,104.75 35.72,104.75 Z
                    M 72.50,121.92 82.02,121.92 82.02,104.75
                        72.50,104.75 72.50,53.48 50.34,53.48
                        13.69,105.91 13.69,121.92 52.6,121.962
                        52.66,137.00 72.50,137.00 Z" />
            <path
                    d="M 132.70,137.70
                    C 137.96,137.70 142.93,136.73 147.61,134.80
                        152.29,132.86 156.21,130.34 159.39,127.25
                        166.04,120.84 170.09,113.91 171.56,106.48
                        171.56,106.48 171.56,91.41 171.56,91.41
                        171.56,91.41 127.25,91.41 127.25,91.41
                        127.25,91.41 127.25,106.48 127.25,106.48
                        127.25,106.48 150.22,106.48 150.22,106.48
                        149.22,110.43 147.23,113.59 144.25,115.95
                        141.27,118.31 137.42,119.48 132.70,119.48
                        126.13,119.48 120.89,117.36 116.98,113.11
                        113.08,108.85 111.12,103.16 111.12,96.05
                        111.12,88.93 113.08,83.25 116.98,79.00
                        120.89,74.75 126.24,72.62 133.05,72.62
                        139.86,72.62 144.81,75.60 147.91,81.55
                        147.91,81.55 171.56,81.55 171.56,81.55
                        168.78,73.05 163.87,66.40 156.83,61.61
                        149.80,56.81 141.75,54.41 132.70,54.41
                        121.02,54.41 111.14,58.34 103.06,66.19
                        94.98,74.03 90.94,83.98 90.94,96.05
                        90.94,108.11 94.98,118.07 103.06,125.92
                        111.14,133.78 121.02,137.70 132.70,137.70 Z" />
        </svg>
    )
}

export default AWIconNetworkInterfaceWifiAp