import React, { FunctionComponent } from "react";

const AWIconNDIInput: FunctionComponent<{
  [prop: string]: any;
}> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 2 16 12" {...props}>
      <path d="M8.4 9.4c-.2-.2-.5-.2-.7 0l-3.8 3.8c-.2.2-.2.5 0 .7 0 0 .2.1.3.1h7.6c.3 0 .5-.2.5-.5 0-.1-.1-.3-.1-.4L8.4 9.4z" />
      <path d="M14.5 2h-13C.7 2 0 2.7 0 3.5v7c0 .8.7 1.5 1.5 1.5h2.1l1-1H1.5c-.3 0-.5-.2-.5-.5v-7c0-.3.2-.5.5-.5h13c.3 0 .5.2.5.5v7c0 .3-.2.5-.5.5h-3.1l1 1h2.1c.8 0 1.5-.7 1.5-1.5v-7c0-.8-.7-1.5-1.5-1.5z" />
      <path d="M7 8.9V4.6h-.9v2.8L4.8 4.6h-.9v4.3h.9V6.1l1.3 2.8zM10 8.4c.3-.3.5-.8.5-1.3v-.7c0-.6-.2-1-.5-1.3-.3-.3-.7-.5-1.2-.5H7.7v4.3h1.1c.5 0 .9-.2 1.2-.5zM8.5 5.3h.3c.3 0 .5.1.6.2s.2.5.2.9v.7c0 .4-.1.6-.2.8-.1.2-.3.2-.6.2h-.3V5.3zM11.2 4.6h.9v4.3h-.9z" />
    </svg>
  );
};

export default AWIconNDIInput;
