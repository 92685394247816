import React, {FunctionComponent} from "react"

const AWIconInternetAccessPoint: FunctionComponent<{ [prop: string]: any }> = (props) => {
    return (
        <svg version="1.1"
             id="Capa_1"
             xmlns="http://www.w3.org/2000/svg"
             x="0px"
             y="0px"
             viewBox="0 0 256 217"
             enableBackground="new 0 0 256 217"
             { ...props }>
            <g>
                <path
                 d="m 113.19912,172.22454 c 0,0 0,-16 0,-16 0,0 -59.000002,0 -59.000002,0 -32.49,-0.05 -49.6499998,-34.83 -29.53,-58.989996 3.79,-4.56 9.94,-8.87 15.53,-10.92 2.64,-0.97 9.57,-2.02 11.01,-3.82 1.51,-1.91 0.84,-8.53 1.17,-11.27 0.73,-5.98 4.34,-12.59 8.91,-16.48 5.53,-4.71 12.65,-7.2 19.91,-6.34 5.29,0.63 9.51,3.59 12.37,1.79 1.75,-1.11 5.92,-10.06 8.160002,-12.97 5.89,-7.64 13.51,-13.93 22.47,-17.55 30.77,-12.4300001 67.6,10.93 68,44.55 0.14,12.53 -1.21,10.36 -2,20 15.89,0 27.54,-1.69 39.83,11.09 16.21,16.849996 12.71,42.769996 -6.83,55.359996 -8.75,5.64 -14.17,5.55 -24,5.55 0,0 0,16 0,16 21.73,-0.02 40.62,-7.76 51.02,-28 16.27,-31.65 -4.65,-74.659996 -42.02,-74.999996 -0.04,-12.6 -2.96,-26.1 -9.45,-37 -15.34,-25.7200001 -46.03,-37.1600001 -74.55,-29.2600001 -9.78,2.72 -18.54,7.4300001 -26.000002,14.3500001 -3.89,3.61 -11.53,14.47 -16.01,15.02 0,0 -4.99,0 -4.99,0 -14.7,0.74 -28.64,8.79 -35.68,21.89 -4.01,7.47 -3.83,13.08 -5.91,15.61 -1.93,2.35 -9.23,5.41 -12.41,7.49 -6.52,4.26 -11.15,9.33 -15.2899998,15.9 -12.46,19.769996 -8.85999997,48.779996 8.3799998,64.699996 11.19,10.34 24.97,14.28 39.91,14.3 0,0 57.000002,0 57.000002,0 z m 8,-60 c 0,0 0,4 0,4 0,0 22,0 22,0 0,0 0,-4 0,-4 0,0 -22,0 -22,0 z m 27,37 c 0,0 22,0 22,0 0,0 0,47 0,47 0,0 22,0 22,0 0,0 0,-47 0,-47 0,0 22,0 22,0 -3.65,-6.79 -17.96,-21.47 -23.83,-28 -2.02,-2.24 -5.95,-7.8 -9.13,-7.8 -2.69,0 -7.15,5.8 -8.97,7.8 0,0 -24.07,28 -24.07,28 z m -27,-27 c 0,0 0,6 0,6 0,0 22,0 22,0 0,0 0,-6 0,-6 0,0 -22,0 -22,0 z m 0,11 c 0,0 0,47 0,47 0,0 -22.000002,0 -22.000002,0 3.650002,6.79 17.960002,21.47 23.830002,28 2.02,2.24 5.95,7.8 9.13,7.8 2.69,0 7.15,-5.8 8.97,-7.8 0,0 24.07,-28 24.07,-28 0,0 -22,0 -22,0 0,0 0,-47 0,-47 0,0 -22,0 -22,0 z m 49,68 c 0,0 0,6 0,6 0,0 22,0 22,0 0,0 0,-6 0,-6 0,0 -22,0 -22,0 z m 0,12 c 0,0 0,4 0,4 0,0 22,0 22,0 0,0 0,-4 0,-4 0,0 -22,0 -22,0 z"/>
            </g>
        </svg>
    )
}

export default AWIconInternetAccessPoint