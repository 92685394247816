import React, { FunctionComponent } from "react";

interface AWRadioSwitchProps {
  className?: string;
  [x: string]: any;
}

const AWRadioSwitch: FunctionComponent<AWRadioSwitchProps> = ({
  children,
  className,
  ...otherProps
}) => {
  return <div { ...otherProps } className={`radio-switch${className ? ' ' + className : ''}`}>{children}</div>;
};

export default AWRadioSwitch;
