import React, { FunctionComponent } from "react";

const AWIconWebRTCInput: FunctionComponent<{
  [prop: string]: any;
}> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 2 16 12" {...props}>
      <path d="M8.4 9.4c-.2-.2-.5-.2-.7 0l-3.8 3.8c-.2.2-.2.5 0 .7 0 0 .2.1.3.1h7.6c.3 0 .5-.2.5-.5 0-.1-.1-.3-.1-.4L8.4 9.4z" />
      <path d="M14.5 2h-13C.7 2 0 2.7 0 3.5v7c0 .8.7 1.5 1.5 1.5h2.1l1-1H1.5c-.3 0-.5-.2-.5-.5V6.7l.3 1.5h.6l.4-1.8.4 1.8h.6l.5-3h-.6l-.3 1.9-.4-1.8H2l-.4 1.8-.3-1.8H1V3.5c0-.3.2-.5.5-.5h13c.3 0 .5.2.5.5v2.1s0-.1-.1-.1c-.2-.2-.4-.3-.8-.3-.3 0-.6.1-.8.3-.2.2-.3.5-.3.9V7c0 .4.1.7.3 1 .2.2.4.3.8.3.3 0 .6-.1.8-.3 0 0 0-.1.1-.1v2.6c0 .3-.2.5-.5.5h-3.1l1 1h2.1c.8 0 1.5-.7 1.5-1.5v-7c0-.8-.7-1.5-1.5-1.5zm.1 5.2c0 .2 0 .3-.1.4s-.2.1-.3.1c-.2 0-.3-.1-.3-.2s-.2-.2-.2-.5v-.6c0-.3 0-.4.1-.6.1-.1.2-.2.3-.2.2 0 .3 0 .3.1.1.1.1.2.1.4h.5v1h-.4z" />
      <path d="M5.8 5.8v-.5H4.1v2.9h1.8v-.5H4.7V7h1v-.5h-1v-.7zM8.1 6.1c0-.3-.1-.5-.2-.6s-.4-.2-.8-.2h-.9v3h1c.3 0 .5-.1.7-.2.2-.1.2-.4.2-.7 0-.2 0-.3-.1-.4 0-.2-.1-.3-.3-.3.2 0 .3-.1.3-.2s.1-.3.1-.4zm-1.3-.3h.3c.1 0 .2 0 .3.1.1 0 .1.1.1.2 0 .2-.1.4-.4.4h-.3v-.7zm.7 1.8c-.1.1-.2.1-.3.1h-.4v-.8h.4c.2 0 .3.1.3.4.1.2 0 .3 0 .3zM10.6 6.2c0-.3-.1-.5-.3-.7-.2-.2-.4-.2-.7-.2h-1v3h.6V7.2h.3l.5 1h.7L10.1 7c.3-.2.5-.4.5-.8zm-.7.3c-.1.1-.2.2-.3.2h-.4v-.9h.4c.2 0 .4.1.4.4 0 .1-.1.3-.1.3zM12.1 8.2V5.8h.8v-.5h-2.2v.5h.8v2.4z" />
    </svg>
  );
};

export default AWIconWebRTCInput;
