import { Field, FieldProps } from "formik";
import React, { Fragment, FunctionComponent } from "react";
import { FormFeedback, FormGroup, Input, Label, UncontrolledTooltip } from 'reactstrap';
import { AWFormFieldCommonProps, AWFormFieldErrorName } from './forms.types';
import { getErrorText, getText } from './forms.utils';
import AWIcon from '../icon';

interface AWFormFieldTextProps extends AWFormFieldCommonProps {
  asTextarea?: boolean;
}

const AWFormFieldText: FunctionComponent<AWFormFieldTextProps> = ({
                                                                    asTextarea = false,
                                                                    componentProps = {},
                                                                    errorTexts,
                                                                    fieldTexts,
                                                                    hintPosition = 'bottom',
                                                                    wrapperProps = {},
                                                                    ...fieldProps
                                                                  }) => {
  const { value: componentValue, type, ...otherComponentProps } = componentProps;

  // Texts
  const label = getText(fieldTexts, fieldProps.name, 'label');
  const placeholder = getText(fieldTexts, fieldProps.name, 'placeholder');
  const hint = getText(fieldTexts, fieldProps.name, 'hint');

  // Computing id
  if(!otherComponentProps.id){
    otherComponentProps.id = fieldProps.name;
  }

  return (
    <Field
      {...fieldProps}>
      {(props: FieldProps) => {
        const { field, meta } = props;
        const { value, ...otherFormikFieldProps } = field;

        // Computing value props
        const valueProps: { [x: string]: any } = { value };
        return (
          <FormGroup { ...wrapperProps }>
            {label &&
            <Label for={otherComponentProps.id}>
              {label}
            </Label>
            }
            <Input type={ asTextarea ? 'textarea' : 'text' }
                   disabled={fieldProps.disabled}
                   placeholder={placeholder} // Placeholder by default
                   invalid={meta.error != null && meta.touched} // Used for displaying error
                   {...otherComponentProps} // Spreading component props, except 'options' and 'value' props
                   {...otherFormikFieldProps} // Spreading all Formik field props: onChange, onBlur, etc...
                   {...valueProps } />
            { hint && hintPosition === 'icon' &&
            <Fragment>
                <AWIcon className="form-hint"
                        id={`${otherComponentProps.id}_hint`}
                        name="info_circle"/>
                <UncontrolledTooltip aria-describedby={`${otherComponentProps.id}_hint`}
                                     placement="top"
                                     target={`${otherComponentProps.id}_hint`}
                                     trigger="hover">
                  {() => (
                    <div>{ hint }</div>
                  )}
                </UncontrolledTooltip>
            </Fragment>
            }
            <FormFeedback>
              { getErrorText(errorTexts, meta.error as AWFormFieldErrorName) }
            </FormFeedback>
            { hint && hintPosition === 'bottom' &&
            <div className="indicator" aria-describedby={`${otherComponentProps.id}_hint`}>
              { hint }
            </div>
            }
          </FormGroup>
        );
      }}
    </Field>
  );
}

export default AWFormFieldText;