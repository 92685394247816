import React, {FunctionComponent} from "react"

const AWIconNetworkInterfaceWifiAp: FunctionComponent<{ [prop: string]: any }> = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 192 192"
        enableBackground="new 0 0 192 192"
        { ...props }>
            <path
                d="M 77.72,113.86 60.25,113.86 39.75,173.00
                    55.05,173.00 58.31,163.09 79.48,163.09
                    82.77,173.00 98.14,173.00 Z
                M 75.88,152.17 61.94,152.17 68.91,131.17 Z
                M 144.52,119.33
                C 140.71,115.80 135.39,114.03 128.56,114.03
                    128.56,114.03 105.30,114.03 105.30,114.03
                    105.30,114.03 105.30,173.00 105.30,173.00
                    105.30,173.00 119.66,173.00 119.66,173.00
                    119.66,173.00 119.66,150.06 119.66,150.06
                    119.66,150.06 128.56,150.06 128.56,150.06
                    132.66,150.06 136.20,149.46 139.19,148.27
                    142.19,147.06 144.44,145.50 145.95,143.59
                    148.80,139.91 150.23,136.07 150.23,132.09
                    150.23,127.11 148.33,122.85 144.52,119.33 Z
                M 119.66,124.95
                C 119.66,124.95 128.56,124.95 128.56,124.95
                    130.70,124.95 132.41,125.62 133.69,126.97
                    134.98,128.31 135.62,130.04 135.62,132.14
                    135.62,134.23 134.99,135.93 133.73,137.22
                    132.47,138.50 130.75,139.14 128.56,139.14
                    128.56,139.14 119.66,139.14 119.66,139.14
                    119.66,139.14 119.66,124.95 119.66,124.95 Z" />
            <path
                d="M 54.25,80.26
                C 64.93,69.50 79.61,62.97 95.97,62.97
                    112.34,62.97 127.02,69.49 137.71,80.26
                    137.71,80.26 122.18,95.84 122.18,95.84
                    115.48,89.04 106.27,84.96 95.97,84.96
                    85.66,84.96 76.47,88.96 69.76,95.76
                    69.76,95.76 54.25,80.26 54.25,80.26 Z" />
            <path
                d="M 156.07,61.92
                C 140.68,46.46 119.50,36.98 95.97,36.98
                    72.44,36.98 51.26,46.43 35.87,61.88
                    35.87,61.88 20.32,46.31 20.32,46.31
                    39.68,26.89 66.38,14.99 95.97,14.99
                    125.60,14.99 152.28,26.87 171.65,46.34
                    171.65,46.34 156.07,61.92 156.07,61.92 Z" />
        </svg>
    )
}

export default AWIconNetworkInterfaceWifiAp