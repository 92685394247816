import React, {FunctionComponent} from "react"

const AWIconEncoderActive: FunctionComponent<{ [prop: string]: any }> = (props) => {
    return (
        <svg version="1.1"
             id="Capa_1"
             xmlns="http://www.w3.org/2000/svg"
             x="0px"
             y="0px"
             viewBox="0 0 512 512"
             enableBackground="new 0 0 512 512"
             { ...props }>
            <g>
                <g>
                    <path d="M0,0v512h512V0H0z M482,482H30V30h452V482z"/>
                </g>
            </g>
            <g>
                <g>
                    <rect x="241" y="211" width="30" height="30"/>
                </g>
            </g>
            <g>
                <g>
                    <rect x="181" y="151" width="30" height="30"/>
                </g>
            </g>
            <g>
                <g>
                    <rect x="211" y="181" width="30" height="30"/>
                </g>
            </g>
            <g>
                <g>
                    <rect x="331" y="301" width="30" height="30"/>
                </g>
            </g>
            <g>
                <g>
                    <rect x="301" y="271" width="30" height="30"/>
                </g>
            </g>
            <g>
                <g>
                    <rect x="271" y="241" width="30" height="30"/>
                </g>
            </g>
            <g>
                <g>
                    <rect x="361" y="331" width="30" height="30"/>
                </g>
            </g>
            <g>
                <g>
                    <rect x="151" y="121" width="30" height="30"/>
                </g>
            </g>
            <g>
                <g>
                    <path d="M60,60v392h392V60H60z M361,181h30v30h-30V181z M301,121h30v30h-30V121z M421,361h-30v30h30v30h-30H91V91h30v30h30V91h30
    v30h30v30h30v-30h30v30h-30v30h30v30h30v-30h30v30h-30v30h30v30h30v-30h30v30h-30v30h30v30h30V361z"/>
                </g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
        </svg>
    )
}

export default AWIconEncoderActive