import React, { FunctionComponent } from "react";
import { DropdownItem, DropdownItemProps, DropdownMenu, DropdownMenuProps, DropdownToggle, DropdownToggleProps, UncontrolledDropdown, UncontrolledDropdownProps } from "reactstrap";

interface AWDropdownProps extends UncontrolledDropdownProps {
  size?: 'sm' | 'lg';
}

const AWDropdown: FunctionComponent<AWDropdownProps> = ({ children, size = 'sm', disabled, className, ...otherProps }) => (
  <UncontrolledDropdown className={`aw-dropdown ${size}${className ? ' ' + className : ''}`} disabled={disabled} {...otherProps}>{children}</UncontrolledDropdown>
);

export default AWDropdown;

export const AWDropdownToggle: FunctionComponent<DropdownToggleProps> = ({ children, icon, className, ...otherProps }) => (
  <DropdownToggle className={`${icon ? 'icon circle' : ''}${className ? ' ' + className : ''}`} {...otherProps}>{children}</DropdownToggle>
);

export const AWDropdownMenu: FunctionComponent<DropdownMenuProps> = ({ children, ...otherProps }) => (
  <DropdownMenu {...otherProps}>{children}</DropdownMenu>
);

export const AWDropdownItem: FunctionComponent<DropdownItemProps> = ({ children, ...otherProps }) => (
  <DropdownItem {...otherProps}>{children}</DropdownItem>
);