import React, { FunctionComponent, ReactNode, useState } from "react";
import { Row } from "reactstrap";
import AWIcon from "../../components/icon";

interface AWHelpLayoutProps {
  form: ReactNode;
  buttons?: ReactNode;
  help?: ReactNode;
}

const AWHelpLayout: FunctionComponent<AWHelpLayoutProps> = ({
  form,
  buttons,
  help,
}) => {
  const [showHelp, setShowHelp] = useState(false);

  const toggleHelp = () => {
    setShowHelp(!showHelp);
  };

  return (
    <div className="help-layout">
      <Row noGutters className={`main-section ${showHelp ? "show-help" : ""}`}>
        <div className="form-section">
          <div className="form-container">
            <div className="scroll pretty-scroll">{form}</div>
          </div>
        </div>
        <div className="help-section">
          <div className="help-container">
            <div className="scroll pretty-scroll">{help}</div>
          </div>
          {help && (
            <div className="toggle-help-container">
              <div className="toggle-help" onClick={toggleHelp}>
                <AWIcon
                  name={showHelp ? "chevron_double_left" : "info_circle"}
                />
              </div>
            </div>
          )}
        </div>
      </Row>
      {buttons && <div className="buttons-section">{buttons}</div>}
    </div>
  );
};

export default AWHelpLayout;
