import React, {FunctionComponent, ReactNode} from 'react'
import {
    Pagination,
    PaginationItem,
    PaginationLink,
    Button, DropdownToggleProps,
} from 'reactstrap'
import AWDropdown, { AWDropdownItem, AWDropdownMenu, AWDropdownToggle } from '../dropdown'
import AWIcon from "../icon"

interface AWPaginationProps {
    currentPage: number
    hasMore?: boolean // Only used when pagesTotal is not defined
    hideOnSinglePage?: boolean
    itemsPerPage?: number
    itemsPerPageValues?: number[]
    itemsPerPagePosition?: 'prepend' | 'append'
    pagesTotal?: number
    pageSelectorProps?: DropdownToggleProps;
    onChangePage(page: number, itemsPerPage?: number): void
    [x: string]: any
}

const AWPageExplorer: FunctionComponent<AWPaginationProps> = ({
                                                                  className,
                                                                  currentPage = 1,
                                                                  hasMore= false,
                                                                  hideOnSinglePage = false,
                                                                  itemsPerPage,
                                                                  itemsPerPagePosition = 'append',
                                                                  itemsPerPageValues = [5, 10, 25, 50],
                                                                  pagesTotal,
                                                                  pageSelectorProps,
                                                                  onChangePage,
                                                                  ...otherProps
}) => {
    let changesPages = [] as ((event: React.MouseEvent) => void)[]
    let startNumber: number | null = null
    let endNumber: number | null = null

    /**
     * Compute all page number visible
     * 5 numbers always visible
     * Current page placed in third position except when it runs out of numbers (example: first and last page)
     */
    if(pagesTotal){
        if (pagesTotal <= 5) {
            startNumber = 1
            endNumber = pagesTotal
        } else if (currentPage === 1) {
            startNumber = currentPage
            endNumber = currentPage + 4
        } else if (currentPage === 2) {
            startNumber = 1
            endNumber = currentPage + 3
        } else if (currentPage + 2 > pagesTotal) {
            startNumber = pagesTotal - 4
            endNumber = pagesTotal
        } else {
            startNumber = currentPage - 2
            endNumber = currentPage + 2
        }
    }

    function previous(){
        onChangePage(currentPage - 1, itemsPerPage)
    }

    function next(){
        onChangePage(currentPage + 1, itemsPerPage)
    }

    function first() {
        onChangePage(1, itemsPerPage)
    }

    function last() {
        pagesTotal ? onChangePage(pagesTotal, itemsPerPage) : onChangePage(currentPage + 1)
    }

    function changePage(number: number): (event: React.MouseEvent) => void {
        if (!changesPages[number]) {
            changesPages[number] = () => onChangePage(number, itemsPerPage)
        }
        return changesPages[number]
    }

    let items: ReactNode[] = []
    if(startNumber != null && endNumber != null){
        for (let i = startNumber; i <= endNumber; i++) {
            items.push(
                <PaginationItem active={i === currentPage} key={i}>
                    <PaginationLink data-page-navigation={currentPage} onClick={changePage(i)} tag={Button}>{i}</PaginationLink>
                </PaginationItem>
            )
        }
    }

    if(pagesTotal != null && pagesTotal <= 1 && hideOnSinglePage) return null;
    if(pagesTotal == null && !hasMore && currentPage === 1 && hideOnSinglePage) return null;
    const itemsPerPageSelector = (
        <AWDropdown>
            <AWDropdownToggle { ...pageSelectorProps } caret>
                {itemsPerPage}
            </AWDropdownToggle>
            <AWDropdownMenu>
                { itemsPerPageValues.map(value => (
                    <AWDropdownItem key={value} onClick={() => onChangePage(currentPage, value)} data-value={value}>{value}</AWDropdownItem>
                ))}
            </AWDropdownMenu>
        </AWDropdown>
    );
    return (
        <div { ...otherProps} className={`aw-page-explorer${className ? ' ' + className : ''}`}>
            { itemsPerPage && itemsPerPagePosition === 'prepend' && itemsPerPageSelector }
            <Pagination>
                <PaginationItem className='change-page'>
                    { pagesTotal != null && <PaginationLink data-page-navigation="first_page" first onClick={first} tag={Button} disabled={currentPage <= 1}><AWIcon name="chevron_double_left"/></PaginationLink> }
                    { pagesTotal == null && <PaginationLink data-page-navigation="previous_page" onClick={previous} tag={Button} disabled={currentPage <= 1}><AWIcon name="chevron_left"/></PaginationLink>}
                </PaginationItem>
                { pagesTotal != null && 
                    <PaginationItem className='change-page'>
                        <PaginationLink data-page-navigation="previous_page" previous onClick={previous} tag={Button} disabled={currentPage <= 1}><AWIcon name="chevron_left"/></PaginationLink>
                    </PaginationItem> 
                }
                {items}
                { pagesTotal != null && 
                    <PaginationItem className='change-page'>
                        <PaginationLink data-page-navigation="next_page" next onClick={next} tag={Button} disabled={currentPage === pagesTotal}><AWIcon name="chevron_right"/></PaginationLink>
                    </PaginationItem>
                }
                <PaginationItem className='change-page'>
                    { pagesTotal != null && <PaginationLink data-page-navigation="last_page" last onClick={last} tag={Button} disabled={currentPage === pagesTotal}><AWIcon name="chevron_double_right"/></PaginationLink> }
                    { pagesTotal == null && <PaginationLink data-page-navigation="next_page" onClick={next} tag={Button} disabled={!hasMore}><AWIcon name="chevron_right"/></PaginationLink>}
                </PaginationItem>
            </Pagination>
            { itemsPerPage && itemsPerPagePosition === 'append' && itemsPerPageSelector }
        </div>
    )
}

export default AWPageExplorer