import React, { FunctionComponent } from "react";

const AWIconRTMPInput: FunctionComponent<{
  [prop: string]: any;
}> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 2 16 12" {...props}>
      <path d="M8.4 9.4c-.2-.2-.5-.2-.7 0l-3.8 3.8c-.2.2-.2.5 0 .7 0 0 .2.1.3.1h7.6c.3 0 .5-.2.5-.5 0-.1-.1-.3-.1-.4L8.4 9.4z" />
      <path d="M14.5 2h-13C.7 2 0 2.7 0 3.5v7c0 .8.7 1.5 1.5 1.5h2.1l1-1H1.5c-.3 0-.5-.2-.5-.5V8.9h.9V7.3h.4L3 8.9h.9l-.8-1.8c.4-.2.6-.6.6-1.2 0-.4-.1-.7-.4-1-.2-.2-.6-.3-1-.3H1V3.5c0-.3.2-.5.5-.5h13c.3 0 .5.2.5.5v1.9c-.1-.2-.2-.3-.3-.4-.3-.3-.6-.4-1-.4h-1.5v4.3h.9V7.4h.6c.4 0 .8-.1 1.1-.4.1-.1.2-.3.3-.4v3.9c0 .3-.2.5-.5.5h-3.1l1 1h2.1c.8 0 1.5-.7 1.5-1.5v-7C16 2.7 15.3 2 14.5 2zM1.9 5.3h.5c.3 0 .5.2.5.6 0 .2 0 .3-.1.5s-.3.2-.4.2h-.5V5.3zm12.2 1.2c-.1.1-.2.2-.4.2h-.6V5.3h.6c.2 0 .3.1.4.2s.1.3.1.5 0 .4-.1.5z" />
      <path d="M6 8.9V5.3h1.1v-.7H4v.7h1.1v3.6zM10.6 8.9h.9V4.6h-1.1l-.9 3.1-.8-3.1H7.6v4.3h.8v-3l.8 3h.6l.9-3-.1 1.8z" />
    </svg>
  );
};

export default AWIconRTMPInput;
