import React, { FunctionComponent } from "react";

const AWIconSoftwareUpdate: FunctionComponent<{ [prop: string]: any }> = (
  props
) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...props}>
      <path d="M10.2 2.6L9 4c-.1.1-.1.3 0 .4.1 0 .1.1.2.1h3.6c.2 0 .3-.1.3-.3v-.1L12.5.6c0-.2-.2-.3-.3-.2-.2 0-.3 0-.3.1l-1 1.1c-1.2-.5-2.7-.8-4.2-.5-3.8.7-6.3 4.4-5.6 8.2.1.3.4.5.8.5.3-.1.5-.4.4-.7-.3-1.3-.1-2.7.6-3.9 1.4-2.6 4.5-3.7 7.3-2.6zM14.9 6.6c-.1-.3-.4-.5-.7-.4-.3.1-.5.4-.4.7.3 1.6-.1 3.4-1.3 4.8-1.6 2-4.1 2.6-6.4 1.8l1-1.2c.1 0 .1-.1.2-.2 0-.2-.1-.3-.3-.3H3.3c-.1 0-.2.1-.2.3l.6 3.5c0 .1 0 .2.1.2.1.1.3.1.4 0l1.2-1.4c1.2.5 2.6.6 3.9.4 3.8-.7 6.3-4.4 5.6-8.2z" />
      <path d="M7.5 3c-.3 0-.5.2-.5.5V9c0 .2.1.3.3.4l3.5 2c.2.1.5 0 .7-.2.1-.2 0-.5-.2-.7L8 8.7V3.5c0-.3-.2-.5-.5-.5z" />
    </svg>
  );
};

export default AWIconSoftwareUpdate;
