import React, {FunctionComponent} from "react"

const AWIconGuestInterview: FunctionComponent<{ [prop: string]: any }> = (props) => {
    return (
        <svg version="1.1"
             id="Capa_1"
             xmlns="http://www.w3.org/2000/svg"
             x="0px"
             y="0px"
             viewBox="0 0 90.36 90.36"
             enableBackground="new 0 0 173 145"
             { ...props }>
            <g>
              <path d="M203.57,420.9v7.17l6.77-3.92a13.81,13.81,0,0,0,0-23.92l-54.27-31.34a13.81,13.81,0,0,0-20.72,12v62.67a13.81,13.81,0,0,0,20.72,12l7.12-4.11,6.28-3.63,7.83-4.52,7.82-4.52,5.4-3.11,3.59-2.08,1.81-1a29.35,29.35,0,0,0-16.38-14.87h0a13.66,13.66,0,1,0-20.13,0v0a29.35,29.35,0,0,0-11.64,7.82v11.75a23.36,23.36,0,0,1,2.07-4.23A23.09,23.09,0,0,1,169.28,422h.26A22.93,22.93,0,0,1,182.36,426h0a22.92,22.92,0,0,1,4.85,4.37l-5.46,3.15-1.65.95-3.31,1.91h0l-7.32,4.23-6.21,3.59L157,447.79l-4,2.32a7.6,7.6,0,0,1-11.4-6.59V380.85a7.6,7.6,0,0,1,11.4-6.58l27.14,15.67,27.14,15.66a7.61,7.61,0,0,1,0,13.18l-3.67,2.12-5.5,3.17a34.63,34.63,0,0,1,3.24,5.3l2.26-1.3m-44.15-10.43.21.21-.21-.21Zm17.5-9.26a7.45,7.45,0,1,1-7.45-7.45A7.45,7.45,0,0,1,176.92,408.38Z" transform="translate(-135.35 -367.01)"/>
            </g>
        </svg>
    )
}

export default AWIconGuestInterview