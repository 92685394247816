import React, { FunctionComponent } from "react";

const AWIconHLSCast: FunctionComponent<{ [prop: string]: any }> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="20 62 472 386" {...props}>
      <path d="M190 232.5h-34.6v-46h-23.5v113.8h23.5v-48.7H190v48.7h23.4V186.5H190zM232.2 186.5v113.8h64.7v-19.1h-41.2v-94.7zM347.3 283.2c-5.9 0-10.2-1.5-12.8-4.5-2.6-3-3.9-7.7-3.9-14.3H307c0 7.7 1.7 14.3 5.2 19.9 3.5 5.6 8.3 9.9 14.5 13s13 4.5 20.5 4.5c11.1 0 19.8-2.8 26.3-8.4 6.4-5.6 9.7-13.3 9.7-23.2 0-9-2.6-16.4-7.7-22.3-5.1-5.8-13.4-10.8-24.7-14.8-6.5-2.7-11-5.3-13.6-7.9-2.6-2.5-3.8-5.4-3.8-8.5 0-4 1.1-7.2 3.4-9.6 2.3-2.4 5.5-3.6 9.6-3.6 4.4 0 7.7 1.4 10 4.2 2.3 2.8 3.5 6.8 3.5 12.1h23.4c0-6.8-1.5-12.9-4.5-18.3s-7.3-9.5-12.9-12.5c-5.6-2.9-11.9-4.4-19.1-4.4-10.7 0-19.5 2.9-26.5 8.7-6.9 5.8-10.4 13.5-10.4 23.1 0 7.9 2.6 14.8 7.7 20.6 5.1 5.8 13.5 10.9 25.1 15.2 6.4 2.6 10.8 5 13.2 7.4 2.4 2.4 3.6 5.9 3.6 10.5.1 8.8-4 13.1-12.2 13.1zM21.3 297.6V340c58.9 0 106.6 48.1 106.6 107h42.7c.1-82.4-66.9-149.3-149.3-149.4zM21.3 382.6v63.9h63.9c0-35.3-28.6-63.9-63.9-63.9z" />
      <path d="M447.8 63H64c-23.6 0-42.7 19.1-42.7 42.7V256H64V105.7h383.8v298.6H214.4V447H448c23.6 0 42.7-19.1 42.7-42.7V105.7c0-23.6-19.3-42.7-42.9-42.7z" />
    </svg>
  );
};

export default AWIconHLSCast;
