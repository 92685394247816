import React, { FunctionComponent } from "react";

const AWIconHLSInput: FunctionComponent<{ [prop: string]: any }> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 2 16 12" {...props}>
      <path d="M8.4 9.4c-.2-.2-.5-.2-.7 0l-3.8 3.8c-.2.2-.2.5 0 .7 0 0 .2.1.3.1h7.6c.3 0 .5-.2.5-.5 0-.1-.1-.3-.1-.4L8.4 9.4z" />
      <path d="M14.5 2h-13C.7 2 0 2.7 0 3.5v7c0 .8.7 1.5 1.5 1.5h2.1l1-1H1.5c-.3 0-.5-.2-.5-.5v-7c0-.3.2-.5.5-.5h13c.3 0 .5.2.5.5v7c0 .3-.2.5-.5.5h-3.1l1 1h2.1c.8 0 1.5-.7 1.5-1.5v-7c0-.8-.7-1.5-1.5-1.5z" />
      <path d="M6.4 8.9V4.6h-.9v1.7H4.2V4.6h-.9v4.3h.9V7h1.3v1.9zM7.1 4.6v4.3h2.4v-.8H8V4.6zM11.4 8.2c-.2 0-.4-.1-.5-.2s-.1-.3-.1-.5h-.9c0 .3.1.5.2.7.1.2.3.4.5.5s.5.2.8.2c.4 0 .7-.1 1-.3s.4-.5.4-.9c0-.3-.1-.6-.3-.8-.2-.2-.5-.4-.9-.6-.2-.1-.4-.2-.5-.3s-.1-.2-.1-.3c0-.2 0-.3.1-.4s.2-.1.4-.1.3.1.4.2.1.3.1.5h.9c0-.3-.1-.5-.2-.7s-.3-.4-.5-.5-.4-.2-.7-.2c-.4 0-.7.1-1 .3s-.5.6-.5.9c0 .3.1.6.3.8s.5.4.9.6c.2.1.4.2.5.3s.1.2.1.4c.1.3-.1.4-.4.4z" />
    </svg>
  );
};

export default AWIconHLSInput;
