import React, {FunctionComponent} from "react"

const AWIconMoJoProLaptop: FunctionComponent<{ [prop: string]: any }> = (props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            id="Layer_1"
            x="0px"
            y="0px"
            viewBox="0 0 569.03699 321.5"
            enableBackground="new 0 0 1024 1024"
            { ...props }>
            <path
                style={{
                    fill: '#ffffff',
                    fillOpacity: 1
                }}
                id="path1310"
                d="M 467.017,269 H 102.019 c -15.164,0 -27.5,-12.337 -27.5,-27.5 V 31.5 C 74.519,16.336 86.855,4 102.019,4 h 364.998 c 15.164,0 27.5,12.336 27.5,27.5 v 210 c 0,15.163 -12.335,27.5 -27.5,27.5 z M 102.019,19 c -6.893,0 -12.5,5.607 -12.5,12.5 v 210 c 0,6.893 5.607,12.5 12.5,12.5 h 364.998 c 6.893,0 12.5,-5.607 12.5,-12.5 v -210 c 0,-6.893 -5.607,-12.5 -12.5,-12.5 z" />
            <path
                style={{
                    fill: '#ffffff',
                    fillOpacity: 1
                }}
                id="path1312"
                d="m 323.879,289.375 v 7.75 c 0,4.25 -5.75,5.042 -7,5.042 h -64.721 c -1.25,0 -7,-0.792 -7,-5.042 v -7.75 H 4 V 303 c 0,2.167 0.533,14.5 12.7,14.5 h 535.638 c 12.166,0 12.699,-12.333 12.699,-14.5 v -13.625 z" />
        </svg>
    )
}

export default AWIconMoJoProLaptop