import { FunctionComponent } from "react";
import React from "react";

interface AWLoaderProps {
    active: boolean,
    position: 'inline' | 'centered',
    size?: string;
    [x: string]: any;
}

const AWLoader: FunctionComponent<AWLoaderProps> = ({ active, position, size, className, ...otherProps }) => {
    if(!active) return null;
    return (
        <div {...otherProps} className={`aw-loader ${position}${className ? ' ' + className : ''}`} aria-live="polite" aria-busy="true">
            <div className={`scale-loader ${size}`}>
                <span className="bar1"/>
                <span className="bar2"/>
                <span className="bar3"/>
                <span className="bar4"/>
                <span className="bar5"/>
            </div>
        </div>
    );
};

export default AWLoader;