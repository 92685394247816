import React, { FunctionComponent, ReactNode } from 'react';
import AWIcon, { AWIconName } from '../icon';
import { isString } from '../../utils/string-utils';

interface AWEmptyProps {
  icon: AWIconName | ReactNode;
  text: string | ReactNode;
  subText?: string | ReactNode;
  [x: string]: any;
}

const AWEmpty: FunctionComponent<AWEmptyProps> = ({ className, icon, text, subText, ...otherProps }) => (
  <div className="aw-empty-container">
    <div {...otherProps} className={`aw-empty${className ? ' ' + className : ''}`}>
      { isString(icon) && <AWIcon name={icon as AWIconName}/> }
      { !isString(icon) && icon }
      <div className="text">
        { text }
      </div>
      { subText && <div className="subtext">
        { subText }
      </div>
      }
    </div>
  </div>
);

export default AWEmpty;