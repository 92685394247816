import React, { FunctionComponent } from "react";

const AWIconSSTCameraVideo: FunctionComponent<{
  [prop: string]: any;
}> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 3 16 10" {...props}>
      <path d="M15.9 3.9c-.2-.5-.8-.7-1.3-.5l-3.1 1.4c-.1-1-1-1.7-2-1.7H2C.9 3 0 3.9 0 5v6c0 1.1.9 2 2 2h7.5c1 0 1.9-.7 2-1.7l3.1 1.4c.1.1.3.1.4.1.6 0 1-.4 1-1V4.3c0-.2 0-.3-.1-.4zM10.5 11c0 .6-.4 1-1 1H2c-.6 0-1-.4-1-1V5c0-.6.4-1 1-1h7.5c.6 0 1 .4 1 1v6zm4.5.7l-3.5-1.6V5.8L15 4.3v7.4z" />
      <path d="M3.1 6.6c.1 0 .2 0 .3.1.1.2.1.3.1.5h.7c0-.2 0-.4-.1-.6-.1-.2-.2-.3-.4-.4-.2-.1-.4-.1-.6-.1-.3 0-.6.1-.8.3-.2.1-.3.3-.3.6s0 .5.2.7c.2.2.4.3.8.5.2.1.3.2.4.2.1.1.1.2.1.3 0 .3-.1.4-.4.4-.2 0-.3 0-.4-.1s-.1-.3-.1-.5h-.7c0 .3 0 .5.1.7s.3.3.5.4.4.1.6.1c.3 0 .6-.1.8-.3.2-.1.3-.4.3-.7 0-.3 0-.5-.2-.7-.2-.2-.4-.3-.8-.4-.2-.1-.3-.2-.4-.3-.1-.1-.1-.2-.1-.2 0-.1 0-.2.1-.3.1-.1.2-.2.3-.2zM5.9 6.6c.1 0 .2 0 .3.1 0 .2.1.3.1.5H7c0-.2 0-.4-.1-.6-.1-.2-.3-.3-.4-.4-.2-.1-.4-.1-.6-.1-.4 0-.6 0-.9.2-.2.2-.3.4-.3.7 0 .2.1.5.2.6.2.3.5.4.8.6.2.1.3.2.4.2.1.1.1.2.1.3 0 .3-.1.4-.4.4-.2 0-.3 0-.4-.1s0-.3 0-.5h-.8c0 .2.1.4.2.6s.2.4.4.5.4.1.6.1c.3 0 .6-.1.8-.3.3-.1.4-.4.4-.7 0-.3-.1-.5-.2-.7-.2-.2-.5-.3-.8-.4-.2-.1-.4-.2-.4-.3l-.2-.2c0-.1 0-.2.1-.3.1-.1.2-.2.4-.2zM7.3 6.7h.9v3h.7v-3h.9v-.6H7.3z" />
    </svg>
  );
};

export default AWIconSSTCameraVideo;
