import React, { FunctionComponent } from "react";

const AWIconNDICast: FunctionComponent<{
  [prop: string]: any;
}> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="20 62 472 386" {...props}>
      <path d="M228.3 186.5h-23.4v74.7l-34.4-74.7H147v113.8h23.5v-74.6l34.3 74.6h23.5zM322.6 252.3v-18.4c-.2-14.7-4.3-26.2-12.3-34.7s-18.9-12.7-32.5-12.7h-30.7v113.8h30.1c14.2 0 25.3-4.3 33.3-12.8s12.1-20.2 12.1-35.2zm-23.4 2c-.2 9.9-1.8 16.9-5 20.9s-8.7 6.1-16.5 6.1h-7v-75.5h8.5c7.3.2 12.5 2.4 15.5 6.6 3 4.2 4.6 11.6 4.6 22.2v19.7zM340.5 186.5H364v113.8h-23.5zM21.3 297.6V340c58.9 0 106.6 48.1 106.6 107h42.7c.1-82.4-66.9-149.3-149.3-149.4zM21.3 382.6v63.9h63.9c0-35.3-28.6-63.9-63.9-63.9z" />
      <path d="M447.8 63H64c-23.6 0-42.7 19.1-42.7 42.7V256H64V105.7h383.8v298.6H214.4V447H448c23.6 0 42.7-19.1 42.7-42.7V105.7c0-23.6-19.3-42.7-42.9-42.7z" />
    </svg>
  );
};

export default AWIconNDICast;
