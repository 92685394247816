import React, {FunctionComponent} from "react"

const AWIconNetworkInterfaceBgan: FunctionComponent<{ [prop: string]: any }> = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 192 192"
            enableBackground="new 0 0 192 192"
             { ...props }>
            <path
                    d="M 169.86,134.83
                    C 169.86,134.83 169.63,144.88 169.63,144.88
                      169.63,144.88 150.77,152.08 150.77,152.08
                      150.77,152.08 150.54,140.72 150.54,140.72
                      139.34,141.60 105.74,147.74 95.34,150.43
                      95.34,150.43 95.34,162.43 95.34,162.43
                      95.34,162.43 67.63,162.66 67.63,162.66
                      67.63,162.66 67.63,152.83 67.63,152.83
                      77.86,149.40 160.34,135.60 169.86,134.83 Z
                    M 148.06,143.70
                    C 148.06,143.70 148.06,150.14 148.06,150.14
                      136.66,156.60 111.60,160.66 97.80,159.30
                      97.80,159.30 97.66,152.86 97.66,152.86
                      106.80,153.00 137.70,149.54 148.06,143.70 Z
                    M 147.37,135.37
                    C 147.37,135.37 142.87,121.87 142.87,121.87
                      142.87,121.87 93.67,129.07 93.67,129.07
                      93.67,129.07 97.34,143.86 97.34,143.86
                      103.06,142.43 141.83,136.20 147.37,135.37 Z
                    M 94.45,144.32
                    C 94.45,144.32 63.02,31.93 63.02,31.93
                      63.02,31.93 66.30,31.94 66.30,31.94
                      66.30,31.94 92.54,126.00 92.54,126.00
                      92.54,126.00 142.34,119.40 142.34,119.40
                      142.34,119.40 114.36,31.15 114.36,31.15
                      114.36,31.15 117.08,31.09 117.08,31.09
                      117.08,31.09 150.58,134.87 150.58,134.87
                      150.58,134.87 164.24,132.94 170.10,132.60
                      170.10,132.60 135.74,28.50 135.74,28.50
                      123.46,27.30 47.54,28.06 33.74,30.00
                      33.74,30.00 67.31,150.18 67.31,150.18
                      74.29,147.92 94.45,144.32 94.45,144.32 Z
                    M 64.73,150.74
                    C 64.73,150.74 64.66,162.74 64.66,162.74
                      64.66,162.74 61.73,162.67 61.73,162.67
                      61.73,162.67 43.66,150.74 43.66,150.74
                      43.66,150.74 43.80,148.57 43.80,148.57
                      43.80,148.57 33.97,142.20 33.97,142.20
                      33.97,142.20 28.06,142.20 28.06,142.20
                      28.06,142.20 22.09,136.54 22.09,136.54
                      22.09,136.54 22.13,129.67 22.13,129.67
                      22.13,129.67 24.67,128.03 24.67,128.03
                      24.67,128.03 56.62,123.17 56.62,123.17
                      56.62,123.17 64.73,150.74 64.73,150.74 Z
                    M 35.18,137.39 35.16,141.46 37.69,143.03
                      37.73,138.94 Z
                    M 39.84,140.21 39.89,144.40 42.47,146.00
                      42.43,141.80 Z" />
        </svg>
    )
}

export default AWIconNetworkInterfaceBgan