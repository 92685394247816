import React, {FunctionComponent} from "react"

const AWIconEncoder: FunctionComponent<{ [prop: string]: any }> = (props) => {
    return (
        <svg version="1.1"
             id="Capa_1"
             xmlns="http://www.w3.org/2000/svg"
             x="0px"
             y="0px"
             viewBox="0 0 512 512"
             enableBackground="new 0 0 512 512"
             { ...props }>
            <g>
                <g>
                    <path d="M0,0v512h512V0H0z M482,482H30V30h452V482z"/>
                </g>
            </g>
            <g>
                <g>
                    <rect x="361" y="241" width="30" height="30"/>
                </g>
            </g>
            <g>
                <g>
                    <rect x="301" y="241" width="30" height="30"/>
                </g>
            </g>
            <g>
                <g>
                    <rect x="331" y="271" width="30" height="30"/>
                </g>
            </g>
            <g>
                <g>
                    <rect x="361" y="301" width="30" height="30"/>
                </g>
            </g>
            <g>
                <g>
                    <rect x="391" y="331" width="30" height="30"/>
                </g>
            </g>
            <g>
                <g>
                    <rect x="271" y="211" width="30" height="30"/>
                </g>
            </g>
            <g>
                <g>
                    <rect x="241" y="181" width="30" height="30"/>
                </g>
            </g>
            <g>
                <g>
                    <rect x="361" y="181" width="30" height="30"/>
                </g>
            </g>
            <g>
                <g>
                    <rect x="301" y="181" width="30" height="30"/>
                </g>
            </g>
            <g>
                <g>
                    <rect x="211" y="151" width="30" height="30"/>
                </g>
            </g>
            <g>
                <g>
                    <rect x="241" y="121" width="30" height="30"/>
                </g>
            </g>
            <g>
                <g>
                    <rect x="181" y="121" width="30" height="30"/>
                </g>
            </g>
            <g>
                <g>
                    <rect x="301" y="121" width="30" height="30"/>
                </g>
            </g>
            <g>
                <g>
                    <rect x="151" y="91" width="30" height="30"/>
                </g>
            </g>
            <g>
                <g>
                    <rect x="271" y="271" width="30" height="30"/>
                </g>
            </g>
            <g>
                <g>
                    <rect x="241" y="241" width="30" height="30"/>
                </g>
            </g>
            <g>
                <g>
                    <rect x="301" y="301" width="30" height="30"/>
                </g>
            </g>
            <g>
                <g>
                    <rect x="331" y="331" width="30" height="30"/>
                </g>
            </g>
            <g>
                <g>
                    <polygon points="391,391 391,361 361,361 361,391 121,391 121,151 151,151 151,121 121,121 121,91 91,91 91,421 391,421 421,421
    421,391 		"/>
                </g>
            </g>
            <g>
                <g>
                    <rect x="211" y="211" width="30" height="30"/>
                </g>
            </g>
            <g>
                <g>
                    <rect x="151" y="151" width="30" height="30"/>
                </g>
            </g>
            <g>
                <g>
                    <rect x="181" y="181" width="30" height="30"/>
                </g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
        </svg>
    )
}

export default AWIconEncoder