import React, { FunctionComponent, SVGAttributes, Fragment } from 'react'

interface AWAviwestLogoProps extends SVGAttributes<Element> {
    width?: number,
    caption?: boolean,
    inverted?: boolean,
    followTheme?: boolean
}

const AWAviwestLogo: FunctionComponent<AWAviwestLogoProps> = ({ width, caption, inverted = false, followTheme = false, ...props }) => {
    const widthLogo = width ?? 300;
    let ratio = 4;
    if(caption){
        ratio = 2.7;
    }
    const heightLogo = widthLogo / ratio;
    return (
        <svg className={`logo-aviwest${followTheme ? ' aw-theme':''}${inverted ? ' inverted':''}`} data-name="aviwest" xmlns="http://www.w3.org/2000/svg" width={widthLogo} height={heightLogo} viewBox={`0 ${caption ? '0' : '-8'} 300 70`} {...props}>
            <title>AVIWEST</title>
            <g>
                <path d="M27,1.2h1.6l2.5.4a5,5,0,0,1,3.7,3.7c1.3,4.4,2.7,8.8,4,13.1,3.4,10.9,6.8,21.9,10.1,32.8.2.7.1.8-.6.8H39.4c-.8,0-1-.2-1.2-.9L34.6,39.4c-.3-.8-.4-.9-1.3-.9h-16c-.9,0-1.1.1-1.3.9-1.2,3.9-2.4,7.8-3.7,11.7-.2.9-.4,1-1.3,1H2.3c-.8,0-.9-.2-.7-.8C6.4,36,11.1,20.8,15.8,5.6a5.4,5.4,0,0,1,4.7-4.1l2.8-.3H27ZM25.3,28.7h4.6c1.3,0,1.4-.1,1-1.3-1.3-4.4-2.7-8.8-4-13.1-.4-1.1-.7-2.3-1.1-3.4s-.3-.4-.5-.4-.4.2-.5.4a3.6,3.6,0,0,0-.4,1.1c-1,3.1-1.9,6.2-2.9,9.3s-1.3,4.2-1.9,6.3-.2,1.1.8,1.1Z"></path>
                <path d="M89.4,1.2c.4.2.3.4.2.8L82,26.4c-2.2,7.1-4.5,14.3-6.7,21.5a5,5,0,0,1-4.2,3.8,30.2,30.2,0,0,1-10.8-.1,4.6,4.6,0,0,1-3.7-3.4c-1.3-3.9-2.5-7.8-3.7-11.7C49.4,25,45.9,13.5,42.3,2c-.1-.3-.2-.6.2-.8h9.8c.4.1.6.4.7.9l11.4,37a29.9,29.9,0,0,0,1,3.2c.1.2.3.5.5.5a.7.7,0,0,0,.5-.4l.5-1.2,12-39.1a1.1,1.1,0,0,1,.8-.9Z"></path>
                <path d="M103.9,1.2c.4.1.5.4.5.8V51.3c0,.6-.2.8-.8.8H94.9c-.6,0-.8-.2-.8-.9V2c0-.4.1-.7.5-.8Z"></path>
                <path className="accent" d="M138.6,16.1l-.3,2c-.3,2.2-.5,4.5-.8,6.7s-.5,4.2-.8,6.4l-.9,7.1c-.2,2.2-.5,4.3-.7,6.4a25.4,25.4,0,0,1-.4,2.9,4.5,4.5,0,0,1-4.1,3.8,20.3,20.3,0,0,1-6,.5,16.8,16.8,0,0,1-4.5-.6,5.8,5.8,0,0,1-3.9-4.5c-.6-3.7-1.1-7.4-1.6-11.1l-1.8-12.5c-.5-3.6-1-7.2-1.6-10.9s-.9-6.8-1.4-10.2c-.1-.5.2-.8.7-.8h8.9a.7.7,0,0,1,.7.6c.1,1.5.3,3,.5,4.5s.5,3.5.7,5.3.5,3.5.7,5.3.5,3.5.7,5.2.5,3.5.7,5.2.5,3.5.7,5.3.4,3.5.7,5.2l.6,3.5.3.6h.2c.1-.6.3-1.2.4-1.8.3-2.5.6-5,.8-7.5s.6-5,.8-7.5l.6-5.7.6-5.7c.2-1.7.4-3.3.5-4.9s.3-2.3.4-3.4.1-.3.2-.5a7.2,7.2,0,0,1,6.1-3.6,36.8,36.8,0,0,1,4.7,0,6.4,6.4,0,0,1,4.1,1.6l.9.9A5.2,5.2,0,0,1,147.1,7c.3,2,.6,4.1.8,6.1s.5,4,.8,6,.5,3.6.7,5.4.4,3.5.7,5.2.4,3.6.7,5.5l.6,4.4a9,9,0,0,0,.3,1.6l.3.4.3-.4c.2-1,.5-1.9.6-2.9s.5-4,.7-6.1.4-4,.7-6,.4-4.1.7-6.1l.6-5.2.6-5.2c.1-1.5.3-3,.5-4.5s.2-2.2.4-3.4.3-.4.5-.5h8.8c.9,0,1,.2.9,1s-.6,3.8-.8,5.7-.5,3.7-.8,5.5-.5,3.8-.8,5.7-.5,3.8-.8,5.7-.5,4.1-.8,6.2-.6,3.7-.8,5.6-.5,3.8-.8,5.7-.5,3.3-.7,4.9a4.9,4.9,0,0,1-3.8,4.3,23.4,23.4,0,0,1-7.5.5,15.7,15.7,0,0,1-3-.5,6,6,0,0,1-4.2-3.5,12.5,12.5,0,0,1-.3-1.9l-.6-5.7-.6-5.3-.6-5.5c-.2-1.4-.3-2.9-.5-4.4l-.6-5.1c-.1-1.2-.3-2.3-.5-3.5s-.1-.3-.1-.5Z" fill="#ec502e"></path>
                <path className="accent" d="M172.6,28.3v-16a13.3,13.3,0,0,1,.7-4.8,8.7,8.7,0,0,1,7.6-6.1h26c.7,0,.8.1.8.8V9.4c0,.8-.1.9-.9.9H183.9c-.9.1-1.1.3-1.1,1.1v9.2c0,.8.2,1,1,1h21.3c.8,0,.9.1.9.9v7.7c0,.8-.1.9-.9.9H183.9c-.9,0-1.1.2-1.1,1.1v9.6c0,.9.2,1.1,1.1,1.1h22.9c.8,0,.9.1.9,1v7.3c0,.7-.2.9-.8.9H179.6a5,5,0,0,1-2.6-.8,8.4,8.4,0,0,1-4.3-5.6,7.6,7.6,0,0,1-.1-1.5Z" fill="#ec502e"></path>
                <path className="accent" d="M227.8,52.1H215.1c-.8,0-1-.2-1-1V43.7c0-.6.2-.8.8-.8h22.3a2.8,2.8,0,0,0,2.5-1.3,6.6,6.6,0,0,0,1.5-4.5,21.1,21.1,0,0,0-.2-2.5,3.8,3.8,0,0,0-3.5-3.5l-2.2-.2h-8.4a16.4,16.4,0,0,1-5.4-.8,9.9,9.9,0,0,1-7-6.6,18.7,18.7,0,0,1-1.2-5.7,21.6,21.6,0,0,1,.8-8,12.3,12.3,0,0,1,6.6-7.5,8.4,8.4,0,0,1,4.1-1.1c7.6.1,15.1,0,22.6,0h.6a.6.6,0,0,1,.6.6V9.7c0,.4-.2.6-.7.6H228a3.4,3.4,0,0,0-2.8,1.3,6.5,6.5,0,0,0-1.3,5.2,12.3,12.3,0,0,0,.2,1.9,3.1,3.1,0,0,0,3,2.5l11.1.2a17.6,17.6,0,0,1,4.6.6,10.4,10.4,0,0,1,7.5,6.9,19.3,19.3,0,0,1,1.1,5.3,29.5,29.5,0,0,1,.1,4.2c-.3,3.6-1.1,7-3.7,9.8a12,12,0,0,1-6.1,3.7l-1.2.2Z" fill="#ec502e"></path>
                <path className="accent" d="M268.3,31.2V11.4c0-.9-.2-1.1-1.1-1.1h-11c-.8,0-.9,0-.9-.8V2.1c0-.7.1-.9.8-.9h34.6c.7,0,.8.1.8.8V9.6c0,.6-.1.7-.8.7H279.5c-.8,0-1,.2-1,.9V51c0,.9-.1,1.1-1,1.1h-8.2c-.8,0-1-.2-1-1Z" fill="#ec502e"></path>
                { caption &&
                <Fragment>
                    <path d="M69.7,77.8l-1.8-.3-.8-.3.5-2.1,2,.5h2.2a3,3,0,0,0,2.3-1.9,14.2,14.2,0,0,0,.7-2.2,4.1,4.1,0,0,1-2,1.2,3,3,0,0,1-3.9-2.9,9.3,9.3,0,0,1,1.5-6.1,4.1,4.1,0,0,1,3.8-2.2,3.1,3.1,0,0,1,2.5,1.3.3.3,0,0,0,.1-.2c.4-.8.4-.8,1.2-.8h1.4c-.2.7-.3,1.5-.5,2.2-.6,3.1-1.2,6.2-1.9,9.3a5.7,5.7,0,0,1-2.3,3.6,6,6,0,0,1-2.5,1H69.7Zm1.9-9.2v.5c.1,1.3.9,1.8,2,1.1l.5-.3a3.6,3.6,0,0,0,1.3-2.1,28.6,28.6,0,0,0,.7-2.8c.1-.7,0-.9-.7-1.2l-.7-.2A2.3,2.3,0,0,0,72.5,65,6.4,6.4,0,0,0,71.6,68.6Z"></path>
                    <path d="M115.4,63.2a5,5,0,0,1,2.2-1.6,3.9,3.9,0,0,1,2.4-.1,2.6,2.6,0,0,1,1.9,3.1c-.3,1.7-.6,3.4-1,5.1a20.5,20.5,0,0,1-.5,2.4.5.5,0,0,1-.3.3h-2.4c.3-1.3.5-2.6.8-3.9s.5-2.1.7-3.2-.6-2-1.9-1.5a2.9,2.9,0,0,0-2,2.5c-.4,1.4-.7,2.9-1,4.4l-.3,1.7h-2.7l.6-2.7.9-4.6c.2-1.1-.4-1.7-1.5-1.4a2.4,2.4,0,0,0-1.9,1.5,9.6,9.6,0,0,0-.7,1.8c-.4,1.8-.7,3.6-1.1,5.4H105c.7-3.6,1.4-7.1,2.2-10.7h2.2V63a4.5,4.5,0,0,1,3.2-1.6A2.6,2.6,0,0,1,115.4,63.2Z"></path>
                    <path d="M172.6,61.7l.6,7.3h0l.9-2,2.7-5.1.4-.2h2.7l-.2.3L174,72.1a.6.6,0,0,1-.6.3h-2.1c-.2-2.5-.4-4.9-.5-7.4l-.2.3L168,72.1a.5.5,0,0,1-.5.3h-2.2l-2.1-10.7h2.5c.1,0,.2.2.2.3.4,2.2.7,4.3,1,6.5a.9.9,0,0,1,.1.5l1.6-4,1.2-3c.1-.3.2-.3.5-.3Z"></path>
                    <path d="M260.9,70.9a4.6,4.6,0,0,1-2.7,1.7,3,3,0,0,1-3.8-2.8,9,9,0,0,1,2-6.9,3.7,3.7,0,0,1,3.9-1.4,2.8,2.8,0,0,1,1.7,1.2l.6-2.5c.2-1.2.5-2.4.7-3.5s.1-.4.4-.4H266c-.2,1.1-.4,2-.6,3l-2.1,9.9h0c-.2,1,0,1.2,1,1.1h.3a14.2,14.2,0,0,1-.4,2c0,.1-.2.2-.2.2h-1.9C261.4,72.4,261,71.7,260.9,70.9ZM257,68.7c0,.2.1.6.1.9a1.2,1.2,0,0,0,1.4.9l.9-.5a3.3,3.3,0,0,0,1.4-1.9c.3-1.1.5-2.2.8-3.3a.6.6,0,0,0-.2-.7,2.1,2.1,0,0,0-3.2.5A7.9,7.9,0,0,0,257,68.7Z"></path>
                    <path d="M40.5,77.6H37.9l.6-2.9c.9-4.2,1.7-8.4,2.6-12.7.1-.3.2-.4.5-.3h1.8V63l.6-.6a3.2,3.2,0,0,1,5.6,2.3,8.1,8.1,0,0,1-2.3,6.6,4.2,4.2,0,0,1-5.5.7h-.1ZM47,65.3a3.1,3.1,0,0,0-.1-.9,1.1,1.1,0,0,0-1.3-.8,3.7,3.7,0,0,0-1.4.7,4.2,4.2,0,0,0-1.3,2.2c-.3,1-.5,2-.7,3s0,.5.3.6a2.2,2.2,0,0,0,3.2-.7A7.1,7.1,0,0,0,47,65.3Z"></path>
                    <path d="M1.9,69.5a16.4,16.4,0,0,0,2.8.7,5,5,0,0,0,3.8-.4,2.8,2.8,0,0,0,1-.9,1.6,1.6,0,0,0-.9-2.5A10.4,10.4,0,0,0,7,65.8l-1.3-.4c-2.5-1-2.8-3.2-2.1-5.1a4.4,4.4,0,0,1,3.9-3,12.2,12.2,0,0,1,5.7.8c.3.1.4.2.3.5a14.9,14.9,0,0,0-.4,1.8l-2.9-.7a7,7,0,0,0-2.5.1,1.8,1.8,0,0,0-1.6,1.5,1.7,1.7,0,0,0,1,1.9l2.3.9a7.2,7.2,0,0,1,2,.9,3.4,3.4,0,0,1,1.1,3.5,4.8,4.8,0,0,1-3.6,3.8,10.5,10.5,0,0,1-7-.4.5.5,0,0,1-.4-.7A10.5,10.5,0,0,0,1.9,69.5Z"></path>
                    <path d="M17.2,72.4H14.5c1.2-5.4,2.3-10.7,3.4-16.1h2.6l-1.4,6.6a4.2,4.2,0,0,1,2.7-1.4,4.1,4.1,0,0,1,1.9.1,2.5,2.5,0,0,1,1.9,2.7c-.2,1.2-.4,2.3-.7,3.5s-.6,3.1-.9,4.6H21.4c0-.2.1-.5.1-.7.4-2.1.9-4.1,1.3-6.2h0c.3-1.6-.5-2.2-2-1.8a3.4,3.4,0,0,0-2.3,2.5l-1.2,5.6C17.3,72,17.2,72.2,17.2,72.4Z"></path>
                    <path d="M35.1,62.6h0c.1-.9.7-1.1,1.5-.9h1.1a23.8,23.8,0,0,0-.5,2.6c-.4,1.6-.7,3.2-1,4.8s-.1,1.3,1,1.2h.2a10.6,10.6,0,0,1-.4,1.9c0,.1-.1.3-.2.3H35.5a1.8,1.8,0,0,1-1.8-1.7l-.6.6a4,4,0,0,1-3.3,1.2,2.9,2.9,0,0,1-2.5-2.5,8.8,8.8,0,0,1,2.3-7.7,3.5,3.5,0,0,1,4.1-.8A11,11,0,0,1,35.1,62.6Zm-5.3,6.1c0,.2.1.6.1.9a1.1,1.1,0,0,0,1.5.8,2.4,2.4,0,0,0,1.1-.6,4.5,4.5,0,0,0,1.3-2.2l.6-2.6c.1-.7,0-.9-.6-1.2l-.8-.2a2.1,2.1,0,0,0-2.1,1.3A7,7,0,0,0,29.8,68.7Z"></path>
                    <path d="M151.4,68.3c.2-2.2.7-4.2,2.5-5.7a5.7,5.7,0,0,1,5.5-.9,3,3,0,0,1,2.1,2.4,7.5,7.5,0,0,1-1.9,7,5.3,5.3,0,0,1-5.8,1.3,3.6,3.6,0,0,1-2.4-3.5Zm7.6-2.6v-.5a1.6,1.6,0,0,0-1.8-1.6,2.1,2.1,0,0,0-1.8.9,6.2,6.2,0,0,0-1.3,4.4c.1,1.2.9,1.7,2.1,1.6a2.4,2.4,0,0,0,1.8-1.3A7.4,7.4,0,0,0,159,65.7Z"></path>
                    <path d="M133.8,65.7a9,9,0,0,1-1.3,4.5,5.7,5.7,0,0,1-6.6,2.2,3.1,3.1,0,0,1-2.2-2.5,7.7,7.7,0,0,1,1.7-6.8,5.8,5.8,0,0,1,6.1-1.4,3.4,3.4,0,0,1,2.2,3A3.1,3.1,0,0,1,133.8,65.7Zm-2.7.1v-.7a1.6,1.6,0,0,0-1.7-1.5,2.2,2.2,0,0,0-2,1,6.3,6.3,0,0,0-1.2,4.4,1.5,1.5,0,0,0,1.7,1.5,2.5,2.5,0,0,0,2.3-1.5A7,7,0,0,0,131.1,65.8Z"></path>
                    <path d="M104,65.5a8,8,0,0,1-1.7,5.1A5.6,5.6,0,0,1,96,72.4a3.3,3.3,0,0,1-2.2-3,8.4,8.4,0,0,1,1.5-5.9,5.7,5.7,0,0,1,6.4-1.8,3.4,3.4,0,0,1,2.2,3A2.5,2.5,0,0,1,104,65.5Zm-7.6,2.6c-.1,1.8.7,2.6,2.1,2.4a2.7,2.7,0,0,0,1.2-.6,5.6,5.6,0,0,0,1.5-5.1,1.4,1.4,0,0,0-1.5-1.2,2.6,2.6,0,0,0-2.5,1.5A7.1,7.1,0,0,0,96.4,68.1Z"></path>
                    <path d="M287.9,65.6a8.8,8.8,0,0,1-1.6,5,5.8,5.8,0,0,1-6.4,1.8,3.4,3.4,0,0,1-2.2-3.1,8.1,8.1,0,0,1,1.1-5.3,5.7,5.7,0,0,1,6.5-2.4,3.5,3.5,0,0,1,2.6,3.1Zm-7.6,2.5q0,2.7,2.1,2.4a2.8,2.8,0,0,0,1.3-.7,5.6,5.6,0,0,0,1.4-5,1.4,1.4,0,0,0-1.5-1.2,2.6,2.6,0,0,0-2.5,1.5A7.1,7.1,0,0,0,280.3,68.1Z"></path>
                    <path d="M269.3,68.3c-.2,1.2.6,2.1,2,2.2a7.8,7.8,0,0,0,3.4-.5l.5-.2a17.4,17.4,0,0,1-.5,2.2h-.2a8.5,8.5,0,0,1-5,.4,3.9,3.9,0,0,1-2.8-3.4,7.4,7.4,0,0,1,2.1-6.2,5.3,5.3,0,0,1,5.6-1.2,2.7,2.7,0,0,1,2,2.1,3,3,0,0,1-1.1,2.9,6.7,6.7,0,0,1-3.6,1.4Zm.3-2.1,1.8-.2a12.7,12.7,0,0,0,1.8-.6,1.1,1.1,0,0,0,.5-1.3c-.1-.5-.6-.7-1.2-.6a2.7,2.7,0,0,0-1.2.3A4,4,0,0,0,269.6,66.2Z"></path>
                    <path d="M65.7,72.4H63c.3-1.2.5-2.5.8-3.7s.5-2.4.7-3.6-.5-1.7-1.6-1.5a3.4,3.4,0,0,0-2.7,2.6c-.4,1.3-.6,2.5-.9,3.8s-.3,1.6-.4,2.4H56.2l2.2-10.7h2.3V63l.5-.5a4.5,4.5,0,0,1,3.6-1.1,2.8,2.8,0,0,1,2.4,3.4c-.3,1.9-.7,3.7-1.1,5.5S65.8,71.7,65.7,72.4Z"></path>
                    <path d="M87.3,63.9h-2c.2-.8.3-1.4.5-2.1h.3a4.1,4.1,0,0,1,1.1,0c.5,0,.6-.2.7-.6s.3-1.6.5-2.4h2.7l-.6,3h2.7l-.4,2.1H90c-.3,1.6-.6,3.1-1,4.7a4.4,4.4,0,0,1-.2,1c0,.7.1.9.8.9a10.9,10.9,0,0,0,1.8-.1h.4a15.5,15.5,0,0,1-.4,2.1h-.2a6.5,6.5,0,0,1-3.4.1,2.2,2.2,0,0,1-1.7-2.8c.4-1.8.8-3.6,1.1-5.4A.6.6,0,0,0,87.3,63.9Z"></path>
                    <path d="M241.9,69.2l.9-1.8c1-1.8,2.1-3.6,3.1-5.5l.3-.2H249c-.1.2-.2.3-.2.5-2,3.2-4,6.5-5.9,9.8a.8.8,0,0,1-.8.4h-1.9c-.8-3.6-1.6-7.1-2.5-10.7h2.7Z"></path>
                    <path d="M193.2,62.1c-.2.8-.3,1.4-.5,2.1l-2-.5h-2a.8.8,0,0,0-.7.8.8.8,0,0,0,.5,1l1.9.7c1,.5,1.9,1.1,2,2.3a3.5,3.5,0,0,1-1.4,3.3,6.2,6.2,0,0,1-2.6.9,10.4,10.4,0,0,1-4.3-.6l.4-2.1,2.8.5a5.7,5.7,0,0,0,1.8-.3,1,1,0,0,0,.7-1,.9.9,0,0,0-.7-.9l-1.7-.7a2.6,2.6,0,0,1-2-2.4,3.2,3.2,0,0,1,1.8-3.3,6.4,6.4,0,0,1,4.3-.3Z"></path>
                    <path d="M137,61.7h2.2V63a4.2,4.2,0,0,1,4.1-1.5l-.4,2.4h-.5a3.2,3.2,0,0,0-3.7,2.4c-.5,1.9-.8,3.7-1.2,5.6a4.3,4.3,0,0,0-.1.5h-2.7C135.5,68.8,136.2,65.3,137,61.7Z"></path>
                    <path d="M145.5,72.4h-2.6c.2-.8.3-1.7.5-2.5.5-2.6,1.1-5.2,1.6-7.9.1-.2.2-.4.5-.3h1.8V63a4.3,4.3,0,0,1,4.2-1.5l-.5,2.4h-.5a3.2,3.2,0,0,0-3.6,2.4,24.5,24.5,0,0,0-.8,3.7Z"></path>
                    <path d="M251,72.4h-2.6c.7-3.6,1.5-7.1,2.2-10.7h2.7C252.5,65.3,251.8,68.8,251,72.4Z"></path>
                    <path d="M53.3,72.4H50.6l2.3-10.7h2.6Z"></path>
                    <path d="M185.9,57.6l-1,1.9c-.8,1.3-1.5,2.6-2.2,3.9l-.5.3H180c.4-.8.8-1.5,1.1-2.2l1.8-3.6a.5.5,0,0,1,.3-.3Z"></path>
                    <path d="M254.4,56.4c-.2.9-.3,1.8-.5,2.6s-.2.2-.3.2h-2.5l.6-2.8Z"></path>
                    <path d="M56.1,59.2H53.3l.6-2.8h2.8Z"></path>
                    <path className="accent" d="M224.5,68.2a1.9,1.9,0,0,0,1.9,2.3A8.7,8.7,0,0,0,230,70h.4c-.2.7-.3,1.4-.5,2.1h-.2a7.5,7.5,0,0,1-5.1.2,3.4,3.4,0,0,1-2.6-3.4,7.1,7.1,0,0,1,1.6-5.4,5.2,5.2,0,0,1,5.6-1.9,2.8,2.8,0,0,1,2.4,2.3,3,3,0,0,1-1.5,3.1,8,8,0,0,1-3.4,1.1Zm.4-2a7.1,7.1,0,0,0,3.5-.7A1.3,1.3,0,0,0,229,64a1.4,1.4,0,0,0-1.4-.6A3.4,3.4,0,0,0,224.9,66.2Z" fill="#ec502e"></path>
                    <path className="accent" d="M215,69.3l.2-.4L219,62a.9.9,0,0,1,.5-.3H222l-.2.4L215.9,72a.7.7,0,0,1-.7.4h-1.3c-.4,0-.6-.1-.7-.5-.5-2.5-1.1-4.9-1.7-7.4l-.6-2.8h2.4c.1,0,.2.3.2.4.5,2.3.9,4.7,1.4,7Z" fill="#ec502e"></path>
                    <path className="accent" d="M204.3,56.4a9.4,9.4,0,0,1-.4,1.6c-.7,3.7-1.5,7.4-2.3,11.1-.2,1,0,1.2,1,1.2h.4a11.6,11.6,0,0,1-.4,1.8l-.3.2h-2.1a1.4,1.4,0,0,1-1.2-1.6,15.4,15.4,0,0,1,.4-3c.7-3.6,1.5-7.3,2.2-10.9.1-.4.2-.6.7-.5Z" fill="#ec502e"></path>
                    <path className="accent" d="M204.8,72.4l.9-4.6,1.2-5.6c0-.4.2-.6.6-.5h2c-.1.4-.1.8-.2,1.1-.6,3.1-1.3,6.1-1.9,9.2-.1.3-.2.4-.5.4Z" fill="#ec502e"></path>
                    <path className="accent" d="M210.7,56.4c-.2.9-.4,1.7-.5,2.5a.5.5,0,0,1-.4.2h-2.3c.1-.7.2-1.2.3-1.8s.2-1,1.2-1Z" fill="#ec502e"></path>
                </Fragment>
                }
            </g>
        </svg>
    );
};

export default AWAviwestLogo