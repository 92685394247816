import React, { FunctionComponent, Key, ReactNode } from "react";
import {
  Button,
  Col,
  ColProps,
  ListGroup,
  ListGroupItem,
  Row,
} from "reactstrap";

interface AWListColumn extends ColProps {
  name: string;
  header: string;
  hidden?: boolean;
}

interface AWListRow {
  state?: "selected" | "disabled" | "warning" | "error";
  content: {
    [propertyName: string]: {
      cell: string | ReactNode;
      title?: string;
    };
  };
  key?: Key;
  actions?: ReactNode;
  onClick?: () => void;
  [x: string]: any;
}

interface AWListProps {
  columns: Array<AWListColumn>;
  data: Array<AWListRow>;
  hasActions?: boolean;
  [x: string]: any;
}

const AWList: FunctionComponent<AWListProps> = ({
  className,
  columns,
  data,
  hasActions = false,
  ...otherListProps
}) => (
  <div {...otherListProps}
       className={ `aw-list${className ? ' ' + className : ''}` }>
    <ListGroup className="spaced">
      <Row key="header" className="list-header flex-nowrap">
        <Col className="d-flex p-0 align-items-center">
          {columns.map((column, index) => {
            const { name, header, ...colProps } = column;
            return (
              <Col
                key={index}
                role="cell"
                className="text-secondary list-header-col"
                title={header}
                {...colProps}
              >
                {header}
              </Col>
            );
          })}
        </Col>
        {hasActions && (
          <Col
            xs="auto"
            key="actions"
            role="cell"
            className="list-header-col actions"
          >
            <Button size="sm" className="icon circle"></Button>
          </Col>
        )}
      </Row>
      {data.map(({ key,
                   actions,
                   className,
                   content,
                   onClick,
                   state,
                   ...otherRowProps
                 }, rowIndex) => (
        <ListGroupItem
          {...otherRowProps}
          key={key ? key : rowIndex}
          className={`list-row row flex-nowrap${hasActions ? ' has-actions' : ''}${state ? ` ${state}` : ""}${className ? ' ' + className : ''}`}
          role="row"
        >
          <Col className={`d-flex p-0${onClick ? " cursor-pointer" : ""}`} onClick={onClick}>
            {columns.map((column, columnIndex) => {
              if (column.hidden) {
                return null;
              }
              const { name, header, ...colProps } = column;
              return (
                <Col
                  key={columnIndex}
                  role="cell"
                  className="list-cell"
                  {...colProps}
                >
                  <div
                    className="inner-cell"
                    title={content[column.name].title}
                  >
                    {content[column.name].cell}
                  </div>
                </Col>
              );
            })}
          </Col>
          {hasActions && (
            <Col
              xs="auto"
              key="actions"
              role="cell"
              className="list-row-action"
            >
              <div className="inner-cel">{actions}</div>
            </Col>
          )}
        </ListGroupItem>
      ))}
    </ListGroup>
  </div>
);

export default AWList;
