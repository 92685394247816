import { FieldArray, Formik, FormikErrors } from "formik";
import React, { Fragment, FunctionComponent, useMemo, useState } from "react";
import { Button, Col, Collapse, Form, Label, Row } from "reactstrap";
import { isProfileNameValid } from "../../../utils/validation-utils";
import {
	AWFormErrorNameAlreadyTaken,
	AWFormErrorRequiredField,
	AWFormErrorWrongFormat,
	CommonFormProps,
	OutputTSProfile,
} from "../forms.types";
import AWFormFieldText from "../form-field-text";
import AWFormFieldNumber from "../form-field-number";
import AWIcon from "../../icon";
import AWFormFieldRadio from '../form-field-radio';
import { getText, shouldDisplayField } from '../forms.utils';
import AWFormFieldSwitch from '../form-field-switch';
import { isEmptyString } from '../../../utils/string-utils';

interface TSDestinationProps {
	host: string;
	port: number | '';
}

interface OutputTSFormValues extends OutputTSProfile {
	destinationsArray: TSDestinationProps[]
}

interface OutputTSFormProps extends CommonFormProps {
	defaultValues: OutputTSProfile | null;
	extendValidation?: (values: OutputTSFormValues) => FormikErrors<OutputTSFormValues>;
	forbiddenNames: string[];
	forbiddenDirNames: string[];
	onSubmit: (values: OutputTSProfile) => void;
}

const baseValue: OutputTSProfile = {
	mode: 'TS',
	name: '',
	bitrate: 12000,
	delay: 1000,
	destinations: '',
	audioPID: 101,
	videoPID: 102,
	pmtPID: 103,
	pcrPID: 104,
	multicast: false,
	protocol: 'UDP',
	ttl: 64,
}

const OutputTSForm: FunctionComponent<OutputTSFormProps> = ({
	children,
	defaultValues,
	errorTexts,
	extendValidation,
	forbiddenNames,
	formikProps = {},
	formProps = {},
	fieldTexts,
	visibleFields,
	onSubmit,
}) => {
	const [advancedSectionVisible, setAdvancedSectionVisible] = useState(false);

	const handleFormSubmit = (values: OutputTSFormValues) => {
		const data: OutputTSProfile = {
			...values,
			destinations: values.destinationsArray.reduce((acc: string, destination: TSDestinationProps, index) => acc + `${index > 0 ? ',' : ''}${destination.host}:${destination.port}`, '')
		};
		delete data.destinationsArray;
		onSubmit(data);
	};

	const commonFieldProps = { fieldTexts, errorTexts };

	const defaultTTL = (multicast: Boolean | null) => {
		if (multicast == null) {
			multicast = false;
		}
		return multicast ? 1 : 64;
	};

	const handleValidation = (
		values: OutputTSFormValues
	): FormikErrors<OutputTSFormValues> => {
		const errors: FormikErrors<OutputTSFormValues> = {};

		// Name
		if (isEmptyString(values.name)) {
			errors.name = AWFormErrorRequiredField;
		} else if(!isProfileNameValid(values.name)){
			errors.name = AWFormErrorWrongFormat;
		} else if (forbiddenNames.indexOf(values.name) !== -1) {
			errors.name = AWFormErrorNameAlreadyTaken;
		}

		// bitrate
		if (isEmptyString(values.bitrate)) {
			errors.bitrate = AWFormErrorRequiredField;
		}

		// delay
		if (isEmptyString(values.delay)) {
			errors.delay = AWFormErrorRequiredField;
		}
		// audioPID
		if (isEmptyString(values.audioPID)) {
			errors.audioPID = AWFormErrorRequiredField;
		}

		// videoPID
		if (isEmptyString(values.videoPID)) {
			errors.videoPID = AWFormErrorRequiredField;
		}
		// pmtPID
		if (isEmptyString(values.pmtPID)) {
			errors.pmtPID = AWFormErrorRequiredField;
		}

		// pcrPID
		if (isEmptyString(values.pcrPID)) {
			errors.pcrPID = AWFormErrorRequiredField;
		}

		// Destinations
		const destinationsArrayErrors: FormikErrors<TSDestinationProps>[] = [];
		values.destinationsArray.forEach((destination: TSDestinationProps, index: number) => {
			const destinationError: FormikErrors<TSDestinationProps> = {};
			if (isEmptyString(destination.host)) {
				destinationError.host = AWFormErrorRequiredField;
			}
			if (isEmptyString(destination.port)) {
				destinationError.port = AWFormErrorRequiredField;
			}
			if(Object.keys(destinationError).length > 0){
				destinationsArrayErrors[index] = destinationError;
			}
		});

		if(destinationsArrayErrors.length > 0){
			errors.destinationsArray = destinationsArrayErrors;
			console.log(destinationsArrayErrors);
		}

		// Additional Validation
		let additionalErrors = {};
		if (extendValidation) {
			additionalErrors = extendValidation(values);
		}
		return {
			...additionalErrors,
			...errors,
		};
	};

	const initialValues = useMemo(
		() => {
			const destinationsArray: TSDestinationProps[] =  defaultValues && defaultValues.destinations != null ? defaultValues.destinations.split(",").map((destination) => {
				if(destination.indexOf(':') !== -1){
					return {
						host: destination.split(":")[0],
						port: parseInt(destination.split(":")[1]),
					};
				}
				return {
					host: destination,
					port: "",
				}
			}) : [{
				host: "",
				port: "",
			}];
			const ttl = defaultValues && defaultValues.ttl != null ? defaultValues.ttl : defaultTTL(defaultValues ? defaultValues.multicast : null);
			return {
				...baseValue,
				...defaultValues,
				ttl,
				destinationsArray,
			}
		},[defaultValues]
	);

	return (
		<Formik
			{...formikProps}
			initialValues={initialValues}
			validate={handleValidation}
			onSubmit={handleFormSubmit}
		>
			{(formikProps) => (
				<Form {...(formProps as any)} onSubmit={formikProps.handleSubmit}>
					<AWFormFieldText
						{...commonFieldProps}
						name="name"
					/>

					<Row form className="no-gutters">
						{ shouldDisplayField('bitrate', visibleFields) && (
							<Col md={6}>
								<AWFormFieldNumber
									{...commonFieldProps}
									name="bitrate"
								/>
							</Col>
						)}
						{ shouldDisplayField('delay', visibleFields) && (
							<Col md={6}>
								<AWFormFieldNumber
									{...commonFieldProps}
									name="delay"
								/>
							</Col>
						)}
					</Row>

					{ shouldDisplayField('destinations', visibleFields) && (
						<FieldArray name="destinationsArray"
												validateOnChange={false}>
							{({ push, remove }) => {
								return (
									<Fragment>
										{ getText(fieldTexts, 'destinations', 'label') && (
											<Label>{getText(fieldTexts, 'destinations', 'label')}</Label>
										)}
										{formikProps.values.destinationsArray.map((destination: TSDestinationProps, index: number) => (
											<Row key={index} className="destination-row" form>
												<Col xs={6}>
													<AWFormFieldText
														{...commonFieldProps}
														name={`destinationsArray[${index}].host`}
													/>
												</Col>
												<Col xs={4} md={5}>
													<AWFormFieldNumber
														{...commonFieldProps}
														name={`destinationsArray[${index}].port`}
													/>
												</Col>
												<Col xs={2} md={1}>
													{formikProps.values.destinationsArray.length > 1 && (
														<Button
															onClick={() => remove(index)}
														>
															<AWIcon name="delete" />
														</Button>
													)}
													{formikProps.values.destinationsArray.length < 2 && !formikProps.values.multicast &&
													<Button
														onClick={() =>
															push({
																host: "",
																port: "",
															})
														}
													>
														<AWIcon name="add"/>
													</Button>
													}
												</Col>
											</Row>
										))}
									</Fragment>
								);
							}}
						</FieldArray>
					)}
					<Button className={`form-collapsable-section-trigger ${advancedSectionVisible ? 'expanded' : ''}`} onClick={() => setAdvancedSectionVisible(!advancedSectionVisible)}>
						<div className="label">
							{ getText(fieldTexts, 'advancedSection', 'label') }
						</div>
						<AWIcon name={ advancedSectionVisible ? 'chevron_up' : 'chevron_down' } />
					</Button>
					<Collapse isOpen={advancedSectionVisible} className="form-collapsable-section">
						{ shouldDisplayField('multicast', visibleFields) && (
							<AWFormFieldSwitch
								{...commonFieldProps}
								name="multicast"
								onValueChange={(multicastEnabled:boolean) => {
									if(multicastEnabled){
										formikProps.setFieldValue('destinationsArray', [formikProps.values.destinationsArray[0]]);
									}
									formikProps.setFieldValue('ttl', defaultTTL(multicastEnabled));
								}}
							/>
						)}
						{ shouldDisplayField('ttl', visibleFields) && (
							<AWFormFieldNumber
								{...commonFieldProps}
								name="ttl"
							/>
						)}
						{ shouldDisplayField('protocol', visibleFields) && (
							<Row className="form-group">
								<Col className="ml-auto">
									<AWFormFieldRadio
										{...commonFieldProps}
										componentProps={{
											value: 'UDP'
										}}
										name="protocol"
									/>
								</Col>
								<Col className="mr-auto">
									<AWFormFieldRadio
										{...commonFieldProps}
										componentProps={{
											value: 'RTP'
										}}
										name="protocol"
									/>
								</Col>
							</Row>
						)}
						<Row id="pid" form>
							{ shouldDisplayField('audioPID', visibleFields) && (
								<Col md={3}>
									<AWFormFieldNumber
										{...commonFieldProps}
										name="audioPID"
									/>
								</Col>
							)}
							{ shouldDisplayField('videoPID', visibleFields) && (
								<Col md={3}>
									<AWFormFieldNumber
										{...commonFieldProps}
										name="videoPID"
									/>
								</Col>
							)}
							{ shouldDisplayField('pmtPID', visibleFields) && (
								<Col md={3}>
									<AWFormFieldNumber
										{...commonFieldProps}
										name="pmtPID"
									/>
								</Col>
							)}
							{ shouldDisplayField('pcrPID', visibleFields) && (
								<Col md={3}>
									<AWFormFieldNumber
										{...commonFieldProps}
										name="pcrPID"
									/>
								</Col>
							)}
						</Row>
					</Collapse>

					{children &&
						children(formikProps)}
				</Form>
			)}
		</Formik>
	);
};

export default OutputTSForm;
