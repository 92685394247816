import { Field, FieldProps } from 'formik';
import React, { FunctionComponent, ReactNode } from 'react';
import { FormFeedback, FormGroup, Label } from 'reactstrap';
import { AWFormFieldCommonProps, AWFormFieldErrorName } from './forms.types';
import { getErrorText, getText } from './forms.utils';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

interface AWFormFieldRangeProps extends AWFormFieldCommonProps {
  min: number;
  max: number;
  step?: number;
  input?: boolean;
  betterHint?: ReactNode | string; 
}

const AWFormFieldRange: FunctionComponent<AWFormFieldRangeProps> = ({
  componentProps = {},
  errorTexts,
  fieldTexts,
  hintPosition = 'bottom',
  wrapperProps = {},
  min,
  max,
  step,
  input,
  betterHint,
  ...fieldProps
}) => {
  const { value: componentValue, type, ...otherComponentProps } = componentProps;

  // Texts
  const label = getText(fieldTexts, fieldProps.name, 'label');
  const hint = getText(fieldTexts, fieldProps.name, 'hint');

  // Computing id
  if (!otherComponentProps.id) {
    otherComponentProps.id = fieldProps.name;
  }

  return (
    <Field {...fieldProps}>
      {(props: FieldProps) => {
        const { field, meta, form } = props;
        const { value } = field;

        return (
          <FormGroup {...wrapperProps} className={wrapperProps?.className + ' aw-range'}>
            {label && <Label for={otherComponentProps.id}>{label}</Label>}
            {input && <input
              type="number"
              className="form-control-sm form-control"
              min={min}
              max={max}
              disabled={fieldProps.disabled}
              {...field}
            />}
            <Slider min={min} max={max} step={step} disabled={fieldProps.disabled} value={value} onChange={(v) => form.setFieldValue(field.name, v)} />
            <FormFeedback>{getErrorText(errorTexts, meta.error as AWFormFieldErrorName)}</FormFeedback>
            {betterHint && <div className="indicator">{betterHint}</div>}
            {hint && hintPosition === 'bottom' && <div className="indicator">{hint}</div>}
          </FormGroup>
        );
      }}
    </Field>
  );
};

export default AWFormFieldRange;
