import { Formik, FormikErrors } from "formik";
import React, { Fragment, FunctionComponent, useMemo } from "react";
import { Form } from "reactstrap";
import { isProfileNameValid, isURLValid } from "../../../utils/validation-utils";
import {
	AWFormErrorNameAlreadyTaken,
	AWFormErrorRequiredField,
	AWFormErrorURiAlreadyTaken,
	AWFormErrorWrongFormat,
	CommonFormProps,
	OutputSRTProfile,
} from "../forms.types";
import {
	outputSrtPullPublicURI,
	outputSrtPullLocalURIs,
	outputSrtPushURI,
} from "../../../utils/uri-utils";
import AWFormFieldText from "../form-field-text";
import AWFormFieldSwitch from "../form-field-switch";
import { shouldDisplayField } from '../forms.utils';
import { isEmptyString } from '../../../utils/string-utils';
import AWFormFieldSelect from "../form-field-select";
import AWFormFieldPassword from "../form-field-password";
import AWFormFieldNumber from "../form-field-number";

interface OutputSRTFormProps extends CommonFormProps {
	defaultValues: OutputSRTProfile | null;
	extendValidation?: (
		values: OutputSRTProfile
	) => FormikErrors<OutputSRTProfile>;
	forbiddenNames: string[];
	forbiddenUris: string[];
	onSubmit: (values: OutputSRTProfile) => void;
	srtKeyLengths: string[];
}

const baseValues: OutputSRTProfile = {
	mode: "",
	name: "",
	serverMode: false,
	uri: "srt://",
	port: "",
	latency: "",
	keyLength: "Disabled",
	password: "",
	cbrMode: false,
	bitrate: "",
};

const OutputSRTForm: FunctionComponent<OutputSRTFormProps> = ({
	children,
	defaultValues,
	errorTexts,
	extendValidation,
	forbiddenNames,
	forbiddenUris,
	formikProps = {},
	formProps = {},
	fieldTexts,
	visibleFields,
	localIPs,
	onSubmit,
	publicIP,
	srtKeyLengths,
}) => {
	const handleFormSubmit = (values: OutputSRTProfile) => {
		if (values.serverMode) {
			values.uri = "";
		} else {
			values.port = "";
		}
		onSubmit(values);
	};

	const commonFieldProps = { fieldTexts, errorTexts };

	const uriList = (values: OutputSRTProfile) => {
		let uris = null;
		if (values.serverMode) {
			uris = outputSrtPullLocalURIs(localIPs, values);
			uris && uris.push(outputSrtPullPublicURI(publicIP, values));
		} else {
			uris = [outputSrtPushURI(values)];
		}
		return uris ? uris : [];
	};

	const handleValidation = (
		values: OutputSRTProfile
	): FormikErrors<OutputSRTProfile> => {
		const errors: FormikErrors<OutputSRTProfile> = {};

		// Name
		if (isEmptyString(values.name)) {
			errors.name = AWFormErrorRequiredField;
		} else if(!isProfileNameValid(values.name)){
			errors.name = AWFormErrorWrongFormat;
		} else if (forbiddenNames.indexOf(values.name) !== -1) {
			errors.name = AWFormErrorNameAlreadyTaken;
		}

		if (values.serverMode) {
			// Port is required
			if (isEmptyString(values.port)) {
				errors.port = AWFormErrorRequiredField;
			}
		}
		else {
			// URI is required
			if(isEmptyString(values.uri)){
				errors.uri = AWFormErrorRequiredField;
			} else if(!isURLValid(values.uri)){
				errors.uri = AWFormErrorWrongFormat;
			} else {
				const uris = uriList(values);
				if(uris !== null && uris.find((uri) => forbiddenUris.indexOf(uri) !== -1) !== undefined) {
					errors.uri = AWFormErrorURiAlreadyTaken;
					errors.port = AWFormErrorURiAlreadyTaken;
				}
			}
		}

		// latency
		if (isEmptyString(values.latency)) {
			errors.latency = AWFormErrorRequiredField;
		}
		
		// keyLength
		if (isEmptyString(values.keyLength)) {
			errors.keyLength = AWFormErrorRequiredField;
		}

		// password
		if (values.keyLength !== 'Disabled' && isEmptyString(values.password)) {
			errors.password = AWFormErrorRequiredField;
		}

		if (values.cbrMode && isEmptyString(values.bitrate)) {
			errors.bitrate = AWFormErrorRequiredField;
		}
	
		// Additional Validation
		let additionalErrors = {};
		if (extendValidation) {
			additionalErrors = extendValidation(values);
		}
		return {
			...additionalErrors,
			...errors,
		};
	};

	const initialValues = useMemo(
		() => ({
			...baseValues,
			...defaultValues,
		}),
		[defaultValues]
	);

	return (
		<Formik
			{...formikProps}
			initialValues={initialValues}
			validate={handleValidation}
			onSubmit={handleFormSubmit}
		>
			{(formikProps) => (
				<Form {...(formProps as any)} onSubmit={formikProps.handleSubmit}>
					<AWFormFieldText {...commonFieldProps} name="name" />
					{ shouldDisplayField('serverMode', visibleFields) && (
						<AWFormFieldSwitch {...commonFieldProps} name="serverMode" />
					)}
					{formikProps.values.serverMode === true && shouldDisplayField('port', visibleFields) && (
						<AWFormFieldNumber {...commonFieldProps} name="port" />
					)}
					{formikProps.values.serverMode === false && shouldDisplayField('uri', visibleFields) && (
						<Fragment>
							<AWFormFieldText {...commonFieldProps} name="uri" />
						</Fragment>
					)}

					{ shouldDisplayField('latency', visibleFields) && 	
						<AWFormFieldNumber {...commonFieldProps} name="latency" />
					}

					{ shouldDisplayField('keyLength', visibleFields) && 
						<AWFormFieldSelect {...commonFieldProps} options={srtKeyLengths} name="keyLength" />
					}

					{ formikProps.values.keyLength !== 'Disabled' && shouldDisplayField('password', visibleFields) && 
						<AWFormFieldPassword {...commonFieldProps} name="password" />
					}

					{ shouldDisplayField('cbrMode', visibleFields) && (
						<AWFormFieldSwitch {...commonFieldProps} name="cbrMode" />
					)}

					{formikProps.values.cbrMode === true && shouldDisplayField('bitrate', visibleFields) && (
						<AWFormFieldNumber {...commonFieldProps} name="bitrate" />
					)}

					{children &&
						children({
							...formikProps,
							info: {
								publicURI: publicIP
									? outputSrtPullPublicURI(publicIP, formikProps.values)
									: null,
								localURI: localIPs
									? outputSrtPullLocalURIs(localIPs, formikProps.values)
									: null,
							},
						})}
				</Form>
			)}
		</Formik>
	);
};

export default OutputSRTForm;
