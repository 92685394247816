import React, { FunctionComponent } from "react";

const AWIconRTSPInput: FunctionComponent<{
  [prop: string]: any;
}> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 2 16 12" {...props}>
      <path d="M8.4 9.4c-.2-.2-.5-.2-.7 0l-3.8 3.8c-.2.2-.2.5 0 .7 0 0 .2.1.3.1h7.6c.3 0 .5-.2.5-.5 0-.1-.1-.3-.1-.4L8.4 9.4z" />
      <path d="M14.5 2h-13C.7 2 0 2.7 0 3.5v7c0 .8.7 1.5 1.5 1.5h2.1l1-1H1.5c-.3 0-.5-.2-.5-.5v-7c0-.3.2-.5.5-.5h13c.3 0 .5.2.5.5v7c0 .3-.2.5-.5.5h-3.1l1 1h2.1c.8 0 1.5-.7 1.5-1.5v-7c0-.8-.7-1.5-1.5-1.5z" />
      <path d="M4.5 5.9c0-.4-.1-.7-.4-1-.2-.2-.6-.3-1-.3H1.7v4.3h.9V7.3H3l.7 1.6h.9l-.8-1.8c.5-.3.7-.7.7-1.2zm-1 .5c-.1.1-.3.2-.4.2h-.5V5.3h.5c.3 0 .5.2.5.6 0 .2 0 .4-.1.5zM6.7 8.9V5.3h1.1v-.7H4.7v.7h1.1v3.6zM9.6 8.2c-.2 0-.4 0-.5-.2S9 7.8 9 7.5h-.9c0 .3.1.5.2.7.1.2.3.4.5.5s.5.2.8.2c.4 0 .7-.1 1-.3s.4-.5.4-.9c0-.3-.1-.6-.3-.8-.2-.2-.5-.4-.9-.6-.3-.1-.5-.2-.6-.2s-.1-.3-.1-.4c0-.2 0-.3.1-.4s.2-.1.4-.1.3.1.4.2.1.3.1.5h.9c0-.3-.1-.5-.2-.7s-.3-.4-.5-.5-.5-.2-.7-.2c-.4 0-.7.1-1 .3-.3.3-.4.6-.4.9 0 .3.1.6.3.8s.5.4.9.6c.2.1.4.2.5.3s.1.2.1.4c.1.3-.1.4-.4.4zM13 7.4c.4 0 .8-.1 1.1-.4.3-.2.4-.6.4-1s-.2-.8-.5-1c-.3-.3-.6-.4-1-.4h-1.5v4.3h.9V7.4h.6zm-.6-2.1h.6c.2 0 .3.1.4.2s.1.3.1.5 0 .4-.1.5-.2.1-.4.1h-.6V5.3z" />
    </svg>
  );
};

export default AWIconRTSPInput;
