import React, { FunctionComponent } from "react";

const AWIconVideoReturnOff: FunctionComponent<{ [prop: string]: any }> = (
  props
) => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="11 27 175 167"
      enableBackground="new 0 0 0 0"
      {...props}
    >
      <path d="M116.6 127.7L82.9 108.3 107.5 132.9z"></path>
      <path d="M138.4 27.5c-35 .6-50.7 29.4-47.2 55.4l-.1-.3H57.3l7.9 7.9h27.6c5.2 18.1 20.4 33.3 45.6 33.7 3.2-.1 6.2-.4 9-.8v31.2c0 5-3.6 9.1-8.3 9.9l7.7 7.7c4.8-1.3 8.4-5.7 8.4-10.8v-40c46.1-15.9 40.6-92.8-16.8-93.9zm22.1 81.3c4.5-19-7.6-38.9-26.8-41.9l-.1 10.7c.8 4.3-28.1-16.5-28.9-16.1-3.8-1.7 27.5-17.6 27.6-18.6.6-.3 1.5.1 1.5.9v9.6c28.3-.9 45.8 34.3 26.7 55.4z"></path>
      <path d="M137.3 172.6L129.4 164.7 129.2 164.7 101.2 136.6 101.3 136.6 66.6 101.9 66.6 102 55.1 90.5 55.3 90.5 47.4 82.6 47.2 82.6 16 51.4 11 56.3 37.3 82.6 37.3 82.6 45.2 90.5 45.2 90.5 66.6 111.9 66.6 112 94.9 140.3 94.9 140.2 119.3 164.7 119.3 164.7 127.2 172.6 127.2 172.6 148.6 194 153.6 189.1 137.1 172.6z"></path>
      <path d="M31.5 164.7c-5.6 0-10.1-4.5-10.1-10.1v-54c0-5.6 4.5-10.1 10.1-10.1h3.8l-7.9-7.9h-2.7c-6.1 0-11.2 5.1-11.2 11.3v67.5c0 6.2 5.1 11.2 11.2 11.2h92.6l-7.9-7.9H31.5z"></path>
      <path d="M66.6 156.6L88.6 143.9 66.6 121.9z"></path>
    </svg>
  );
};

export default AWIconVideoReturnOff;
