import React, { FunctionComponent } from "react";

const AWIconFirmwareUpdate: FunctionComponent<{ [prop: string]: any }> = (
  props
) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
      <path d="M29.3 13.2c-.2-.6-.8-1-1.4-.8-.6.2-1 .8-.8 1.4.6 3.1-.2 6.6-2.5 9.3-3.1 3.8-8 5-12.3 3.4l1.9-2.3c.2 0 .2-.2.4-.4.1.1.2.2.4.2.3 0 .5-.2.5-.5V22h1v1.5c0 .3.2.5.5.5s.5-.2.5-.5V22h1v1.5c0 .3.2.5.5.5s.5-.2.5-.5V22c1.4 0 2.5-1.1 2.5-2.5h1.5c.3 0 .5-.2.5-.5s-.2-.5-.5-.5H22v-1h1.5c.3 0 .5-.2.5-.5s-.2-.5-.5-.5H22v-1h1.5c.3 0 .5-.2.5-.5s-.2-.5-.5-.5H22v-1h1.5c.3 0 .5-.2.5-.5s-.2-.5-.5-.5H22c0-1.4-1.1-2.5-2.5-2.5v-.9h5.8c.4 0 .6-.2.6-.6v-.2l-1.2-6.8c0-.4-.4-.6-.6-.4-.4 0-.6 0-.6.2l-1.8 2.2c-2.5-1.1-5.4-1.5-8.3-1C6.1 3.9 1.3 11.1 2.7 18.4c.2.6.8 1 1.5 1 .6-.2 1-.8.8-1.4-.6-2.5-.2-5.2 1.2-7.5 2.7-5 8.7-7.2 14-5L18 8.2c-.2.2-.2.6 0 .8.2 0 .2.2.4.2h.2v.8h-1V8.5c-.1-.3-.3-.5-.6-.5s-.5.2-.5.5V10h-1V8.5c0-.3-.2-.5-.5-.5s-.5.2-.5.5V10h-1V8.5c0-.3-.2-.5-.5-.5s-.5.2-.5.5V10c-1.4 0-2.5 1.1-2.5 2.5H8.5c-.3 0-.5.2-.5.5s.2.5.5.5H10v1H8.5c-.3 0-.5.2-.5.5s.2.5.5.5H10v1H8.5c-.3 0-.5.2-.5.5s.2.5.5.5H10v1H8.5c-.3 0-.5.2-.5.5s.2.5.5.5H10c0 1.4 1.1 2.5 2.5 2.5v1.2H6.9c-.2 0-.4.2-.4.6l1.2 6.8c0 .2 0 .4.2.4.2.2.6.2.8 0l2.2-2.7c2.3.9 5 1.2 7.6.8 7.4-1.4 12.2-8.6 10.8-15.9zM11 19.5v-7c0-.8.7-1.5 1.5-1.5h7c.8 0 1.5.7 1.5 1.5v7c0 .8-.7 1.5-1.5 1.5h-7c-.8 0-1.5-.7-1.5-1.5zm2.5 2.5h1v1.4c-.1-.1-.2-.1-.4-.1h-.6V22z" />
      <path d="M14.5 19h3c.8 0 1.5-.7 1.5-1.5v-3c0-.8-.7-1.5-1.5-1.5h-3c-.8 0-1.5.7-1.5 1.5v3c0 .8.7 1.5 1.5 1.5zm-.5-4.5c0-.3.2-.5.5-.5h3c.3 0 .5.2.5.5v3c0 .3-.2.5-.5.5h-3c-.3 0-.5-.2-.5-.5v-3z" />
    </svg>
  );
};

export default AWIconFirmwareUpdate;
