import { Formik, FormikErrors } from "formik";
import React, { FunctionComponent, useMemo } from "react";
import { Form } from "reactstrap";
import { isProfileNameValid } from '../../../utils/validation-utils';
import {
  AWFormErrorNameAlreadyTaken,
  AWFormErrorRequiredField,
  AWFormErrorWrongFormat,
  CommonFormProps,
  OutputHLSProfile
} from '../forms.types';
import { outputHlsLocalURIs, outputHlsPublicURI } from '../../../utils/uri-utils';
import AWFormFieldText from '../form-field-text';
import { isEmptyString } from '../../../utils/string-utils';

interface OutputHLSFormProps extends CommonFormProps {
  defaultValues: OutputHLSProfile | null;
  extendValidation?: (values: OutputHLSProfile) => FormikErrors<OutputHLSProfile>;
  forbiddenNames: string[];
  onSubmit: (values: OutputHLSProfile) => void;
}

const baseValues: OutputHLSProfile = {
  mode: 'HLS',
  name: '',
  dir: ''
}

const OutputHLSForm: FunctionComponent<OutputHLSFormProps> = ({
                                                                children,
                                                                defaultValues,
                                                                errorTexts,
                                                                extendValidation,
                                                                forbiddenNames,
                                                                formikProps = {},
                                                                formProps= {},
                                                                fieldTexts,
                                                                localIPs,
                                                                onSubmit,
                                                                publicIP,
                                                              }) => {

  const handleFormSubmit = (values: OutputHLSProfile) => {
    values = {
      ...values,
      dir: values.name,
    };
    onSubmit(values);
  };

  const commonFieldProps = { fieldTexts, errorTexts };

  const handleValidation = (values: OutputHLSProfile): FormikErrors<OutputHLSProfile> => {
    const errors: FormikErrors<OutputHLSProfile> = {};

    // Name
    if(isEmptyString(values.name)){
      errors.name = AWFormErrorRequiredField;
    }
    else if(!isProfileNameValid(values.name)){
      errors.name = AWFormErrorWrongFormat;
    }
    else if(forbiddenNames.indexOf(values.name) !== -1){
      errors.name = AWFormErrorNameAlreadyTaken;
    }

    // Additional Validation
    let additionalErrors: FormikErrors<OutputHLSProfile> = {};
    if(extendValidation){
      additionalErrors = extendValidation(values);
    }
    return {
      ...additionalErrors,
      ...errors
    };
  };

  const initialValues = useMemo(() => ({
    ...baseValues,
    ...defaultValues
  }), [defaultValues]);

  return (
    <Formik {...formikProps}
            initialValues={initialValues}
            validate={ handleValidation }
            onSubmit={ handleFormSubmit }>
      {(formikProps) => (
        <Form { ...(formProps as any) } onSubmit={ formikProps.handleSubmit }>

          <AWFormFieldText
            { ...commonFieldProps }
            name="name"/>

          { children && children({
            ...formikProps,
            info: {
              publicURI: publicIP ? outputHlsPublicURI(publicIP, formikProps.values) : null,
              localURI: localIPs ? outputHlsLocalURIs(localIPs, formikProps.values) : null
            }
          })}
        </Form>
      )}
    </Formik>
  );
};


export default OutputHLSForm;