import React, {Fragment, FunctionComponent, Ref, useRef, useState} from "react";
import {Popover} from "reactstrap";

interface AWConfirmPropTypes {
    confirmationText: string;
    onConfirm: () => void;
    render: (containerRef: Ref<any>, onClick: () => void, waitingConfirmation: boolean) => any;
    [x: string]: any;
}

const AWConfirm: FunctionComponent<AWConfirmPropTypes> = ({ className, confirmationText, onConfirm, render, ...otherProps }) => {

    const containerRef = useRef(null)
    const [timer, setTimer] = useState<ReturnType<typeof setTimeout> | null>(null)
    const [popoverOpened, setPopoverOpened] = useState(false)
    const [waitingForConfirmation, setWaitingForConfirmation] = useState(false)

    const handleClick = () => {
        if(!waitingForConfirmation){
            clearTimeout(timer as any);
            setWaitingForConfirmation(true)
            setPopoverOpened(true)
            setTimer(setTimeout(() => {
                setWaitingForConfirmation(false)
                setPopoverOpened(false)
            }, 1500))
        }
        else {
            clearTimeout(timer as any);
            setWaitingForConfirmation(false)
            setPopoverOpened(false)
            onConfirm()
        }
    }

    const togglePopover = () => {
        setPopoverOpened(!popoverOpened)
    }

    return (
        <Fragment>
            { render(containerRef, handleClick, waitingForConfirmation) }
            <Popover {...otherProps}
                     className={`confirm-popup${className ? ' ' + className : ''}`}
                     container={containerRef}
                     isOpen={popoverOpened}
                     placement="left"
                     target={containerRef}
                     toggle={togglePopover}>
                { confirmationText }
            </Popover>
        </Fragment>
    );
}

export default AWConfirm;
