import { Formik, FormikErrors } from "formik";
import React, { FunctionComponent, useMemo } from "react";
import { Form } from "reactstrap";
import { isProfileNameValid } from "../../../utils/validation-utils";
import {
	AWFormErrorNameAlreadyTaken,
	AWFormErrorRequiredField,
	AWFormErrorWrongFormat,
	CommonFormProps,
	OutputRTSPProfile,
} from "../forms.types";
import { outputRtspLocalURIs, outputRtspPublicURI, } from "../../../utils/uri-utils";
import AWFormFieldText from "../form-field-text";
import AWFormFieldPassword from '../form-field-password';
import { shouldDisplayField } from '../forms.utils';
import { isEmptyString } from '../../../utils/string-utils';

interface OutputRTSPFormProps extends CommonFormProps {
	defaultValues: OutputRTSPProfile | null;
	extendValidation?: (
		values: OutputRTSPProfile
	) => FormikErrors<OutputRTSPProfile>;
	forbiddenNames: string[];
	forbiddenUris: string[];
	rtspServer: { port: number };
	onSubmit: (values: OutputRTSPProfile) => void;
}

const baseValues: OutputRTSPProfile = {
	mode: "RTSP",
	name: '',
	label: '',
	login: '',
	password: '',
};

const OutputRTSPForm: FunctionComponent<OutputRTSPFormProps> = ({
	children,
	defaultValues,
	errorTexts,
	extendValidation,
	forbiddenNames,
	formikProps = {},
	formProps = {},
	fieldTexts,
	localIPs,
	visibleFields,
	onSubmit,
	publicIP,
	rtspServer,
}) => {
	const handleFormSubmit = (values: OutputRTSPProfile) => {
		values = {
			...values,
			label: values.name,
		};
		onSubmit(values);
	};

	const commonFieldProps = { fieldTexts, errorTexts };

	const handleValidation = (
		values: OutputRTSPProfile
	): FormikErrors<OutputRTSPProfile> => {
		const errors: FormikErrors<OutputRTSPProfile> = {};

		// Name
		if (isEmptyString(values.name)) {
			errors.name = AWFormErrorRequiredField;
		} else if(!isProfileNameValid(values.name)){
			errors.name = AWFormErrorWrongFormat;
		} else if (forbiddenNames.indexOf(values.name) !== -1) {
			errors.name = AWFormErrorNameAlreadyTaken;
		}
		// login
		if (isEmptyString(values.login) && !isEmptyString(values.password)) {
			errors.login = AWFormErrorRequiredField;
		}
		// password
		if (!isEmptyString(values.login) && isEmptyString(values.password)) {
			errors.password = AWFormErrorRequiredField;
		}
		// Additional Validation
		let additionalErrors = {};
		if (extendValidation) {
			additionalErrors = extendValidation(values);
		}
		return {
			...additionalErrors,
			...errors,
		};
	};

	const initialValues = useMemo(
		() => ({
			...baseValues,
			...defaultValues
		}),
		[defaultValues]
	);

	return (
		<Formik
			{...formikProps}
			initialValues={initialValues}
			validate={handleValidation}
			onSubmit={handleFormSubmit}
		>
			{(formikProps) => (
				<Form {...(formProps as any)} onSubmit={formikProps.handleSubmit}>
					<AWFormFieldText
						{...commonFieldProps}
						name="name"
					/>
					{ shouldDisplayField('login', visibleFields) && (
						<AWFormFieldText
							{...commonFieldProps}
							name="login"
						/>
					)}
					{ shouldDisplayField('password', visibleFields) && (
						<AWFormFieldPassword
							previewEnabled={true}
							{...commonFieldProps}
							name="password"
						/>
					)}

					{children &&
						children({
							...formikProps,
							info: {
								publicURI: publicIP
									? outputRtspPublicURI(publicIP, formikProps.values, rtspServer)
									: null,
								localURI: localIPs
									? outputRtspLocalURIs(localIPs, formikProps.values, rtspServer)
									: null,
							},
						})}
				</Form>
			)}
		</Formik>
	);
};

export default OutputRTSPForm;
