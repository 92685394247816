import React, {FunctionComponent} from "react"

const AWIconToggleOff: FunctionComponent<{ [prop: string]: any }> = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 600 300"
            enableBackground="new 0 0 600 300"
             { ...props }>
            <path
                    d="M 3.00,150.00
                    C 3.00,66.90 69.90,0.00 153.00,0.00
                      153.00,0.00 447.00,0.00 447.00,0.00
                      530.10,0.00 597.00,66.90 597.00,150.00
                      597.00,233.10 530.10,300.00 447.00,300.00
                      447.00,300.00 153.00,300.00 153.00,300.00
                      69.90,300.0 3.00,233.10 3.00,150.00 Z
                    M 35.00,150.00
                    C 35.00,215.90 87.10,269.00 153.00,269.00
                      153.00,269.00 447.00,269.00 447.00,269.00
                      512.90,269.00 565.00,215.90 565.00,150.00
                      565.00,84.10 512.90,31.00 447.00,31.00
                      447.00,31.00 153.00,31.00 153.00,31.00
                      87.10,31.00 35.00,84.10 35.00,150.00 Z
                    M 62.00,150.00
                    C 62.00,98.00 104.00,56.00 156.00,56.00
                      208.00,56.00 250.00,98.00 250.00,150.00
                      250.00,202.00 208.00,244.00 156.00,244.00
                      104.00,244.00 62.00,202.00 62.00,150.00 Z" />
            <path
                    d="M 317.50,91.00
                    C 348.20,91.00 373.00,116.00 373.00,147.00
                      373.00,178.00 348.20,203.00 317.50,203.00
                      286.80,203.00 262.00,178.00 262.00,147.00
                      262.00,116.00 286.80,91.00 317.50,91.00 Z
                    M 317.50,109.00
                    C 296.20,109.00 279.00,126.00 279.00,147.00
                      279.00,168.00 296.20,185.00 317.50,185.00
                      338.80,185.00 356.00,168.00 356.00,147.00
                      356.00,126.00 338.80,109.00 317.50,109.00 Z
                    M 391.00,91.00 456.00,91.00 456.00,109.00
                      409.00,109.00 409.00,141.00 450.00,141.00
                      450.00,159.00 409.00,159.00 409.00,203.00
                      391.00,203.00 Z
                    M 475.00,91.00 540.00,91.00 540.00,109.00
                      493.00,109.00 493.00,141.00 534.00,141.00
                      534.00,159.00 493.00,159.00 493.00,203.00
                      475.00,203.00 Z" />
        </svg>
    )
}

export default AWIconToggleOff