import { Formik, FormikErrors } from "formik";
import React, { Fragment, FunctionComponent, useMemo } from "react";
import { Form } from "reactstrap";
import { isProfileNameValid, isURLValid } from "../../../utils/validation-utils";
import {
	AWFormErrorNameAlreadyTaken,
	AWFormErrorRequiredField,
	AWFormErrorURiAlreadyTaken,
	AWFormErrorWrongFormat,
	CommonFormProps,
	OutputRTMPProfile,
} from "../forms.types";
import {
	outputRtmpPullLocalURIs,
	outputRtmpPullPublicURI,
	outputRtmpPushURI,
} from "../../../utils/uri-utils";
import AWFormFieldText from "../form-field-text";
import AWFormFieldSwitch from "../form-field-switch";
import AWFormFieldPassword from "../form-field-password";
import { shouldDisplayField } from '../forms.utils';
import { isEmptyString } from '../../../utils/string-utils';

interface OutputRTMPFormProps extends CommonFormProps {
	defaultValues: OutputRTMPProfile | null;
	extendValidation?: (
		values: OutputRTMPProfile
	) => FormikErrors<OutputRTMPProfile>;
	forbiddenNames: string[];
	forbiddenUris: string[];
	onSubmit: (values: OutputRTMPProfile) => void;
}

const baseValues: OutputRTMPProfile = {
	mode: "RTMP",
	name: "",
	serverMode: false,
	streamName: "",
	uri: "rtmp://",
	streamKey: "",
	login: "",
	password: "",
};

const OutputRTMPForm: FunctionComponent<OutputRTMPFormProps> = ({
	children,
	defaultValues,
	errorTexts,
	extendValidation,
	forbiddenNames,
	forbiddenUris,
	formikProps = {},
	formProps = {},
	fieldTexts,
	visibleFields,
	localIPs,
	onSubmit,
	publicIP,
}) => {
	const handleFormSubmit = (values: OutputRTMPProfile) => {
		const finalValues = { ...values };
		if(values.serverMode){
			finalValues.streamName = finalValues.name;
		}
		onSubmit(finalValues);
	};

	const commonFieldProps = { fieldTexts, errorTexts };
	const uriList = (values: OutputRTMPProfile) => {
		let uris = null;
		if (values.serverMode) {
			uris = outputRtmpPullLocalURIs(localIPs, values);
			uris && uris.push(outputRtmpPullPublicURI(publicIP, values));
		} else {
			uris = [outputRtmpPushURI(values)];
		}
		return uris ? uris : [];
	};
	const handleValidation = (
		values: OutputRTMPProfile
	): FormikErrors<OutputRTMPProfile> => {
		const errors: FormikErrors<OutputRTMPProfile> = {};

		// Name
		if (isEmptyString(values.name)) {
			errors.name = AWFormErrorRequiredField;
		} else if(!isProfileNameValid(values.name)){
			errors.name = AWFormErrorWrongFormat;
		} else if (forbiddenNames.indexOf(values.name) !== -1) {
			errors.name = AWFormErrorNameAlreadyTaken;
		}

		const uris = uriList(values);
		if (
			uris !== null &&
			uris.find((uri) => forbiddenUris.indexOf(uri) !== -1) !== undefined
		) {
			errors.uri = AWFormErrorURiAlreadyTaken;
			errors.streamKey = AWFormErrorURiAlreadyTaken;
			errors.streamName = AWFormErrorURiAlreadyTaken;
		}

		if (!values.serverMode) {
			if (isEmptyString(values.uri)) {
				// uri
				errors.uri = AWFormErrorRequiredField;
			} else if(!isURLValid(values.uri)){
				errors.uri = AWFormErrorWrongFormat;
			}
			// login
			if (isEmptyString(values.login)) {
				errors.login = AWFormErrorRequiredField;
			}
			// password
			if (isEmptyString(values.password)) {
				errors.password = AWFormErrorRequiredField;
			}
		}
		// streamKey
		if (isEmptyString(values.streamKey)) {
			errors.streamKey = AWFormErrorRequiredField;
		}
		// Additional Validation
		let additionalErrors = {};
		if (extendValidation) {
			additionalErrors = extendValidation(values);
		}
		return {
			...additionalErrors,
			...errors,
		};
	};

	const initialValues = useMemo(
		() => ({
			...baseValues,
			...defaultValues,
		}),
		[defaultValues]
	);

	return (
		<Formik
			{...formikProps}
			initialValues={initialValues}
			validate={handleValidation}
			onSubmit={handleFormSubmit}
		>
			{(formikProps) => (
				<Form {...(formProps as any)} onSubmit={formikProps.handleSubmit}>
					<AWFormFieldText {...commonFieldProps} name="name" />
					{ shouldDisplayField('serverMode', visibleFields) && (
						<AWFormFieldSwitch {...commonFieldProps} name="serverMode" />
					)}
					{formikProps.values.serverMode === false && (
						<Fragment>
							{ shouldDisplayField('uri', visibleFields) && (
								<AWFormFieldText {...commonFieldProps} name="uri" />
							)}
							{ shouldDisplayField('streamKey', visibleFields) && (
								<AWFormFieldText {...commonFieldProps} name="streamKey" />
							)}
							{ shouldDisplayField('login', visibleFields) && (
								<AWFormFieldText {...commonFieldProps} name="login" />
							)}
							{ shouldDisplayField('password', visibleFields) && (
								<AWFormFieldPassword
									previewEnabled={true}
									{...commonFieldProps}
									name="password"
								/>
							)}
						</Fragment>
					)}
					{formikProps.values.serverMode && (
						<Fragment>
							{ shouldDisplayField('streamKey', visibleFields) && (
								<AWFormFieldText {...commonFieldProps} name="streamKey" />
							)}
						</Fragment>
					)}
					{children &&
						children({
							...formikProps,
							info: {
								publicURI:
									publicIP && formikProps.values.serverMode
										? outputRtmpPullPublicURI(publicIP, formikProps.values)
										: null,
								localURI:
									localIPs && formikProps.values.serverMode
										? outputRtmpPullLocalURIs(localIPs, formikProps.values)
										: null,
							},
						})}
				</Form>
			)}
		</Formik>
	);
};

export default OutputRTMPForm;
