export const isEmptyString = (input?: any): boolean => {
  return isString(input) && input.trim().length === 0;
};

export const isString = (input?: any) => {
  return input != null && typeof input === 'string';
};

export const randomString = (length: number) => {
  return Math.random().toString(36).substring(length);
};