import { Field, FieldProps } from "formik";
import React, { Fragment, FunctionComponent } from "react";
import { FormFeedback, FormGroup, Input, Label, UncontrolledTooltip } from 'reactstrap';
import { AWFormFieldCommonProps, AWFormFieldErrorName } from './forms.types';
import { getErrorText, getText } from './forms.utils';
import AWIcon from '../icon';

interface AWFormFieldCheckboxProps extends AWFormFieldCommonProps {
  onValueChange?: (value: boolean) => void;
}

const AWFormFieldCheckbox: FunctionComponent<AWFormFieldCheckboxProps> = ({
                                                                            componentProps = {},
                                                                            errorTexts,
                                                                            fieldTexts,
                                                                            hintPosition= 'bottom',
                                                                            onValueChange,
                                                                            wrapperProps = {},
                                                                            ...fieldProps
                                                                          }) => {
  const { value: componentValue, type, ...otherComponentProps } = componentProps;

  // Texts
  const label = getText(fieldTexts, fieldProps.name, 'label');
  const hint = getText(fieldTexts, fieldProps.name, 'hint');

  // Computing id
  if(!otherComponentProps.id){
    otherComponentProps.id = componentValue ? `${fieldProps.name}_${componentValue}` : fieldProps.name;
  }

  const handleChange = (newValue: boolean, setFieldValue: any) => {
    if(onValueChange) onValueChange(newValue);
    setFieldValue(fieldProps.name, newValue);
  }

  return (
    <Field
      {...fieldProps}>
      {(props: FieldProps) => {
        const { field, form, meta } = props;
        const { value, ...otherFormikFieldProps } = field;

        // Computing value props
        const valueProps: { [x: string]: any } = {};
        // Checkbox - we use 'checked' prop, not 'value'
        if(typeof value === 'boolean'){
          valueProps.checked = value;
        }



        return (
          <FormGroup { ...wrapperProps } check={true}>
            <Label for={ otherComponentProps.id }>
              <Input type="checkbox"
                     disabled={fieldProps.disabled}
                     invalid={meta.error != null && meta.touched} // Used for displaying error
                     {...otherComponentProps } // Spreading component props, except 'options' and 'value' props
                     {...otherFormikFieldProps } // Spreading all Formik field props: onChange, onBlur, etc...
                     {...valueProps }
                     onChange={(e) => handleChange(e.target.checked, form.setFieldValue)}/>
              { label }
              { hint && hintPosition === 'icon' &&
              <Fragment>
                  <AWIcon className="form-hint"
                          id={`${otherComponentProps.id}_hint`}
                          name="info_circle"/>
                  <UncontrolledTooltip aria-describedby={`${otherComponentProps.id}_hint`}
                                       placement="top"
                                       target={`${otherComponentProps.id}_hint`}
                                       trigger="hover">
                    {() => (
                      <div>{ hint }</div>
                    )}
                  </UncontrolledTooltip>
              </Fragment>
              }
            </Label>
            <FormFeedback>
              { getErrorText(errorTexts, meta.error as AWFormFieldErrorName) }
            </FormFeedback>
            { hint && hintPosition === 'bottom' &&
            <div className="indicator">
              { hint }
            </div>
            }
          </FormGroup>
        );
      }}
    </Field>
  );
}

export default AWFormFieldCheckbox;