import { ReactNode, ReactNodeArray } from "react";
import { FormikConfig, FormikProps } from "formik";
import { FormProps } from "reactstrap";
import { FieldAttributes } from "formik";

export interface CommonFormProps {
	children?: (props: FormikProps<any> & {
		info?: { [x: string]: any };
	}) => ReactNode | ReactNodeArray;
	errorTexts: AWFormFieldErrors;
	fieldTexts: AWFormFieldTexts;
	formikProps?: FormikConfig<any>;
	formProps?: FormProps; //{ [x: string] : any }; // Using 'FormProps' type from reactstrap doesn't work here, don't know why
	visibleFields?: AWFormVisibleFields;
	localIPs?: string[];
	publicIP?: string;
}

export interface AWFormFieldCommonProps extends FieldAttributes<any> {
	componentProps?: any;
	wrapperProps?: any;
	errorTexts: AWFormFieldErrors;
	fieldTexts: AWFormFieldTexts;
	hintPosition?: "icon" | "bottom";
}

export type AWFormFieldTexts = {
	[prop: string]: {
		label?: string;
		labels?: {
			//Specific to Radio / Switch inputs
			[value: string]: string;
		};
		placeholder?: string; //Not necessary for Checkbox/Radio/Switch/Select
		placeholders?: {
			//Specific to Radio / Switch inputs
			[value: string]: string;
		};
		hint?: string;
		hints?: {
			//Specific to Radio / Switch inputs
			[value: string]: string;
		};
		options?: {
			//Specific select
			[value: string]: string;
		};
	};
};

export interface AWFormFieldErrors {
	[prop: string]: string;
}

export const AWFormErrorRequiredField = "requiredField";
export const AWFormErrorWrongFormat = "wrongFormat";
export const AWFormErrorNameAlreadyTaken = "nameAlreadyTaken";
export const AWFormErrorURiAlreadyTaken = "uriAlreadyTaken";
export const AWFormErrorPortAlreadyTaken = "portAlreadyTaken";

export type AWFormFieldErrorName =
	| typeof AWFormErrorRequiredField
	| typeof AWFormErrorWrongFormat
	| typeof AWFormErrorNameAlreadyTaken
	| typeof AWFormErrorPortAlreadyTaken;

export type AWFormVisibleFields = { [fieldName: string]: boolean };

export interface CommonProfile {
	mode: string;
	id?: string;
	name: string;
	[otherProp: string]: any;
}

export interface OutputHLSProfile extends CommonProfile {
	dir: string;
}

export interface OutputTSProfile extends CommonProfile {
	bitrate: number;
	delay: number;
	ttl: number;
	destinations: string;
	audioPID: number;
	videoPID: number;
	pmtPID: number;
	pcrPID: number;
	protocol: string | "UDP" | "RTP";
	multicast: boolean;
}

export interface OutputRTMPProfile extends CommonProfile {
	serverMode: boolean;
	streamName: string;
	uri: string;
	streamKey: string;
	login: string;
	password: string;
}

export interface OutputRTSPProfile extends CommonProfile {
	label: string;
	login: string;
	password: string;
}

export interface OutputSRTProfile extends CommonProfile {
	serverMode: boolean;
	port: number | '';
	uri: string;
	latency: number | '';
	keyLength: string;
	password?: string;
	cbrMode: boolean,
	bitrate?: number | '';
}

export interface OutputSSTProfile extends CommonProfile {
	ipAddress: string;
	destChannel: string;
	port: number | '';
	delay: number | '';
}
