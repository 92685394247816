import React, {FunctionComponent} from "react"

const AWIconBatteyLevel20: FunctionComponent<{ [prop: string]: any }> = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg"
            viewBox="12 14 162 162"
            enableBackground="new 12 14 162 162"
             { ...props }>
            <path
              d="M 75.57,40.39
              C 75.57,40.39 68.34,47.63 68.34,47.63
              49.41,66.55 47.63,96.01 63.30,116.80
              63.30,116.80 27.32,152.78 27.32,152.78
              24.13,155.97 24.13,161.14 27.32,164.33
              30.51,167.52 35.69,167.52 38.88,164.33
              38.88,164.33 74.95,128.26 74.95,128.26
              96.44,143.72 126.78,141.94 146.12,122.61
              146.12,122.61 151.98,116.74 151.98,116.74
              155.38,113.34 155.38,107.82 151.98,104.41
              151.98,104.41 145.13,97.57 145.13,97.57
              145.13,97.57 164.68,78.03 164.68,78.03
              167.87,74.84 167.87,69.67 164.68,66.48
              161.49,63.29 156.31,63.29 153.12,66.48
              153.12,66.48 133.57,86.00 133.57,86.00
              133.57,86.00 106.33,58.76 106.33,58.76
              106.33,58.76 125.87,39.22 125.87,39.22
              129.06,36.03 129.06,30.86 125.87,27.67
              122.68,24.48 117.50,24.48 114.31,27.67
              114.31,27.67 94.77,47.21 94.77,47.21
              94.77,47.21 87.95,40.39 87.95,40.39
              84.54,36.97 79.00,36.97 75.57,40.39 Z" />
        </svg>
    )
}

export default AWIconBatteyLevel20