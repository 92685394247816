import {
  CSSProperties,
  FunctionComponent,
  ReactElement,
  ReactNode,
} from "react";
import React from "react";

interface AWAuthLayoutProps {
  backgroundImage?: string;
  title: string | ReactNode;
  subtitle?: string | ReactNode;
  children: ReactElement;
  point?: boolean;
}

const AWAuthLayout: FunctionComponent<AWAuthLayoutProps> = ({
  backgroundImage,
  title,
  subtitle,
  children,
  point = true,
}) => {
  const backgroundStyle: CSSProperties = {};
  if (backgroundImage) {
    backgroundStyle.backgroundImage = `url('${backgroundImage}')`;
  }

  return (
    <div id="auth-layout">
      <div id="auth-background" style={backgroundStyle} />
      <div id="auth-background-color" />
      <div id="auth-body">
        <div className="content">{children}</div>
        <div className="side-content">
          <div className="baseline">
            {title}
            {point && <span className="point">.</span>}
            {subtitle && (
              <div className="tagline">
                {subtitle}
                {point && <span className="point">.</span>}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AWAuthLayout;
