import React, {FunctionComponent} from "react"

const AWIconStreamhub: FunctionComponent<{ [prop: string]: any }> = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg"
             version="1.1"
             id="Layer_1"
             x="0px"
             y="0px"
             viewBox="0 0 443 403.25"
             enableBackground="new 0 0 1024 1024"
             {...props}>
            <g id="g1528"
               transform="translate(-290.5,-310.375)">
                <path
                    d="M 702,631.375 H 322 c -15.164,0 -27.5,-12.337 -27.5,-27.5 v -262 c 0,-15.164 12.336,-27.5 27.5,-27.5 h 380 c 15.163,0 27.5,12.336 27.5,27.5 v 262 c 0,15.163 -12.337,27.5 -27.5,27.5 z M 325.996,332.393 c -6.748,0 -12.237,5.489 -12.237,12.237 v 256.49 c 0,6.747 5.49,12.237 12.237,12.237 h 372.008 c 6.748,0 12.237,-5.49 12.237,-12.237 V 344.63 c 0,-6.748 -5.489,-12.237 -12.237,-12.237 z"
                    id="path1514"
                    style={{
                        fill: '#ffffff',
                        fillOpacity: 1
                    }}/>
                <path
                    d="m 592,692.625 h -50.5 v -44 h -59 v 44 H 432 c -4.694,0 -8.5,3.806 -8.5,8.5 0,4.694 3.806,8.5 8.5,8.5 h 160 c 4.694,0 8.5,-3.806 8.5,-8.5 0,-4.694 -3.806,-8.5 -8.5,-8.5 z"
                    id="path1516"
                    style={{
                        fill: '#ffffff',
                        fillOpacity: 1
                    }}/>
                <polygon
                    points="478.588,525.752 478.588,425.498 565.412,475.625 "
                    id="polygon1518"
                    style={{
                        fill: '#ffffff',
                        fillOpacity: 1
                    }}/>
                <path
                    d="m 673,428.498 h -16 v -53.439 c -44.227,8.068 -94.12,12.317 -145,12.317 -54.581,0 -108.027,-4.889 -154.56,-14.138 l 3.119,-15.693 c 45.522,9.049 97.889,13.832 151.44,13.832 53.551,0 105.918,-4.783 151.44,-13.832 l 9.56,-1.9 v 72.853 z"
                    id="path1520"
                    style={{
                        fill: '#ffffff',
                        fillOpacity: 1
                    }}/>
                <rect
                    x="657"
                    y="467.22198"
                    width="16"
                    height="31.499001"
                    id="rect1522"
                    style={{
                        fill: '#ffffff',
                        fillOpacity: 1
                    }}/>
                <path
                    d="m 351,590.105 v -72.854 h 16 v 53.439 c 44.227,-8.068 94.12,-12.316 145,-12.316 54.582,0 108.027,4.889 154.56,14.138 l -3.119,15.693 c -45.521,-9.049 -97.889,-13.831 -151.44,-13.831 -53.551,0 -105.918,4.782 -151.44,13.831 z"
                    id="path1524"
                    style={{
                        fill: '#ffffff',
                        fillOpacity: 1
                    }}/>
                <rect
                    x="351"
                    y="447.02899"
                    width="16"
                    height="31.499001"
                    id="rect1526"
                    style={{
                        fill: '#ffffff',
                        fillOpacity: 1
                    }}/>
            </g>
        </svg>
    )
}

export default AWIconStreamhub