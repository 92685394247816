import React, {CSSProperties, FunctionComponent, Fragment} from 'react';

interface AWVuMeterProps {
    max?: number
    min?: number
    peakValue: number
    decayValue: number
    horizontal?: boolean
    thickness?: number
    disabled?: boolean
    [x: string]: any;
}

const AWVuMeter: FunctionComponent<AWVuMeterProps> = ({
                                                          horizontal = false,
                                                          max = 100,
                                                          min = 0,
                                                          peakValue,
                                                          decayValue,
                                                          thickness = 6,
                                                          disabled,
                                                          ...otherProps
}) => {

    const peakPercentage = Math.abs(peakValue - min) / Math.abs(max - min);
    const decayPercentage = Math.abs(decayValue - min) / Math.abs(max - min);
    const peakStyle: CSSProperties = {};
    const decayStyle: CSSProperties = {};
    const wrapperStyle: CSSProperties = {};
    if(horizontal){
        peakStyle.left = `${ peakPercentage * 100}%`;
        decayStyle.left = `${ decayPercentage * 100}%`;
        wrapperStyle.height = thickness;
    }
    else {
        peakStyle.height = `${ (1 - peakPercentage) * 100}%`;
        decayStyle.height = `${ (1 - decayPercentage) * 100}%`;
        wrapperStyle.width = thickness;
    }
    return (
        <div {...otherProps} className={ `vu-meter${horizontal ? ' horizontal': ''}${disabled ? ' disabled': ''}` } style={wrapperStyle}>
            {!disabled && <Fragment>
                <div className="level-danger"/>
                <div className="level-warning"/>
                <div className="level-peak" style={peakStyle}/>
                <div className="level-decay" style={decayStyle}/>
            </Fragment>}
        </div>
    )
}

export default AWVuMeter;