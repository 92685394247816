import React, {FunctionComponent} from "react"

const AWIconVideoReturnProfileOff: FunctionComponent<{ [prop: string]: any }> = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="11 27 176 167" {...props}>
        <path d="M116.6 127.7l-33.7-19.4 24.6 24.6zM137.3 172.6l-7.9-7.9h-.2l-28-28.1h.1l-34.7-34.7v.1L55.1 90.5h.2l-7.9-7.9h-.2L16 51.4l-5 4.9 26.3 26.3 7.9 7.9 21.4 21.4v.1l28.3 28.3v-.1l24.4 24.5 7.9 7.9 21.4 21.4 5-4.9-16.5-16.5zM31.5 164.7c-5.6 0-10.1-4.5-10.1-10.1v-54c0-5.6 4.5-10.1 10.1-10.1h3.8l-7.9-7.9h-2.7c-6.1 0-11.2 5.1-11.2 11.3v67.5c0 6.2 5.1 11.2 11.2 11.2h92.6l-7.9-7.9H31.5z" />
        <path d="M66.6 156.6l22-12.7-22-22zM142.9 66.7zM186.7 75.6c0-9.3-2.7-18-7.2-25.4-5.4-9.4-14.1-17-26.2-20.5-4.7-1.5-9.8-2.4-15-2.4-6.1 0-12 1.2-17.4 3.2-8.8 3.1-15.6 8.5-20.6 15.1-6.6 8.3-10.5 18.7-10.5 30 0 2.3.2 4.6.5 6.9h-33l7.9 7.9h27c1.4 4.5 3.5 8.7 6.2 12.5 7.6 12.1 21 20.9 40.1 21.2 3.2-.1 6.2-.4 9-.8v31.2c0 5-3.6 9.1-8.3 9.9l7.7 7.7c4.8-1.3 8.4-5.7 8.4-10.8v-40c12.7-4.4 21.5-13.4 26.3-24.1 3.2-6.5 5.1-13.8 5.1-21.6zm-42.8 2.9c-.6 0-1-.5-1-1V66.7c-16.3 2.9-27.6 16.8-27.6 33.9 0 2.7.3 5.4.9 8-4.4-5.8-8.2-12.9-8.2-20.8 0-19.1 15.6-34.6 34.9-34.6v-9.6c0-.6.4-1 1-1 .2 0 .4.1.5.1l27.4 16.9c.3.2.5.5.5.9s-.2.7-.5.9l-27.4 16.9c-.2.1-.4.2-.5.2z" />
      </svg>
    )
}

export default AWIconVideoReturnProfileOff