import React, { FunctionComponent } from "react";

const AWIconEncoder2: FunctionComponent<{ [prop: string]: any }> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 384 512"
      viewBox="0 0 384 512"
      { ...props }
    >
      <path d="M384 212h-68v-70h68V68h-68V0h-74v68h-70V0H23.6C7.6-.8-1.7 18.3 0 24.1v464.3C-1.1 503 17.1 513.8 24.1 512c7.2-.1 335.7.1 336.3 0 14.5 1 25.3-16.9 23.6-24.1V212zm-93.8 59.1l-137.5 81.2c-12.4 7.4-28.3-1.5-28.3-16.1V173.7c0-16.3 17.1-22.7 28.3-16.1l137.5 81.3c12.3 7.2 12.3 25 0 32.2zM314 140h-70V70h70v70z"></path>
    </svg>
  );
};

export default AWIconEncoder2;
