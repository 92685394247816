import React, {FunctionComponent} from "react"

const AWIconDataBridge: FunctionComponent<{ [prop: string]: any }> = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 16 16"
            enableBackground="new 0 0 16 16"
             { ...props }>
            <path d="M13.2,4.6C12.9,2,10.7,0,8,0C6.7,0,5.4,0.5,4.4,1.3C3.6,2,3.1,2.9,2.9,3.7C1.3,4.1,0,5.6,0,7.3c0,2,1.7,3.7,3.8,3.7l0.1,0
              c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5l-0.1,0C2.2,10,1,8.8,1,7.3C1,6,2.1,4.8,3.5,4.7l0.4,0V4.2c0-0.6,0.4-1.4,1.2-2.1
              C5.9,1.4,6.9,1,8,1c2.3,0,4.2,1.8,4.2,4v0.5h0.5c1.3,0,2.3,1,2.3,2.2C15,9,14,10,12.7,10l-0.4,0c-0.3,0-0.5,0.2-0.5,0.5
              c0,0.3,0.2,0.5,0.5,0.5l0.4,0c1.8,0,3.3-1.4,3.3-3.2C16,6.1,14.8,4.8,13.2,4.6z"/>
            <path d="M8.3,12.1l-2.1,2.1V5.5C6.1,5.2,5.9,5,5.6,5C5.4,5,5.1,5.2,5.1,5.5v8.8L3,12.1c-0.2-0.2-0.5-0.2-0.7,0s-0.2,0.5,0,0.7l3,3
              C5.5,16,5.8,16,6,15.9c0,0,0,0,0,0l3-3c0.2-0.2,0.2-0.5,0-0.7S8.5,12,8.3,12.1z"/>
            <path d="M13.7,8.9c0.2-0.2,0.2-0.5,0-0.7l-3-3C10.5,5,10.2,5,10,5.1c0,0,0,0,0,0l-3,3C6.8,8.3,6.8,8.7,7,8.9c0.2,0.2,0.5,0.2,0.7,0
              l2.1-2.1v8.8c0,0.3,0.2,0.5,0.5,0.5s0.5-0.2,0.5-0.5V6.7L13,8.9C13.2,9.1,13.5,9.1,13.7,8.9z"/>
        </svg>
    )
}

export default AWIconDataBridge