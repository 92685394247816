import { Formik, FormikErrors } from "formik";
import React, { FunctionComponent, useMemo } from "react";
import { Col, Form, Row } from "reactstrap";
import { isProfileNameValid } from "../../../utils/validation-utils";
import {
	AWFormErrorNameAlreadyTaken,
	AWFormErrorRequiredField,
	AWFormErrorWrongFormat,
	CommonFormProps,
	OutputSSTProfile,
} from "../forms.types";
import { outputSafestreamsURI } from "../../../utils/uri-utils";
import AWFormFieldText from "../form-field-text";
import AWFormFieldNumber from "../form-field-number";
import AWFormFieldSelect from "../form-field-select";
import { shouldDisplayField } from '../forms.utils';
import { isEmptyString } from '../../../utils/string-utils';

interface OutputSSTFormProps extends CommonFormProps {
	defaultValues: OutputSSTProfile | null;
	extendValidation?: (
		values: OutputSSTProfile
	) => FormikErrors<OutputSSTProfile>;
	forbiddenNames: string[];
	forbiddenUris: string[];
	destinationInputs: string[];
	onSubmit: (values: OutputSSTProfile) => void;
}

const baseValues: OutputSSTProfile = {
	mode: 'SST',
	name: '',
	ipAddress: '',
	destChannel: 'AUTO',
	port: 7900,
	delay: 1000
}

const OutputSSTForm: FunctionComponent<OutputSSTFormProps> = ({
	children,
	defaultValues,
	errorTexts,
	extendValidation,
	forbiddenNames,
	forbiddenUris,
	destinationInputs,
	formikProps = {},
	formProps = {},
	fieldTexts,
	visibleFields,
	onSubmit,
}) => {
	const handleFormSubmit = (values: OutputSSTProfile) => {
		onSubmit(values);
	};

	const commonFieldProps = { fieldTexts, errorTexts };
	const uriList = (values: OutputSSTProfile) => {
		return [outputSafestreamsURI(values)];
	};
	const handleValidation = (
		values: OutputSSTProfile
	): FormikErrors<OutputSSTProfile> => {
		const errors: FormikErrors<OutputSSTProfile> = {};

		// Name
		if (isEmptyString(values.name)) {
			errors.name = AWFormErrorRequiredField;
		} else if(!isProfileNameValid(values.name)){
			errors.name = AWFormErrorWrongFormat;
		} else if (forbiddenNames.indexOf(values.name) !== -1) {
			errors.name = AWFormErrorNameAlreadyTaken;
		}

		// ipAddress
		if (isEmptyString(values.ipAddress)) {
			errors.ipAddress = AWFormErrorRequiredField;
		}

		// delay (Latency)
		if (isEmptyString(values.delay)) {
			errors.delay = AWFormErrorRequiredField;
		}

		// destChannel
		if (isEmptyString(values.destChannel)) {
			errors.destChannel = AWFormErrorRequiredField;
		}

		// port
		if (isEmptyString(values.port)) {
			errors.port = AWFormErrorRequiredField;
		}

		const uris = uriList(values);
		if (
			uris !== null &&
			uris.find((uri) => forbiddenUris.indexOf(uri) !== -1) !== undefined
		) {
			errors.ipAddress = AWFormErrorNameAlreadyTaken;
			errors.port = AWFormErrorNameAlreadyTaken;
			errors.destChannel = AWFormErrorNameAlreadyTaken;
		}
		// Additional Validation
		let additionalErrors = {};
		if (extendValidation) {
			additionalErrors = extendValidation(values);
		}
		return {
			...additionalErrors,
			...errors,
		};
	};

	const initialValues = useMemo(
		() => ({
			...baseValues,
			...defaultValues
		}),
		[defaultValues]
	);

	return (
		<Formik
			{...formikProps}
			initialValues={initialValues}
			validate={handleValidation}
			onSubmit={handleFormSubmit}
		>
			{(formikProps) => (
				<Form {...(formProps as any)} onSubmit={formikProps.handleSubmit}>
					<AWFormFieldText
						{...commonFieldProps}
						name="name"
					/>

					<Row className="destination-row" form>
						{ shouldDisplayField('ipAddress', visibleFields) && (
							<Col xs={8}>
								<AWFormFieldText
									{...commonFieldProps}
									name="ipAddress"
								/>
							</Col>
						)}
						{ shouldDisplayField('port', visibleFields) && (
							<Col xs={4}>
								<AWFormFieldNumber
									{...commonFieldProps}
									name="port"
								/>
							</Col>
						)}
					</Row>

					{ shouldDisplayField('destChannel', visibleFields) && (
						<AWFormFieldSelect
							{...commonFieldProps}
							name="destChannel"
							options={destinationInputs}
						/>
					)}

					{ shouldDisplayField('delay', visibleFields) && (
						<AWFormFieldNumber
							{...commonFieldProps}
							name="delay"
						/>
					)}

					{children && children(formikProps)}
				</Form>
			)}
		</Formik>
	);
};

export default OutputSSTForm;
