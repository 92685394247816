import React, {FunctionComponent} from "react"

const AWIconNetworkInterfaceWifiAp: FunctionComponent<{ [prop: string]: any }> = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 192 192"
            enableBackground="new 0 0 192 192"
             { ...props }>
            <path
                    d="M 22.67,103.36
                    C 22.67,103.36 41.81,103.36 41.81,103.36
                      42.20,101.35 43.22,99.67 44.88,98.31
                      46.54,96.96 48.96,96.28 52.12,96.28
                      55.30,96.28 57.84,97.33 59.73,99.42
                      61.63,101.51 62.58,104.45 62.58,108.23
                      62.58,112.03 61.66,115.06 59.84,117.34
                      58.03,119.62 55.42,120.77 52.02,120.77
                      48.62,120.77 45.91,120.05 43.91,118.62
                      41.89,117.19 40.69,115.35 40.30,113.11
                      40.30,113.11 20.70,113.11 20.70,113.11
                      21.40,120.14 24.44,125.88 29.81,130.33
                      35.19,134.78 42.51,137.00 51.78,137.00
                      61.06,137.00 68.37,134.36 73.70,129.06
                      79.05,123.76 81.72,116.59 81.72,107.53
                      81.72,101.04 79.82,94.98 76.03,89.33
                      74.09,86.46 71.27,84.16 67.56,82.42
                      63.85,80.68 59.31,79.81 53.94,79.81
                      48.56,79.81 44.13,81.36 40.66,84.45
                      40.66,84.45 40.66,69.61 40.66,69.61
                      40.66,69.61 75.56,69.61 75.56,69.61
                      75.56,69.61 75.56,52.20 75.56,52.20
                      75.56,52.20 22.67,52.20 22.67,52.20
                      22.67,52.20 22.67,103.36 22.67,103.36 Z" />
            <path
                    d="M 132.70,137.70
                    C 137.96,137.70 142.93,136.73 147.61,134.80
                      152.29,132.86 156.21,130.34 159.39,127.25
                      166.04,120.84 170.09,113.91 171.56,106.48
                      171.56,106.48 171.56,91.41 171.56,91.41
                      171.56,91.41 127.25,91.41 127.25,91.41
                      127.25,91.41 127.25,106.48 127.25,106.48
                      127.25,106.48 150.22,106.48 150.22,106.48
                      149.22,110.43 147.23,113.59 144.25,115.95
                      141.27,118.31 137.42,119.48 132.70,119.48
                      126.13,119.48 120.89,117.36 116.98,113.11
                      113.08,108.85 111.12,103.16 111.12,96.05
                      111.12,88.93 113.08,83.25 116.98,79.00
                      120.89,74.75 126.24,72.62 133.05,72.62
                      139.86,72.62 144.81,75.60 147.91,81.55
                      147.91,81.55 171.56,81.55 171.56,81.55
                      168.78,73.05 163.87,66.40 156.83,61.61
                      149.80,56.81 141.75,54.41 132.70,54.41
                      121.02,54.41 111.14,58.34 103.06,66.19
                      94.98,74.03 90.94,83.98 90.94,96.05
                      90.94,108.11 94.98,118.07 103.06,125.92
                      111.14,133.78 121.02,137.70 132.70,137.70 Z" />
        </svg>
    )
}

export default AWIconNetworkInterfaceWifiAp