import { Input, InputProps } from "reactstrap"
import React, { FunctionComponent, useCallback, useMemo, useRef } from "react"
import Moment from "moment"
import MomentLocaleUtils from "react-day-picker/moment"

import "moment/locale/fr"
// import "react-day-picker/lib/style.css"
import { isString } from "../../utils/string-utils"
import AWPopup, { AWPopupProps } from '../popup';
import DayPicker from 'react-day-picker';

interface AWDateInputProps {
  format?: string;
  inputProps?: InputProps;
  locale?: string;
  onChange: (date: Date | null, formattedDate: string) => void;
  /** @deprecated This property is deprecated. Please use popupProps to specify popup behavior */
  overlayComponent?: any;
  /** @deprecated This property is deprecated. Please use popupProps to specify popup behavior */
  position?: 'left' | 'right';
  popupProps?: Partial<AWPopupProps>;
  /** @deprecated This property is deprecated. Please use popupProps to specify popup behavior */
  verticalPosition?: 'bottom' | 'top';
  value: string | Date | null | undefined;
  minDate?: string | Date;
  maxDate?: string | Date;
  [x: string]: any;
}

const initialPopupProps: Partial<AWPopupProps> = {
  portal: true,
  direction: 'auto',
  shadow: true
}

const AWDateInput: FunctionComponent<AWDateInputProps> = ({
                                                            format: customFormat = null,
                                                            inputProps = {},
                                                            locale: customLocale = null,
                                                            minDate,
                                                            maxDate,
                                                            onChange,
                                                            popupProps= initialPopupProps,
                                                            value,
                                                          }) => {
  const blurTimeout = useRef<any>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const { onBlur: onInputBlur, onFocus: onInputFocus, ...otherInputProps } = inputProps;

  const format = useMemo(() => {
    return customFormat ? customFormat : Moment.localeData(customLocale ? customLocale : 'en').longDateFormat('L');
  }, [customFormat, customLocale]);

  const placeholder = useMemo(() => {
    let placeholder = inputProps?.placeholder;
    if(!placeholder){
      placeholder = format;
    }
    return placeholder;
  }, [format, inputProps]);

  const disabledDays = useMemo(() => {
      let finalMinDate: Date | null = null;
      let finalMaxDate: Date | null = null;

      if (Moment.isDate(minDate)) {
        finalMinDate = minDate;
      } else if(isString(minDate)){
        const moment = Moment(minDate, format, true);
        if(moment.isValid()){
          finalMinDate = moment.toDate();
        } else {
          console.warn(`[AWDateInput] minDate: wrong date format. Should use format ${format}`);
        }
      }

      if (Moment.isDate(maxDate)) {
        finalMaxDate = maxDate;
      } else if(isString(maxDate)){
        const moment = Moment(maxDate, format, true);
        if(moment.isValid()){
          finalMaxDate = moment.toDate();
        } else {
          console.warn(`[AWDateInput] maxDate: wrong date format. Should use format ${format}`);
        }
      }

      let disabledDays: any = {};
      if (finalMinDate) {
        disabledDays.before = finalMinDate;
      }
      if (finalMaxDate) {
        disabledDays.after = finalMaxDate;
      }
      return disabledDays;
    },
    [minDate, maxDate, format]
  );

  const inputValue = useMemo(() => {
    if (value) {
      if (Moment.isDate(value)) {
        return Moment(value).format(format);
      } else {
        return value;
      }
    }
    return '';
  }, [value, format]);

  const dateValue = useMemo(() => {
      const momentValue = Moment(value, format, true);
      if (momentValue.isValid()) {
        return momentValue.toDate();
      }
      return undefined;
    },
    [value, format]);

  const handleInputChange = useCallback((e) => {
      const newValue = e.target.value;
      const newValueMoment = Moment(newValue, format, true);
      onChange(newValueMoment.isValid() ? newValueMoment.toDate() : null, newValueMoment.isValid() ? newValueMoment.format(format) : newValue);
    },
    //eslint-disable-next-line
    [format]
  );

  const handleInputBlur = (e: any, opened: boolean, close: () => void) => {
    if (blurTimeout.current) {
      clearTimeout(blurTimeout.current);
    }
    blurTimeout.current = setTimeout(() => {
      if(close && opened) close();
    }, 200);
  }

  function handleSelectDay(date: Date, close: () => void) {
    onChange(date, date ? Moment(date).format(format) : '');
    inputRef.current?.blur();
    close();
  }

  return (
    <AWPopup
      { ...popupProps }
      renderTrigger={({ ref, onClick, className, opened, onClose }) => (
        <div className="form-control wrapper"
             ref={ref}>
          <Input
            placeholder={placeholder}
            { ...otherInputProps }
            className={className}
            innerRef={inputRef}
            onChange={handleInputChange}
            onFocus={(e) => {
              onClick(e as any);
            }}
            onBlur={(e) => handleInputBlur(e, opened, onClose)}
            value={inputValue}/>
        </div>
      )}>
      {({ close }) => (
        <div onMouseDown={(e) => e.preventDefault()}>
          {/* preventing mousedown default event inside popup content allow us to click inside popup body without loosing focus */}
          <DayPicker
            locale={customLocale ? customLocale : undefined}
            localeUtils={MomentLocaleUtils}
            showOutsideDays={true}
            selectedDays={dateValue}
            disabledDays={disabledDays}
            fixedWeeks={true}
            month={dateValue}
            onDayClick={(date) => handleSelectDay(date, close)}
          />
        </div>
      )}
    </AWPopup>
  )
}

export default AWDateInput