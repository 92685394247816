import { Field, FieldProps } from "formik";
import React, { Fragment, FunctionComponent } from "react";
import { FormFeedback, FormGroup, Input, Label, UncontrolledTooltip } from 'reactstrap';
import { AWFormFieldCommonProps, AWFormFieldErrorName } from './forms.types';
import { getErrorText, getText } from './forms.utils';
import AWIcon from '../icon';

interface AWFormFieldRadioProps extends AWFormFieldCommonProps {}

const AWFormFieldRadio: FunctionComponent<AWFormFieldRadioProps> = ({
                                                                 component : Component,
                                                                 componentProps = {},
                                                                 errorTexts,
                                                                 fieldTexts,
                                                                 hintPosition = 'bottom',
                                                                 wrapperProps = {},
                                                            ...fieldProps
                                                          }) => {
  const { value: componentValue, type, ...otherComponentProps } = componentProps;
  if(!componentValue){
    throw Error('AWFormFieldRadio should provide a value into \'componentProps\'');
  }
  // Texts
  const label = getText(fieldTexts, fieldProps.name, 'labels', componentValue);
  const hint = getText(fieldTexts, fieldProps.name, 'hints', componentValue);

  // Computing id
  if(!otherComponentProps.id){
    otherComponentProps.id = `${fieldProps.name}_${componentValue}`;
  }
  return (
    <Field
      {...fieldProps}>
      {(props: FieldProps) => {
        const { field, meta } = props;
        const { value, ...otherFormikFieldProps } = field;

        // Computing value props
        const valueProps: { [x: string]: any } = {};
        if(componentValue != null){
          valueProps.checked = componentValue === value;
          valueProps.value = componentValue;
        }
        return (
          <FormGroup { ...wrapperProps } check={true}>
            <Label for={ otherComponentProps.id }>
              {/* Special case for 'radio' and 'checkbox': we render input in <Label/> */}
              <Input type="radio"
                     disabled={fieldProps.disabled}
                     invalid={meta.error != null && meta.touched} // Used for displaying error
                     {...otherComponentProps} // Spreading component props, except 'options' and 'value' props
                     {...otherFormikFieldProps} // Spreading all Formik field props: onChange, onBlur, etc...
                     {...valueProps}/>
              { label }
              { hint && hintPosition === 'icon' &&
              <Fragment>
                  <AWIcon className="form-hint"
                          id={`${otherComponentProps.id}_hint`}
                          name="info_circle"/>
                  <UncontrolledTooltip aria-describedby={`${otherComponentProps.id}_hint`}
                                       placement="top"
                                       target={`${otherComponentProps.id}_hint`}
                                       trigger="hover">
                    {() => (
                      <div>{ hint }</div>
                    )}
                  </UncontrolledTooltip>
              </Fragment>
              }
            </Label>
            <FormFeedback>
              { getErrorText(errorTexts, meta.error as AWFormFieldErrorName) }
            </FormFeedback>
            { hint && hintPosition === 'bottom' &&
            <div className="indicator">
              { hint }
            </div>
            }
          </FormGroup>
        );
      }}
    </Field>
  );
}

export default AWFormFieldRadio;