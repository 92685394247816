import React, {FunctionComponent} from "react"

const AWIconBatteyLevel20: FunctionComponent<{ [prop: string]: any }> = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg"
            viewBox="0 56 192 80"
            enableBackground="new 0 56 192 80"
             { ...props }>
            <path
              d="M 61.09,122.00
              C 61.09,122.00 37.00,122.00 37.00,122.00
              37.00,122.00 37.00,70.00 37.00,70.00
              37.00,70.00 60.73,69.98 60.73,69.98
              62.59,67.52 64.36,65.56 66.10,64.00
              66.10,64.00 40.00,64.00 40.00,64.00
              35.00,64.00 31.00,68.00 31.00,73.00
              31.00,73.00 31.00,119.00 31.00,119.00
              31.00,124.00 35.00,128.00 40.00,128.00
              40.00,128.00 66.31,128.00 66.31,128.00
              64.29,126.03 62.91,124.49 61.09,122.00 Z
              M 125.95,128.00
              C 125.95,128.00 140.00,128.00 140.00,128.00
              145.00,128.00 149.00,124.00 149.00,119.00
              149.00,119.00 149.00,110.00 149.00,110.00
              152.00,110.00 155.00,110.00 155.00,110.00
              158.00,110.00 161.00,107.00 161.00,104.00
              161.00,104.00 161.00,88.00 161.00,88.00
              161.00,85.00 159.00,82.00 156.00,82.00
              156.00,82.00 152.00,82.00 149.00,82.00
              149.00,82.00 149.00,73.00 149.00,73.00
              149.00,68.00 145.00,64.00 140.00,64.00
              140.00,64.00 125.85,64.00 125.85,64.00
              128.37,66.33 129.56,67.77 131.22,70.00
              131.22,70.00 143.00,70.00 143.00,70.00
              143.00,70.00 143.00,122.00 143.00,122.00
              143.00,122.00 131.20,122.00 131.20,122.00
              130.03,123.73 127.44,126.68 125.95,128.00 Z" />
            <path
              d="M 40.00,119.00
              C 40.00,119.00 59.14,119.00 59.14,119.00
              50.17,103.83 50.83,86.04 58.75,73.00
              58.75,73.00 40.00,73.00 40.00,73.00
              40.00,73.00 40.00,119.00 40.00,119.00 Z" />
            <path
              d="M 133.17,119.00
              C 133.17,119.00 140.00,119.00 140.00,119.00
              140.00,119.00 140.00,73.00 140.00,73.00
              140.00,73.00 133.21,73.00 133.21,73.00
              141.11,85.39 141.09,106.22 133.17,119.00 Z" />
            <path
              d="M 136.00,96.00
              C 136.00,118.00 118.00,136.00 96.00,136.00
              74.00,136.00 56.00,118.00 56.00,96.00
              56.00,74.00 74.00,56.00 96.00,56.00
              118.00,56.00 136.00,74.00 136.00,96.00 Z
              M 91.00,104.00 101.00,104.00 104.00,66.00
              88.00,66.00 Z
              M 96.00,110.00
              C 91.60,110.00 88.00,112.60 88.00,118.00
              88.00,122.40 92.00,126.00 96.00,126.00
              100.40,126.00 104.00,122.40 104.00,118.00
              104.00,112.60 100.40,110.00 96.00,110.00 Z" />
        </svg>
    )
}

export default AWIconBatteyLevel20