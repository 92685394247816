import React, { FunctionComponent } from "react";

const AWIconRTMPCast: FunctionComponent<{
  [prop: string]: any;
}> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="20 62 472 386" {...props}>
      <path d="M143.6 220.9c0-11-3.2-19.5-9.6-25.4-6.4-6-15.5-8.9-27.3-8.9H69.3v113.8h23.5v-41.6h11.7l17.5 41.6h25.2v-1.1l-20.4-46.6c11.2-6.1 16.8-16.7 16.8-31.8zm-27 14.1c-2.3 3-5.9 4.6-10.5 4.6H92.7v-33.9h13.7c9.1 0 13.7 5.7 13.7 17.1 0 5.1-1.1 9.2-3.5 12.2zM231 186.5h-80.5v19.2h28.2v94.6h23.6v-94.6H231zM326.1 300.3h23.5V186.5H319l-21.9 82.3-21.9-82.3h-30.7v113.8H268v-30.8l-2.2-47.4 23.3 78.2H305l23.2-78.2-2.1 47.4zM407.6 260.3c12 0 21.3-3.2 28.1-9.7 6.8-6.5 10.1-15.3 10.1-26.4 0-11.1-3.4-20.2-10.3-27.2-6.8-7-16-10.5-27.5-10.5h-39.6v113.8H392v-40h15.6zM392 205.7h16.4c4.2.1 7.6 1.8 10.1 5.1 2.5 3.4 3.8 7.9 3.8 13.6 0 5.5-1.2 9.6-3.7 12.5-2.4 2.9-5.9 4.3-10.4 4.3H392v-35.5zM21.3 297.6V340c58.9 0 106.6 48.1 106.6 107h42.7c.1-82.4-66.9-149.3-149.3-149.4zM21.3 382.6v63.9h63.9c0-35.3-28.6-63.9-63.9-63.9z" />
      <path d="M447.8 63H64c-23.6 0-42.7 19.1-42.7 42.7V256H64V105.7h383.8v298.6H214.4V447H448c23.6 0 42.7-19.1 42.7-42.7V105.7c0-23.6-19.3-42.7-42.9-42.7z" />
    </svg>
  );
};

export default AWIconRTMPCast;
