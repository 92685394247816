import React, {FunctionComponent} from "react"

const AWIconDMNGPro: FunctionComponent<{ [prop: string]: any }> = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg"
             version="1.1"
             id="Layer_1"
             x="0px"
             y="0px"
             viewBox="0 0 654.46399 134.606"
             enableBackground="new 0 0 1024 1024"
             { ...props }>
            <g id="g926"
               transform="translate(-184.768,-444.697)">
                <path
                    d="m 269.172,575.303 h -61.648 c -10.359,0 -18.756,-8.397 -18.756,-18.757 v -89.092 c 0,-10.359 8.396,-18.756 18.756,-18.756 h 61.648 z"
                    id="path902"
                    style={{
                        fill: '#ffffff',
                        fillOpacity: 1
                    }}/>
                <path
                    d="m 754.826,448.697 h 61.65 c 10.357,0 18.756,8.397 18.756,18.757 v 89.092 c 0,10.359 -8.398,18.757 -18.756,18.757 h -61.65 z"
                    id="path904"
                    style={{
                        fill: '#ffffff',
                        fillOpacity: 1
                    }}/>
                <path
                    d="M 293,448.697 V 575.302 H 731 V 448.697 Z m 160.174,108.866 c 0,3.031 -2.458,5.489 -5.49,5.489 H 316.577 c -3.031,0 -5.489,-2.458 -5.489,-5.489 v -91.126 c 0,-3.032 2.458,-5.49 5.489,-5.49 h 131.106 c 3.032,0 5.49,2.458 5.49,5.49 v 91.126 z m 259.738,0 c 0,3.031 -2.457,5.489 -5.49,5.489 H 480.577 c -3.031,0 -5.489,-2.458 -5.489,-5.489 v -91.126 c 0,-3.032 2.458,-5.49 5.489,-5.49 h 226.845 c 3.033,0 5.49,2.458 5.49,5.49 z"
                    id="path906"
                    style={{
                        fill: '#ffffff',
                        fillOpacity: 1
                    }}/>
                <path
                    d="m 348.501,545.813 c 0,1.069 -0.871,1.94 -1.941,1.94 h -13.18 c -1.07,0 -1.941,-0.871 -1.941,-1.94 v -68.66 c 0,-1.069 0.871,-1.941 1.941,-1.941 h 13.18 c 1.07,0 1.941,0.872 1.941,1.941 z"
                    id="path908"
                    style={{
                        fill: '#ffffff',
                        fillOpacity: 1
                    }}/>
                <path
                    d="m 377.322,545.813 c 0,1.069 -0.871,1.94 -1.941,1.94 h -13.18 c -1.071,0 -1.941,-0.871 -1.941,-1.94 v -68.66 c 0,-1.069 0.87,-1.941 1.941,-1.941 h 13.18 c 1.07,0 1.941,0.872 1.941,1.941 z"
                    id="path910"
                    style={{
                        fill: '#ffffff',
                        fillOpacity: 1
                    }} />
                <path
                    d="m 405.908,545.813 c 0,1.069 -0.871,1.94 -1.941,1.94 h -13.18 c -1.07,0 -1.941,-0.871 -1.941,-1.94 v -68.66 c 0,-1.069 0.871,-1.941 1.941,-1.941 h 13.18 c 1.07,0 1.941,0.872 1.941,1.941 z"
                    id="path912"
                    style={{
                        fill: '#ffffff',
                        fillOpacity: 1
                    }}/>
                <path
                    d="m 436.823,545.687 c 0,1.071 -0.87,1.941 -1.941,1.941 h -9.649 c -1.07,0 -1.941,-0.87 -1.941,-1.941 V 534.39 c 0,-1.07 0.871,-1.941 1.941,-1.941 h 9.649 c 1.071,0 1.941,0.871 1.941,1.941 z"
                    id="path914"
                    style={{
                        fill: '#ffffff',
                        fillOpacity: 1
                    }}/>
                <path
                    d="m 436.823,517.63 c 0,1.071 -0.87,1.942 -1.941,1.942 h -9.649 c -1.07,0 -1.941,-0.871 -1.941,-1.942 v -11.296 c 0,-1.071 0.871,-1.941 1.941,-1.941 h 9.649 c 1.071,0 1.941,0.87 1.941,1.941 z"
                    id="path916"
                    style={{
                        fill: '#ffffff',
                        fillOpacity: 1
                    }}/>
                <path
                    d="m 436.823,489.339 c 0,1.07 -0.87,1.941 -1.941,1.941 h -9.649 c -1.07,0 -1.941,-0.871 -1.941,-1.941 v -11.297 c 0,-1.07 0.871,-1.941 1.941,-1.941 h 9.649 c 1.071,0 1.941,0.871 1.941,1.941 z"
                    id="path918"
                    style={{
                        fill: '#ffffff',
                        fillOpacity: 1
                    }}/>
                <path
                    d="m 687.881,474.295 c 5.322,0 9.654,4.332 9.654,9.655 0,5.323 -4.332,9.654 -9.654,9.654 -5.324,0 -9.654,-4.331 -9.654,-9.654 0,-5.323 4.33,-9.655 9.654,-9.655 z"
                    id="path920"
                    style={{
                        fill: '#ffffff',
                        fillOpacity: 1
                    }}/>
                <path
                    d="m 664.641,529.625 v 0.835 h 20.666 c 2.07,0 3.756,1.686 3.756,3.758 v 6.679 c 0,2.072 -1.686,3.758 -3.756,3.758 h -22.988 c -0.525,0 -1.771,1.032 -2.037,4.099 -0.031,0.313 -0.34,2.999 -2.033,2.999 -1.957,0 -1.957,-1.719 -1.957,-2.452 v -2.088 c 0,-0.489 -0.398,-0.887 -0.887,-0.887 -0.49,0 -0.887,0.397 -0.887,0.887 v 2.088 c -0.014,0.916 -0.381,2.452 -1.67,2.452 -1.543,0 -2.111,-1.003 -2.486,-2.51 -0.039,-0.156 -0.08,-0.331 -0.125,-0.52 -0.455,-1.969 -1.074,-4.068 -2.633,-4.068 h -21.789 c -2.072,0 -3.758,-1.686 -3.758,-3.758 v -6.679 c 0,-2.072 1.686,-3.758 3.758,-3.758 h 19.414 v -0.835 c 0,-3.798 3.09,-6.889 6.889,-6.889 h 5.635 c 3.798,0 6.888,3.091 6.888,6.889 z"
                    id="path922"
                    style={{
                        fill: '#ffffff',
                        fillOpacity: 1
                    }}/>
                <path
                    d="m 529.621,534.218 c 0,-2.072 1.686,-3.758 3.756,-3.758 h 19.414 v -0.835 c 0,-3.798 3.09,-6.889 6.889,-6.889 h 5.635 c 3.799,0 6.889,3.091 6.889,6.889 v 0.835 h 20.666 c 2.07,0 3.756,1.686 3.756,3.758 v 6.679 c 0,2.072 -1.686,3.758 -3.756,3.758 h -22.988 c -0.525,0 -1.771,1.032 -2.037,4.099 -0.029,0.313 -0.34,2.999 -2.033,2.999 -1.957,0 -1.957,-1.719 -1.957,-2.452 v -2.088 c 0,-0.489 -0.398,-0.887 -0.887,-0.887 -0.489,0 -0.887,0.397 -0.887,0.887 v 2.088 c -0.014,0.916 -0.379,2.452 -1.67,2.452 -1.543,0 -2.109,-1.003 -2.486,-2.51 -0.039,-0.156 -0.08,-0.331 -0.123,-0.52 -0.457,-1.969 -1.074,-4.068 -2.635,-4.068 h -21.789 c -2.07,0 -3.756,-1.686 -3.756,-3.758 v -6.679 z"
                    id="path924"
                    style={{
                        fill: '#ffffff',
                        fillOpacity: 1
                    }}/>
            </g>
        </svg>
    )
}

export default AWIconDMNGPro