import React, {FunctionComponent} from "react"

const AWIconMoJoProSmartphone: FunctionComponent<{ [prop: string]: any }> = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg"
             version="1.1"
             id="Layer_1"
             x="0px"
             y="0px"
             viewBox="0 0 122.723 236.099"
             enableBackground="new 0 0 1024 1024"
             { ...props}>
            <path
                style={{
                    fill: '#ffffff',
                    fillOpacity: 1
                }}
                id="path1308"
                d="M 118.723,220.852 V 15.248 C 118.723,9.036 113.688,4 107.475,4 H 15.246 C 9.035,4 4,9.036 4,15.248 v 205.604 c 0,6.212 5.035,11.247 11.246,11.247 h 92.229 c 6.213,0 11.248,-5.035 11.248,-11.247 z M 79.657,12.549 c 1.078,0 1.951,0.874 1.951,1.95 0,1.077 -0.873,1.95 -1.951,1.95 H 43.065 c -1.076,0 -1.949,-0.873 -1.949,-1.95 0,-1.077 0.873,-1.95 1.949,-1.95 z m -62.686,1.8 c 0,-2.153 1.746,-3.899 3.9,-3.899 2.152,0 3.898,1.746 3.898,3.899 0,2.153 -1.746,3.899 -3.898,3.899 -2.154,-0.001 -3.9,-1.746 -3.9,-3.899 z m 2.127,190.228 c -5.691,0 -10.307,-4.615 -10.307,-10.309 V 37.332 c 0,-5.692 4.615,-10.308 10.309,-10.308 h 84.523 c 5.693,0 10.309,4.615 10.309,10.308 v 156.937 c 0,5.692 -4.615,10.308 -10.309,10.308 z m 33.865,12.226 c 0,-4.638 3.76,-8.398 8.398,-8.398 4.638,0 8.398,3.761 8.398,8.398 0,4.639 -3.76,8.398 -8.398,8.398 -4.638,0 -8.398,-3.759 -8.398,-8.398 z" />
        </svg>
    )
}

export default AWIconMoJoProSmartphone