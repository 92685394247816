import React, {FunctionComponent} from "react"

const AWIconNetworkInterfaceWifiAp: FunctionComponent<{ [prop: string]: any }> = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 192 192"
            enableBackground="new 0 0 192 192"
             { ...props }>
            <path
                    d="M 57.66,69.84
                    C 59.24,71.31 60.03,73.28 60.03,75.75
                      60.03,81.94 55.62,85.03 46.81,85.03
                      46.81,85.03 43.09,85.03 43.09,85.03
                      43.09,85.03 43.09,101.16 43.09,101.16
                      43.09,101.16 46.81,101.16 46.81,101.16
                      52.07,101.16 55.86,101.97 58.17,103.59
                      60.49,105.22 61.66,107.68 61.66,110.97
                      61.66,114.25 60.82,116.78 59.16,118.56
                      57.50,120.34 55.02,121.22 51.73,121.22
                      48.45,121.22 45.88,120.23 44.03,118.27
                      42.18,116.30 41.21,113.61 41.12,110.20
                      41.12,110.20 21.88,110.20 21.88,110.20
                      21.88,119.26 24.70,126.06 30.34,130.62
                      35.99,135.19 43.27,137.47 52.20,137.47
                      61.14,137.47 68.14,135.29 73.20,130.92
                      78.27,126.55 80.80,120.57 80.80,112.98
                      80.80,102.93 76.47,96.25 67.81,92.92
                      67.81,92.92 67.81,92.45 67.81,92.45
                      70.44,91.37 73.00,89.21 75.52,85.97
                      78.04,82.72 79.30,78.50 79.30,73.31
                      79.30,66.74 76.89,61.33 72.09,57.08
                      67.30,52.83 60.57,50.70 51.91,50.70
                      43.25,50.70 36.31,52.93 31.09,57.38
                      25.88,61.81 23.27,68.09 23.27,76.22
                      23.27,76.22 42.52,76.22 42.52,76.22
                      43.05,70.49 46.00,67.62 51.34,67.62
                      53.97,67.62 56.07,68.37 57.66,69.84 Z" />
            <path
                    d="M 132.70,137.70
                    C 137.96,137.70 142.93,136.73 147.61,134.80
                      152.29,132.86 156.21,130.34 159.39,127.25
                      166.04,120.84 170.09,113.91 171.56,106.48
                      171.56,106.48 171.56,91.41 171.56,91.41
                      171.56,91.41 127.25,91.41 127.25,91.41
                      127.25,91.41 127.25,106.48 127.25,106.48
                      127.25,106.48 150.22,106.48 150.22,106.48
                      149.22,110.43 147.23,113.59 144.25,115.95
                      141.27,118.31 137.42,119.48 132.70,119.48
                      126.13,119.48 120.89,117.36 116.98,113.11
                      113.08,108.85 111.12,103.16 111.12,96.05
                      111.12,88.93 113.08,83.25 116.98,79.00
                      120.89,74.75 126.24,72.62 133.05,72.62
                      139.86,72.62 144.81,75.60 147.91,81.55
                      147.91,81.55 171.56,81.55 171.56,81.55
                      168.78,73.05 163.87,66.40 156.83,61.61
                      149.80,56.81 141.75,54.41 132.70,54.41
                      121.02,54.41 111.14,58.34 103.06,66.19
                      94.98,74.03 90.94,83.98 90.94,96.05
                      90.94,108.11 94.98,118.07 103.06,125.92
                      111.14,133.78 121.02,137.70 132.70,137.70 Z" />
        </svg>
    )
}

export default AWIconNetworkInterfaceWifiAp