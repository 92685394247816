import { Field, FieldProps } from "formik";
import React, { Fragment, FunctionComponent } from "react";
import { FormFeedback, FormGroup, Label, UncontrolledTooltip } from 'reactstrap';
import { AWFormFieldCommonProps, AWFormFieldErrorName } from './forms.types';
import { getErrorText, getText } from './forms.utils';
import AWIcon from '../icon';
import AWSwitch from '../switch';

interface AWFormFieldSwitchProps extends AWFormFieldCommonProps {
  onValueChange?: (value: boolean) => void;
}

const AWFormFieldSwitch: FunctionComponent<AWFormFieldSwitchProps> = ({
                                                                            componentProps = {},
                                                                            errorTexts,
                                                                            fieldTexts,
                                                                            hintPosition= 'bottom',
                                                                            onValueChange,
                                                                            wrapperProps = {},
                                                                            ...fieldProps
                                                                          }) => {
  const { value: componentValue, type, ...otherComponentProps } = componentProps;

  // Texts
  let labelLeft = getText(fieldTexts, fieldProps.name, 'label');
  let labelRight: string | null = null;
  if(!labelLeft){
    labelLeft = getText(fieldTexts, fieldProps.name, 'labels', 'false');
    labelRight = getText(fieldTexts, fieldProps.name, 'labels', 'true');
  }
  let hintLeft = getText(fieldTexts, fieldProps.name, 'hint');
  let hintRight: string | null = null;
  if(!hintLeft){
    hintLeft = getText(fieldTexts, fieldProps.name, 'hints', 'false');
    hintRight = getText(fieldTexts, fieldProps.name, 'hints', 'true');
  }

  // Computing id
  if(!otherComponentProps.id){
    otherComponentProps.id = fieldProps.name;
  }

  const handleChange = (newValue: boolean, setFieldValue: any) => {
    if(onValueChange) onValueChange(newValue);
    setFieldValue(fieldProps.name, newValue);
  };

  return (
    <Field
      {...fieldProps}>
      {(props: FieldProps) => {
        const { field, form, meta } = props;
        const { value, ...otherFormikFieldProps } = field;

        // Computing value props
        const valueProps: { [x: string]: any } = { value };
        // Checkbox - we use 'checked' prop, not 'value'
        if(typeof value === 'boolean'){
          valueProps.defaultChecked = value;
        }

        return (
          <FormGroup { ...wrapperProps }>
            <div className="form-switch">
              <Label>
                { labelLeft }
                { hintLeft && hintPosition === 'icon' &&
                <Fragment>
                    <AWIcon className="form-hint"
                            id={`${otherComponentProps.id}_hint`}
                            name="info_circle"/>
                    <UncontrolledTooltip aria-describedby={`${otherComponentProps.id}_hint`}
                                         placement="top"
                                         target={`${otherComponentProps.id}_hint`}
                                         trigger="hover">
                      {() => (
                        <div>{ hintLeft }</div>
                      )}
                    </UncontrolledTooltip>
                </Fragment>
                }
              </Label>
              <AWSwitch disabled={fieldProps.disabled}
                        invalid={meta.error != null && meta.touched} // Used for displaying error
                        {...otherComponentProps } // Spreading component props, except 'options' and 'value' props
                        {...otherFormikFieldProps } // Spreading all Formik field props: onChange, onBlur, etc...
                        {...valueProps }
                        onChange={(e) => handleChange(e.target.checked, form.setFieldValue)}/>
              {labelRight &&
              <Label>
                {labelRight}
                {hintRight && hintPosition === 'icon' &&
                <Fragment>
                    <AWIcon className="form-hint"
                            id={`${otherComponentProps.id}_hint`}
                            name="info_circle"/>
                    <UncontrolledTooltip aria-describedby={`${otherComponentProps.id}_hint`}
                                         placement="top"
                                         target={`${otherComponentProps.id}_hint`}
                                         trigger="hover">
                      {() => (
                        <div>{hintRight}</div>
                      )}
                    </UncontrolledTooltip>
                </Fragment>
                }
              </Label>
              }
            </div>
            <FormFeedback>
              { getErrorText(errorTexts, meta.error as AWFormFieldErrorName) }
            </FormFeedback>
            { hintLeft && hintPosition === 'bottom' &&
            <div className="indicator">
              { hintLeft }
            </div>
            }
          </FormGroup>
        );
      }}
    </Field>
  );
}

export default AWFormFieldSwitch;