import React, {FunctionComponent} from "react"

const AWIconNetworkInterfaceWifiAp: FunctionComponent<{ [prop: string]: any }> = (props) => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 192 192"
        enableBackground="new 0 0 192 192"
        { ...props }>
      <path
        d="M 112.00,184.00 80.00,184.00 96.00,110.00 Z" />
      <circle
        cx="96.00" cy="72.00" r="11.50" />
      <path
        d="M 70.54,46.55
           C 64.03,53.06 60.00,62.06 60.00,72.00
             60.00,81.91 64.05,90.97 70.52,97.48
             70.52,97.48 74.08,93.92 74.08,93.92
             68.47,88.31 65.00,80.56 65.00,72.00
             65.00,63.48 68.51,55.67 74.08,50.06
             74.08,50.06 70.54,46.55 70.54,46.55 Z
           M 117.92,50.06
           C 123.61,55.68 127.00,63.37 127.00,72.00
             127.00,80.50 123.48,88.32 117.93,93.92
             117.93,93.92 121.44,97.43 121.44,97.43
             127.99,90.92 132.00,81.97 132.00,72.00
             132.00,62.09 127.92,53.04 121.44,46.53
             121.44,46.53 117.92,50.06 117.92,50.06 Z" />
      <path
        d="M 131.37,107.35
           C 140.46,98.29 146.00,85.85 146.00,72.00
             146.00,58.15 140.46,45.69 131.36,36.63
             131.36,36.63 134.88,33.09 134.88,33.09
             144.92,43.06 151.00,56.73 151.00,72.00
             151.00,87.23 144.90,100.92 134.90,110.88
             134.90,110.88 131.37,107.35 131.37,107.35 Z
           M 57.11,110.90
           C 47.16,100.95 41.00,87.19 41.00,72.00
             41.00,56.85 47.19,43.05 57.09,33.10
             57.09,33.10 60.64,36.65 60.64,36.65
             51.66,45.69 46.00,58.25 46.00,72.00
             46.00,85.83 51.55,98.32 60.64,107.38
             60.64,107.38 57.11,110.90 57.11,110.90 Z" />
      <path
        d="M 148.33,124.32
           C 161.72,110.93 170.00,92.43 170.00,72.00
             170.00,51.56 161.71,33.06 148.32,19.67
             148.32,19.67 144.78,23.20 144.78,23.20
             157.33,35.70 165.00,52.90 165.00,72.00
             165.00,91.06 157.28,108.31 144.79,120.79
             144.79,120.79 148.33,124.32 148.33,124.32 Z
           M 47.22,120.80
           C 34.70,108.31 27.00,91.08 27.00,72.00
             27.00,52.92 34.69,35.69 47.20,23.20
             47.20,23.20 43.67,19.67 43.67,19.67
             30.30,33.06 22.00,51.59 22.00,72.00
             22.00,92.46 30.26,110.94 43.68,124.33
             43.68,124.33 47.22,120.80 47.22,120.80 Z" />
  </svg>
    )
}

export default AWIconNetworkInterfaceWifiAp