import React, { FunctionComponent } from "react";

const AWIconIpInput: FunctionComponent<{ [prop: string]: any }> = (props) => {
  return (
    <svg id="Calque_1" data-name="Calque 1" viewBox="0 0 16 12" {...props}>
      <defs>
        <style>
          {
            ".cls-1{font-size:9px;font-family:'Roboto Condensed', sans-serif;font-weight:700;}"
          }
        </style>
      </defs>
      <path
        d="M8.35,9.35a.5.5,0,0,0-.7,0h0l-3.8,3.8a.5.5,0,0,0,0,.7.49.49,0,0,0,.36.15h7.58a.5.5,0,0,0,.36-.85Z"
        transform="translate(0 -2)"
      />
      <path
        d="M14.5,2H1.5A1.5,1.5,0,0,0,0,3.5v7A1.5,1.5,0,0,0,1.5,12H3.59l1-1H1.5a.5.5,0,0,1-.5-.5v-7A.5.5,0,0,1,1.5,3h13a.5.5,0,0,1,.5.5v7a.5.5,0,0,1-.5.5H11.41l1,1H14.5A1.5,1.5,0,0,0,16,10.5v-7A1.5,1.5,0,0,0,14.5,2Z"
        transform="translate(0 -2)"
      />
      <text className="cls-1" transform="translate(4.31 7.98)">
        {"IP"}
      </text>
    </svg>
  );
};

export default AWIconIpInput;
