import React, { FunctionComponent } from "react";
import AWIconSDI from "./svg/sdi";
import AWIconDMNGPro from "./svg/fu/dmng-pro";
import AWIconAir from "./svg/fu/air";
import AWIconMoJoProSmartphone from "./svg/fu/mojopro-smartphone";
import AWIconMoJoProLaptop from "./svg/fu/mojopro-laptop";
import AWIconEncoder from "./svg/encoder";
import AWIconEncoderActive from "./svg/encoder-active";
import AWIconEncoderHEVC from "./svg/encoder-hevc";
import AWIconGuestInterview from "./svg/guest-interview";
import AWIconHE4000 from "./svg/fu/he4000";
import AWIconRackSeries from "./svg/fu/rack-series";
import AWIconUHD from "./svg/uhd";
import AWIconStreamhub from "./svg/fu/streamhub";
import AWIconVideoReturn from "./svg/video-return";
import AWIconVideoReturnProfile from "./svg/video-return-profile";
import AWIconInternetAccessPoint from "./svg/internet-access-point";
import AWIconNetworkInterfaceCellular from "./svg/network-interface-cellular";
import AWIconNetworkInterfaceWifiAp from "./svg/network-interface-wifi-ap";
import AWIconNetworkInterfaceBgan from "./svg/network-interface-bgan";
import AWIconNetworkInterface3G from "./svg/network-interface-3G";
import AWIconNetworkInterface4G from "./svg/network-interface-4G";
import AWIconNetworkInterface5G from "./svg/network-interface-5G";
import AWIconToggleOff from "./svg/toggle-off";
import AWIconToggleOn from "./svg/toggle-on";
import AWIconBatteyLevel0 from "./svg/battery-level-0";
import AWIconBatteyLevel20 from "./svg/battery-level-20";
import AWIconBatteyLevel40 from "./svg/battery-level-40";
import AWIconBatteyLevel60 from "./svg/battery-level-60";
import AWIconBatteyLevel80 from "./svg/battery-level-80";
import AWIconBatteyLevel100 from "./svg/battery-level-100";
import AWIconBatteyLevelAdapter from "./svg/battery-level-adapter";
import AWIconBatteyLevelCharge from "./svg/battery-level-charge";
import AWIconBatteyLevelWarning from "./svg/battery-level-warning";
import AWIconVideoReturnOff from "./svg/video-return-off";
import AWIconEncoder2 from "./svg/encoder-2";
import AWIconIpInput from "./svg/ip-input";
import AWIconFirmwareUpdate from "./svg/firmware-update";
import AWIconHLSCast from "./svg/hls-cast";
import AWIconHLSInput from "./svg/hls-input";
import AWIconReboot from "./svg/reboot";
import AWIconRTMPCast from "./svg/rtmp-cast";
import AWIconRTMPInput from "./svg/rtmp-input";
import AWIconRTSPCast from "./svg/rtsp-cast";
import AWIconRTSPInput from "./svg/rtsp-input";
import AWIconSoftwareUpdate from "./svg/software-update";
import AWIconSRTInput from "./svg/srt-input";
import AWIconSRTCast from "./svg/srt-cast";
import AWIconSSTCameraVideo from "./svg/sst-camera-video";
import AWIconSSTCast from "./svg/sst-cast";
import AWIconTSInput from "./svg/ts-input";
import AWIconTSCast from "./svg/ts-cast";
import AWIconVideoReturnProfileOff from "./svg/video-return-profile-off";
import AWIconWebRTCInput from "./svg/webrtc-input";
import AWIconNDICast from "./svg/ndi-cast";
import AWIconNDIInput from "./svg/ndi-input";
import AWIconDataBridge from "./svg/data-bridge";
import AWIconRemoteControl from "./svg/remote-control";

const FontAwesomeMap: { [x: string]: string | undefined } = {
  account: "fa fa-user-circle",
  activities: "fa fa-clipboard-list",
  add: "fa fa-plus",
  add_square: "fa fa-plus-square",
  arrow_down: "fa fa-arrow-down",
  arrow_left: "fa fa-arrow-left",
  arrow_right: "fa fa-arrow-right",
  arrow_up: "fa fa-arrow-up",
  arrow_alt: "fas fa-arrows-alt",
  block: "fa fa-ban",
  brand_youtube: "fab fa-youtube",
  brand_facebook: "fab fa-facebook-f",
  brand_twitch: "fab fa-twitch",
  broadcast: "fa fa-broadcast-tower",
  booking: "fas fa-star",
  burger_menu: "fa fa-bars",
  caret_down: "fa fa-caret-down",
  caret_left: "fa fa-caret-left",
  caret_right: "fa fa-caret-right",
  caret_up: "fa fa-caret-up",
  cancel: "fa fa-times",
  chart_area: "fa fa-chart-area",
  chart_bar: "fa fa-chart-bar",
  chart_line: "fa fa-chart-line",
  chart_pie: "fa fa-chart-pie",
  circle: "far fa-circle",
  check: "fa fa-check",
  check_circle: "fa fa-check-circle",
  check_square: "fa fa-check-square",
  chevron_right: "fa fa-chevron-right",
  chevron_left: "fa fa-chevron-left",
  chevron_up: "fa fa-chevron-up",
  chevron_down: "fa fa-chevron-down",
  clients: "fa fa-handshake",
  clipboard: "fa fa-clipboard",
  close: "fa fa-times",
  close_circle: "fa fa-times-circle",
  cloud: "fa fa-cloud",
  columns: "fa fa-columns",
  connect: "fa fa-plug",
  copy: "fa fa-copy",
  database: "fa fa-database",
  delete: "fa fa-trash",
  documentation: "fa fa-book",
  download: "fa fa-download",
  download_cloud: "fa fa-cloud-download-alt",
  edit: "fa fa-pen",
  eject: "fa fa-eject",
  emit_call: "fa fa-phone fa-rotate-90",
  error: "fa fa-exclamation-circle",
  exchange: "fa fa-exchange-alt",
  exchange_vertical: "fa fa-exchange-alt fa-rotate-90",
  expand: "fa fa-expand-alt",
  faq: "fa fa-question-circle",
  file: "fa fa-file",
  file_archive: "fa fa-file-archive",
  file_audio: "fa fa-file-audio",
  file_download: "fa fa-file-download",
  file_export: "fa fa-file-export",
  file_image: "fa fa-file-image",
  file_import: "fa fa-file-import",
  file_pdf: "fa fa-file-pdf",
  file_upload: "fa fa-file-upload",
  file_video: "fa fa-file-video",
  filter: "fa fa-filter",
  folder: "fa fa-folder",
  folder_add: "fa fa-folder-plus",
  folder_open: "fa fa-folder-open",
  folder_remove: "fa fa-folder-minus",
  forward: "fa fa-share",
  grid: "fa fa-th",
  hang_up: "fa fa-phone fa-rotate-180",
  headset: "fa fa-headset",
  history: "fa fa-history",
  hw_cpu_usage: "fa fa-microchip",
  hw_disk_usage: "fa fa-hdd",
  hw_memory_usage: "fa fa-memory",
  icon_menu: "fas fa-ellipsis-h",
  icon_menu_alt: "fas fa-ellipsis-v",
  info: "fa fa-info",
  info_circle: "fa fa-info-circle",
  invite: "fa fa-envelope",
  invite_send: "fa fa-paper-plane",
  language: "fa fa-globe",
  layout_grid: "fa fa-th",
  layout_inline: "fa fa-th-list",
  license: "fa fa-key",
  link: "fa fa-link",
  lock: "fa fa-lock",
  lock_open: "fa fa-lock-open",
  log_out: "fa fa-sign-out-alt",
  microphone: "fa fa-microphone",
  microphone_off: "fa fa-microphone-slash",
  multiview: "fa fa-th-large",
  music: "fa fa-music",
  network: "fa fa-network-wired",
  network_interface_ethernet: "fa fa-ethernet",
  network_interface_wifi: "fa fa-wifi",
  network_interface_sim: "fa fa-sim-card",
  options: "fa fa-sliders-h",
  pause: "fa fa-pause",
  pause_circle: "fa fa-pause-circle",
  play: "fa fa-play",
  player_backward: "fa fa-fast-backward",
  player_forward: "fa fa-fast-forward",
  player_step_backward: "fa fa-step-backward",
  player_step_forward: "fa fa-step-forward",
  playback: "fa fa-play-circle",
  power_up: "fa fa-power-off",
  power_down: "fa fa-power-off",
  preview: "fa fa-eye",
  products: "fa fa-box",
  products_alt: "fa fa-box-open",
  record: "fa fa-circle",
  refresh: "fa fa-sync",
  remove: "fa fa-minus",
  remove_square: "fa fa-minus-square",
  restore: "fa fa-history",
  screen_cast: "fab fa-chromecast",
  sd_card: "fas fa-sd-card",
  search: "fa fa-search",
  select_multiple: "fa fa-list",
  server: "fa fa-server",
  setting: "fa fa-cog",
  settings: "fa fa-cogs",
  shrink: "fa fa-compress-alt",
  sort: "fa fa-sort",
  sort_asc: "fa fa-sort-up",
  sort_desc: "fa fa-sort-down",
  sort_alpha_asc: "fas fa-sort-alpha-down",
  sort_alpha_desc: "fas fa-sort-alpha-down-alt",
  stop: "fa fa-stop",
  tools: "fa fa-wrench",
  theme: "fa fa-adjust",
  tv_screen: "fa fa-tv",
  upload: "fa fa-upload",
  upload_cloud: "fa fa-cloud-upload-alt",
  update: "fa fa-clock",
  usb: "fab fa-usb",
  user: "fa fa-user",
  user_accept: "fa fa-user-check",
  user_add: "fa fa-user-plus",
  user_lock: "fa fa-user-lock",
  user_off: "fa fa-user-slash",
  user_refuse: "fa fa-user-times",
  user_remove: "fa fa-user-minus",
  user_pending: "fa fa-user-clock",
  user_settings: "fa fa-user-cog",
  user_shield: "fa fa-user-shield",
  user_team: "fa fa-user-friends",
  users: "fa fa-users",
  users_settings: "fa fa-users-cog",
  video_camera: "fa fa-video",
  video_camera_off: "fa fa-video-slash",
  visibility: "fa fa-eye",
  visibility_off: "fa fa-eye-slash",
  volume_down: "fa fa-volume-down",
  volume_mute: "fa fa-volume-mute",
  volume_off: "fa fa-volume-off",
  volume_on: "fa fa-volume-up",
  volume_up: "fa fa-volume-up",
  warning: "fa fa-exclamation-triangle",
};

const BootstrapMap: { [x: string]: string | undefined } = {
  account: "bi bi-person-circle",
  add: "bi bi-plus-lg",
  add_square: "bi bi-plus-square",
  arrow_down: "bi bi-arrow-down",
  arrow_left: "bi bi-arrow-left",
  arrow_right: "bi bi-arrow-right",
  arrow_up: "bi bi-arrow-up",
  arrow_alt: "bi bi-arrows-move",
  cancel: "bi bi-x-lg",
  caret_down: "bi bi-caret-down",
  caret_left: "bi bi-caret-left",
  caret_right: "bi bi-caret-right",
  caret_up: "bi bi-caret-up",
  chart_line: "bi bi-graph-up",
  chevron_double_right: "bi bi-chevron-double-right",
  chevron_double_left: "bi bi-chevron-double-left",
  chevron_double_down: "bi bi-chevron-double-down",
  chevron_double_up: "bi bi-chevron-double-up",
  chevron_right: "bi bi-chevron-right",
  chevron_left: "bi bi-chevron-left",
  chevron_down: "bi bi-chevron-down",
  chevron_up: "bi bi-chevron-up",
  close: "bi bi-x-lg",
  cloud: "bi bi-cloud",
  connect: "bi bi-plug",
  delete: "bi bi-trash2",
  device_input: "bi bi-camera-video",
  disable: "bi bi-x-circle",
  eject: "bi bi-eject",
  edit: "bi bi-pencil",
  enable: "bi bi-check-circle",
  expand: "bi bi-arrows-angle-expand",
  file_input: "bi bi-file-play",
  filter: "bi bi-filter",
  folder: "bi bi-folder",
  folder_add: "bi bi-folder-plus",
  folder_open: "bi bi-folder2-open",
  folder_remove: "bi bi-folder-minus",
  grip_horizontal: "bi bi-grip-horizontal",
  grip_vertical: "bi bi-grip-vertical",
  group: "bi bi-collection",
  headset: "bi bi-headset",
  history: "bi bi-clock-history",
  hw_cpu_usage: "bi bi-cpu",
  hw_disk_usage: "bi bi-hdd",
  icon_menu: "bi bi-three-dots",
  icon_menu_alt: "bi bi-three-dots-vertical",
  info: "bi bi-info",
  info_circle: "bi bi-info-circle",
  input: "bi bi-cast",
  invite: "bi bi-envelope",
  language: "bi bi-globe",
  lock: "bi bi-lock",
  log_out: "bi bi-box-arrow-right",
  layout_grid: "bi bi-grid",
  layout_inline: "bi bi-view-stacked",
  link: "bi bi-link-45deg",
  microphone: "bi bi-mic",
  microphone_off: "bi bi-mic-mute",
  network: "bi bi-diagram-2-fill",
  node: "bi bi-node-plus",
  options: "bi bi-sliders",
  pause: "bi bi-pause",
  play: "bi bi-play",
  player_step_backward: "bi bi-skip-start",
  player_step_forward: "bi bi-skip-end",
  player_backward: "bi bi-skip-backward",
  player_forward: "bi bi-skip-forward",
  power_up: "bi bi-power",
  power_down: "bi bi-power",
  preview: "bi bi-eye",
  receivers: "bi bi-hdd-stack",
  record: "bi bi-record-circle",
  record_fill: "bi bi-record-fill",
  remove: "bi bi-dash-lg",
  repeat: "bi bi-arrow-repeat",
  remove_square: "bi bi-dash-square",
  route: "bi bi-signpost-split",
  search: "bi bi-search",
  setting: "bi bi-gear",
  shrink: "bi bi-arrows-angle-contract",
  sort_asc: "bi bi-sort-up",
  sort_desc: "bi bi-sort-down",
  stop: "bi bi-stop",
  theme: "bi bi-lightbulb",
  users: "bi bi-people",
  user: "bi bi-person",
  user_accept: "bi bi-person-check",
  user_add: "bi bi-person-plus",
  user_lock: "",
  user_off: "",
  user_pending: "",
  user_refuse: "bi bi-person-x",
  user_remove: "bi bi-person-dash",
  user_settings: "",
  user_shield: "",
  user_team: "bi bi-people",
  video_camera: "bi bi-camera-video",
  video_camera_off: "bi bi-camera-video-off",
  visibility: "bi bi-eye",
  visibility_off: "bi bi-eye-slash",
  volume_down: "bi bi-volume-down",
  volume_mute: "bi bi-volume-mute",
  volume_off: "bi bi-volume-off",
  volume_on: "bi bi-volume-up",
  volume_up: "bi bi-volume-up",
  warning: "bi bi-exclamation-triangle",
};

export type AWIconName =
  | "account"
  | "activities"
  | "add"
  | "add_square"
  | "arrow_alt"
  | "arrow_down"
  | "arrow_left"
  | "arrow_right"
  | "arrow_up"
  | "block"
  | "brand_youtube"
  | "brand_facebook"
  | "brand_twitch"
  | "broadcast"
  | "booking"
  | "burger_menu"
  | "caret_down"
  | "caret_left"
  | "caret_right"
  | "caret_up"
  | "cancel"
  | "circle"
  | "chart_area"
  | "chart_bar"
  | "chart_line"
  | "chart_pie"
  | "check"
  | "check_circle"
  | "check_square"
  | "chevron_double_right"
  | "chevron_double_left"
  | "chevron_double_down"
  | "chevron_double_up"
  | "chevron_right"
  | "chevron_left"
  | "chevron_up"
  | "chevron_down"
  | "clients"
  | "clipboard"
  | "close"
  | "close_circle"
  | "cloud"
  | "columns"
  | "connect"
  | "copy"
  | "database"
  | "delete"
  | "device_input"
  | "disable"
  | "documentation"
  | "download"
  | "download_cloud"
  | "edit"
  | "eject"
  | "emit_call"
  | "enable"
  | "encoder"
  | "encoder_2"
  | "encoder_active"
  | "encoder_hevc"
  | "error"
  | "exchange"
  | "exchange_vertical"
  | "expand"
  | "faq"
  | "file"
  | "file_audio"
  | "file_archive"
  | "file_download"
  | "file_export"
  | "file_image"
  | "file_import"
  | "file_input"
  | "file_pdf"
  | "file_upload"
  | "file_video"
  | "filter"
  | "firmware_update"
  | "folder"
  | "folder_add"
  | "folder_open"
  | "folder_remove"
  | "forward"
  | "fu_pro"
  | "fu_air"
  | "fu_mojopro_laptop"
  | "fu_mojopro_smartphone"
  | "fu_he4000"
  | "fu_rack_series"
  | "grid"
  | "grip_horizontal"
  | "grip_vertical"
  | "group"
  | "guest_interview"
  | "hang_up"
  | "headset"
  | "history"
  | "hls_cast"
  | "hls_input"
  | "hw_cpu_usage"
  | "hw_disk_usage"
  | "hw_memory_usage"
  | "icon_menu"
  | "icon_menu_alt"
  | "info"
  | "info_circle"
  | "input"
  | "internet_access_point"
  | "invite"
  | "invite_send"
  | "ip_input"
  | "layout_grid"
  | "layout_inline"
  | "language"
  | "license"
  | "link"
  | "lock"
  | "lock_open"
  | "log_out"
  | "microphone"
  | "microphone_off"
  | "multiview"
  | "music"
  | "network"
  | "network_interface_cellular"
  | "network_interface_ethernet"
  | "network_interface_wifi"
  | "network_interface_sim"
  | "node"
  | "options"
  | "pause"
  | "pause_circle"
  | "play"
  | "player_backward"
  | "player_forward"
  | "player_step_backward"
  | "player_step_forward"
  | "playback"
  | "power_up"
  | "power_down"
  | "preview"
  | "products"
  | "products_alt"
  | "reboot"
  | "receivers"
  | "record"
  | "record_fill"
  | "refresh"
  | "remove"
  | "remove_square"
  | "repeat"
  | "restore"
  | "route"
  | "rtmp_cast"
  | "rtmp_input"
  | "rtsp_cast"
  | "rtsp_input"
  | "screen_cast"
  | "sd_card"
  | "sdi"
  | "search"
  | "select_multiple"
  | "server"
  | "setting"
  | "settings"
  | "shrink"
  | "software_update"
  | "sort"
  | "sort_asc"
  | "sort_desc"
  | "sort_alpha_asc"
  | "sort_alpha_desc"
  | "sst_camera_video"
  | "sst_cast"
  | "stop"
  | "streamhub_source"
  | "srt_cast"
  | "srt_input"
  | "tools"
  | "theme"
  | "tv_screen"
  | "ts_cast"
  | "ts_input"
  | "uhd"
  | "upload"
  | "upload_cloud"
  | "update"
  | "user"
  | "user_accept"
  | "user_add"
  | "user_lock"
  | "user_off"
  | "user_pending"
  | "user_refuse"
  | "user_remove"
  | "user_settings"
  | "user_shield"
  | "user_team"
  | "users"
  | "users_settings"
  | "video_camera"
  | "video_camera_off"
  | "video_return"
  | "video_return_off"
  | "video_return_profile"
  | "video_return_profile_off"
  | "visibility"
  | "visibility_off"
  | "volume_down"
  | "volume_mute"
  | "volume_off"
  | "volume_on"
  | "volume_up"
  | "warning"
  | "webrtc_input";

export interface AWIconProps {
  color?: "primary" | "secondary" | "info" | "success" | "warning" | "danger";
  fixedWidth?: boolean;
  name?: AWIconName | string;
  rotating?: boolean;
  [x: string]: any;
}

const AWIcon: FunctionComponent<AWIconProps> = ({
  name,
  rotating,
  fixedWidth,
  className,
  color,
  ...otherProps
}) => {
  const awClass = name ? `aw-${name}` : null; // We use this class to locate icons in cypress tests
  const baseClassName = `aw-icon${awClass ? " " + awClass : ""}${
    color ? " " + color : ""
  }${rotating ? " fa-spin" : ""}${fixedWidth ? " fa-fw" : ""}${
    className ? " " + className : ""
  }`;
  switch (name) {
    case "battery_level_0":
      return <AWIconBatteyLevel0 className={baseClassName} {...otherProps} />;
    case "battery_level_20":
      return <AWIconBatteyLevel20 className={baseClassName} {...otherProps} />;
    case "battery_level_40":
      return <AWIconBatteyLevel40 className={baseClassName} {...otherProps} />;
    case "battery_level_60":
      return <AWIconBatteyLevel60 className={baseClassName} {...otherProps} />;
    case "battery_level_80":
      return <AWIconBatteyLevel80 className={baseClassName} {...otherProps} />;
    case "battery_level_100":
      return <AWIconBatteyLevel100 className={baseClassName} {...otherProps} />;
    case "battery_level_adapter":
      return (
        <AWIconBatteyLevelAdapter className={baseClassName} {...otherProps} />
      );
    case "battery_level_charge":
      return (
        <AWIconBatteyLevelCharge className={baseClassName} {...otherProps} />
      );
    case "battery_level_warning":
      return (
        <AWIconBatteyLevelWarning className={baseClassName} {...otherProps} />
      );
    case "data_bridge":
        return <AWIconDataBridge className={baseClassName} {...otherProps} />;
    case "encoder":
      return <AWIconEncoder className={baseClassName} {...otherProps} />;
    case "encoder_2":
      return <AWIconEncoder2 className={baseClassName} {...otherProps} />;
    case "encoder_active":
      return <AWIconEncoderActive className={baseClassName} {...otherProps} />;
    case "encoder_hevc":
      return <AWIconEncoderHEVC className={baseClassName} {...otherProps} />;
    case "firmware_update":
      return <AWIconFirmwareUpdate className={baseClassName} {...otherProps} />;
    case "fu_pro":
      return <AWIconDMNGPro className={baseClassName} {...otherProps} />;
    case "fu_air":
      return <AWIconAir className={baseClassName} {...otherProps} />;
    case "fu_he4000":
      return <AWIconHE4000 className={baseClassName} {...otherProps} />;
    case "fu_rack_series":
      return <AWIconRackSeries className={baseClassName} {...otherProps} />;
    case "fu_mojopro_smartphone":
      return (
        <AWIconMoJoProSmartphone className={baseClassName} {...otherProps} />
      );
    case "fu_mojopro_laptop":
      return <AWIconMoJoProLaptop className={baseClassName} {...otherProps} />;
    case "guest_interview":
      return <AWIconGuestInterview className={baseClassName} {...otherProps} />;
    case "hls_cast":
      return <AWIconHLSCast className={baseClassName} {...otherProps} />;
    case "hls_input":
      return <AWIconHLSInput className={baseClassName} {...otherProps} />;
    case "internet_access_point":
      return (
        <AWIconInternetAccessPoint className={baseClassName} {...otherProps} />
      );
    case "ip_input":
      return <AWIconIpInput className={baseClassName} {...otherProps} />;
    case "ndi_cast":
      return <AWIconNDICast className={baseClassName} {...otherProps} />;
    case "ndi_input":
      return <AWIconNDIInput className={baseClassName} {...otherProps} />;
    case "network_interface_cellular":
      return (
        <AWIconNetworkInterfaceCellular
          className={baseClassName}
          {...otherProps}
        />
      );
    case "network_interface_wifi_ap":
      return (
        <AWIconNetworkInterfaceWifiAp
          className={baseClassName}
          {...otherProps}
        />
      );
    case "network_interface_bgan":
        return (
          <AWIconNetworkInterfaceBgan className={baseClassName} {...otherProps} />
        );
    case "network_interface_3g":
      return (
        <AWIconNetworkInterface3G className={baseClassName} {...otherProps} />
      );
    case "network_interface_4g":
      return (
        <AWIconNetworkInterface4G className={baseClassName} {...otherProps} />
      );
    case "network_interface_5g":
      return (
        <AWIconNetworkInterface5G className={baseClassName} {...otherProps} />
      );
    case "reboot":
      return <AWIconReboot className={baseClassName} {...otherProps} />;
    case "remote_control":
        return <AWIconRemoteControl className={baseClassName} {...otherProps} />;
    case "rtmp_cast":
      return <AWIconRTMPCast className={baseClassName} {...otherProps} />;
    case "rtmp_input":
      return <AWIconRTMPInput className={baseClassName} {...otherProps} />;
    case "rtsp_cast":
      return <AWIconRTSPCast className={baseClassName} {...otherProps} />;
    case "rtsp_input":
      return <AWIconRTSPInput className={baseClassName} {...otherProps} />;
    case "sdi":
      return <AWIconSDI className={baseClassName} {...otherProps} />;
    case "software_update":
      return <AWIconSoftwareUpdate className={baseClassName} {...otherProps} />;
    case "sst_camera_video":
      return <AWIconSSTCameraVideo className={baseClassName} {...otherProps} />;
    case "sst_cast":
      return <AWIconSSTCast className={baseClassName} {...otherProps} />;
    case "streamhub_source":
      return <AWIconStreamhub className={baseClassName} {...otherProps} />;
    case "srt_cast":
      return <AWIconSRTCast className={baseClassName} {...otherProps} />;
    case "srt_input":
      return <AWIconSRTInput className={baseClassName} {...otherProps} />;
    case "toggle_on":
      return <AWIconToggleOn className={baseClassName} {...otherProps} />;
    case "toggle_off":
      return <AWIconToggleOff className={baseClassName} {...otherProps} />;
    case "ts_cast":
      return <AWIconTSCast className={baseClassName} {...otherProps} />;
    case "ts_input":
      return <AWIconTSInput className={baseClassName} {...otherProps} />;
    case "video_return":
      return <AWIconVideoReturn className={baseClassName} {...otherProps} />;
    case "video_return_off":
      return <AWIconVideoReturnOff className={baseClassName} {...otherProps} />;
    case "video_return_profile":
      return (
        <AWIconVideoReturnProfile className={baseClassName} {...otherProps} />
      );
    case "video_return_profile_off":
      return (
        <AWIconVideoReturnProfileOff
          className={baseClassName}
          {...otherProps}
        />
      );
    case "uhd":
      return <AWIconUHD className={baseClassName} {...otherProps} />;
      case "webrtc_input":
        return <AWIconWebRTCInput className={baseClassName} {...otherProps} />;
    default:
      const faClass = name ? FontAwesomeMap[name as AWIconName] : null;
      const biClass = name ? BootstrapMap[name as AWIconName] : null;
      return (
        <i
          className={`${baseClassName}${
            biClass ? " " + biClass : faClass ? " " + faClass : ""
          }`}
          {...otherProps}
        />
      );
  }
};

export default AWIcon;
