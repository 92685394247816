import {
	OutputHLSProfile,
	OutputRTMPProfile,
	OutputRTSPProfile,
	OutputSRTProfile,
	OutputSSTProfile,
} from "../components/forms/forms.types";

// HLS
export const outputHlsLocalURIs = (
	localIps: string[],
	ipProfile: OutputHLSProfile
) => {
	return localIps.map(
		(localIp) => `http://${localIp}:8888/hls/${ipProfile.dir}/playlist.m3u8`
	);
};

export const outputHlsPublicURI = (
	publicIp: string,
	ipProfile: OutputHLSProfile
) => {
	return `http://${publicIp}:8888/hls/${ipProfile.dir}/playlist.m3u8`;
};

// RTMP
export const outputRtmpPushURI = (ipProfile: OutputRTMPProfile) => {
	return `${ipProfile.uri}/${ipProfile.streamKey}`;
};

export const outputRtmpPullLocalURIs = (
	localIps: string[] | undefined,
	ipProfile: OutputRTMPProfile
) => {
	return localIps?.map(
		(localIp) => `rtmp://${localIp}:1935/live/${ipProfile.streamName}`
	);
};

export const outputRtmpPullPublicURI = (
	publicIp: string | undefined,
	ipProfile: OutputRTMPProfile
) => {
	return `rtmp://${publicIp}:1935/live/${ipProfile.streamName}`;
};

export const outputRtspLocalURIs = (
	localIps: string[],
	ipProfile: OutputRTSPProfile,
	rtspServer: { port: number }
) => {
	return localIps.map(
		(localIp: string) =>
			`rtsp://${localIp}:${rtspServer ? rtspServer.port : ""}/${
				ipProfile.label
			}`
	);
};

export const outputRtspPublicURI = (
	publicIp: string,
	ipProfile: OutputRTSPProfile,
	rtspServer: { port: number }
) => {
	return `rtsp://${publicIp}:${rtspServer ? rtspServer.port : ""}/${
		ipProfile.label
	}`;
};

export const outputSrtPullLocalURIs = (
	localIps: string[] | undefined,
	ipProfile: OutputSRTProfile
) => {
	return localIps?.map((localIp) => `srt://${localIp}:${ipProfile.port}`);
};

export const outputSrtPullPublicURI = (
	publicIp: string | undefined,
	ipProfile: OutputSRTProfile
) => {
	return `srt://${publicIp}:${ipProfile.port}`;
};

export const outputSafestreamsURI = (ipProfile: OutputSSTProfile) => {
  return `udp://${ipProfile.ipAddress}:${ipProfile.port}/${ipProfile.destChannel}`;
};

export const outputSrtPushURI = (ipProfile: OutputSRTProfile) => {
	return ipProfile.uri;
};
//
// export const outputWebRTCUri = (ipProfile) => {
//   return ipProfile.uriStunServer ? ipProfile.uriStunServer : '';
// };
