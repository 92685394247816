import React, { FunctionComponent, ReactNode } from "react";

interface AWFooterProps {
  leftInfo?: ReactNode;
  rightInfo?: ReactNode;
  [x: string]: any;
}

const AWFooter: FunctionComponent<AWFooterProps> = ({ className, leftInfo, rightInfo, ...otherProps}) => (
  <footer {...otherProps} className={ `aw-footer${otherProps ? ' ' + className : ''}` }>
    {leftInfo && <div>{leftInfo}</div>}
    {rightInfo && <div className="ml-auto">{rightInfo}</div>}
  </footer>
);

export default AWFooter;
