import React, {FunctionComponent} from "react"

const AWIconAIR: FunctionComponent<{ [prop: string]: any }> = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg"
             version="1.1"
             id="Layer_1"
             x="0px"
             y="0px"
             viewBox="0 0 390.73499 191.66299"
             enableBackground="new 0 0 1024 1024"
             {...props}>
            <g id="g1075"
               transform="translate(-316.632,-416.168)">
                <path
                    d="m 694.623,463.43 -6.742,-32.704 c 0,0 -1.402,-4.699 -3.688,-6.956 -3.646,-3.601 -9.403,-3.601 -9.403,-3.601 h -63.404 v 43.261 z"
                    id="path1061"
                    style={{
                        fill: '#ffffff',
                        fillOpacity: 1
                    }}/>
                <path
                    d="m 503.005,556.244 c -3.196,0 -5.797,2.601 -5.797,5.798 0,3.195 2.601,5.796 5.797,5.796 3.196,0 5.797,-2.601 5.797,-5.796 0,-3.197 -2.601,-5.798 -5.797,-5.798 z"
                    id="path1063"
                    style={{
                        fill: '#ffffff',
                        fillOpacity: 1
                    }}/>
                <path
                    d="m 465.118,556.244 c -3.197,0 -5.797,2.601 -5.797,5.798 0,3.195 2.6,5.796 5.797,5.796 3.195,0 5.797,-2.601 5.797,-5.796 0,-3.197 -2.602,-5.798 -5.797,-5.798 z"
                    id="path1065"
                    style={{
                        fill: '#ffffff',
                        fillOpacity: 1
                    }}/>
                <path
                    d="m 550.732,550.126 c -8.164,0 -14.806,-6.642 -14.806,-14.805 v -57.086 c 0,-8.164 6.642,-14.806 14.806,-14.806 h 44.201 V 420.168 H 349.211 c 0,0 -5.757,0 -9.403,3.601 -2.286,2.257 -3.688,6.956 -3.688,6.956 L 322.354,497.5 c 0,0 -1.722,10.8 -1.722,14.5 0,3.647 1.68,14.294 1.68,14.294 l 11.797,53.653 c 0,0 1.325,10.619 7.301,16.972 4.758,5.057 16.332,6.912 16.332,6.912 h 273.28 v -53.705 z m -169.779,21.865 c 0,2.444 -1.988,4.433 -4.433,4.433 h -19.899 c -2.444,0 -4.433,-1.988 -4.433,-4.433 v -19.899 c 0,-2.444 1.988,-4.433 4.433,-4.433 h 19.899 c 2.444,0 4.433,1.988 4.433,4.433 z m 34.606,18.402 c -11.801,0 -21.367,-9.565 -21.367,-21.367 0,-11.8 9.566,-21.366 21.367,-21.366 11.801,0 21.367,9.566 21.367,21.366 0,11.801 -9.567,21.367 -21.367,21.367 z M 479.5,571.991 c 0,2.444 -1.989,4.433 -4.433,4.433 h -19.899 c -2.444,0 -4.434,-1.988 -4.434,-4.433 v -19.899 c 0,-2.444 1.989,-4.433 4.434,-4.433 h 19.899 c 2.443,0 4.433,1.988 4.433,4.433 z m 4.787,-46.117 c 0,2.017 -1.635,3.65 -3.65,3.65 H 370.221 c -2.016,0 -3.65,-1.634 -3.65,-3.65 v -79.566 c 0,-2.016 1.635,-3.65 3.65,-3.65 h 110.416 c 2.016,0 3.65,1.635 3.65,3.65 z m 33.1,46.117 c 0,2.444 -1.989,4.433 -4.433,4.433 h -19.899 c -2.444,0 -4.433,-1.988 -4.433,-4.433 v -19.899 c 0,-2.444 1.988,-4.433 4.433,-4.433 h 19.899 c 2.443,0 4.433,1.988 4.433,4.433 z"
                    id="path1067"
                    style={{
                        fill: '#ffffff',
                        fillOpacity: 1
                    }}/>
                <path
                    d="m 366.571,556.244 c -3.196,0 -5.797,2.601 -5.797,5.798 0,3.195 2.601,5.796 5.797,5.796 3.196,0 5.797,-2.601 5.797,-5.796 0,-3.197 -2.6,-5.798 -5.797,-5.798 z"
                    id="path1069"
                    style={{
                        fill: '#ffffff',
                        fillOpacity: 1
                    }}/>
                <path
                    d="m 552.377,533.676 h 147.688 l 1.623,-7.382 c 0,0 1.679,-10.646 1.679,-14.294 0,-3.7 -1.722,-14.5 -1.722,-14.5 l -3.632,-17.62 H 552.377 Z"
                    id="path1071"
                    style={{
                        fill: '#ffffff',
                        fillOpacity: 1
                    }}/>
                <path
                    d="m 647.473,603.831 h 18.785 c 0,0 11.575,-1.855 16.332,-6.912 5.977,-6.353 7.302,-16.972 7.302,-16.972 l 6.557,-29.821 h -48.976 z"
                    id="path1073"
                    style={{
                        fill: '#ffffff',
                        fillOpacity: 1
                    }}/>
            </g>
        </svg>
    )
}

export default AWIconAIR