import { BehaviorSubject, Observable } from 'rxjs'
import { AWRxStoreDataMap } from './rx-basic-store';

class AWRxCollectionStore {
  private subjectMap: AWRxStoreDataMap = {};
  private collectionMap: { [key: string]: any[] } = {};
  private readonly maxCollectionSize: number;

  constructor(maxCollectionSize?: number){
    this.maxCollectionSize = maxCollectionSize ? maxCollectionSize : 10; // 10 items by collection by default
  }

  getObservable(subjectKey: string, initialData?: any[]): Observable<any> {
    if(!this.subjectMap[subjectKey]){
      this.subjectMap[subjectKey] = new BehaviorSubject<any[]>(initialData ?? []);
      this.collectionMap[subjectKey] = initialData ?? [];
    }
    return this.subjectMap[subjectKey];
  }

  appendDataToSubject(subjectKey: string, data: any) {
    if(!this.subjectMap[subjectKey]){
      this.subjectMap[subjectKey] = new BehaviorSubject<any[]>([]);
      this.collectionMap[subjectKey] = [];
    }
    this.collectionMap[subjectKey] = this.collectionMap[subjectKey].slice(this.collectionMap[subjectKey].length > this.maxCollectionSize - 1 ? 1 : 0);
    this.collectionMap[subjectKey].push(data);
    this.subjectMap[subjectKey].next(this.collectionMap[subjectKey]);
  }

  clearData(subjectKey: string) {
    delete this.subjectMap[subjectKey];
    delete this.collectionMap[subjectKey];
  }
}

export default AWRxCollectionStore;