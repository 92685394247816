import React, { FunctionComponent, useCallback, useEffect, useMemo } from 'react';
import { Bar, CartesianGrid, ComposedChart, Legend, Line, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import Moment from "moment";
import {
  AWBitrateGraphProps,
  AWBitrateDataPoint,
  AWDefaultGraphColors,
  getGraphRandomColor,
  GRAPH_TOTAL, AWGraphStyle
} from './index';

const AWBitrateGraphRecharts: FunctionComponent<AWBitrateGraphProps> = ({
                                                                        axis,
                                                                        data,
                                                                        linkInterfaceNames,
                                                                        legend,
                                                                        margins,
                                                                        style,
                                                                        audio= false,
                                                                        audioDrops= false,
                                                                        video= false,
                                                                        videoDrops= false,
                                                                        mpegtsUp = false,
                                                                        linkRx = false,
                                                                        linkRxDrops = false,
                                                                        linkTx = false,
                                                                        linkTxDrops = false,
                                                                          ...otherProps
                                                                      }) => {
  const finalStyleOption: AWGraphStyle = useMemo(() => {
      // No need to give total color,
      let linkRxBitrateColors: AWGraphStyle['linkRxBitrateColors'] = { TOTAL: ''};
      let linkRxDroppedPacketsColors: AWGraphStyle['linkRxDroppedPacketsColors'] = { TOTAL: ''};
      let linkTxBitrateColors: AWGraphStyle['linkTxBitrateColors'] = { TOTAL: ''};
      let linkTxDroppedPacketsColors: AWGraphStyle['linkTxDroppedPacketsColors'] = { TOTAL: ''};
      if(linkInterfaceNames){
        linkInterfaceNames.forEach(interfaceName => {
          linkRxBitrateColors[interfaceName] = getGraphRandomColor();
          linkRxDroppedPacketsColors[interfaceName] = getGraphRandomColor();
          linkTxBitrateColors[interfaceName] = getGraphRandomColor();
          linkTxDroppedPacketsColors[interfaceName] = getGraphRandomColor();
        });
      }
      return {
        ...AWDefaultGraphColors,
        linkRxBitrateColors: {
          ...linkRxBitrateColors,
          ...AWDefaultGraphColors.linkRxBitrateColors,
        },
        linkRxDroppedPacketsColors: {
          ...linkRxDroppedPacketsColors,
          ...AWDefaultGraphColors.linkRxDroppedPacketsColors,
        },
        linkTxBitrateColors: {
          ...linkTxBitrateColors,
          ...AWDefaultGraphColors.linkTxBitrateColors,
        },
        linkTxDroppedPacketsColors: {
          ...linkTxDroppedPacketsColors,
          ...AWDefaultGraphColors.linkTxDroppedPacketsColors,
        },
        ...style,
      }
    },
    //eslint-disable-next-line
    [style]
  );

  const tickFormatter = useCallback((tick: number) => Moment(tick).format('HH:mm'), []);

  useEffect(() => {
    if(!linkInterfaceNames && (linkRx || linkRxDrops || linkTx || linkTxDrops)){
      console.warn('[Graph] No network interface names defined: you need to define interface names to display link Graph Lines');
    }
  }, [linkInterfaceNames, linkRx, linkRxDrops, linkTx, linkTxDrops]);

  const linkRxDataKeyBuilder = useCallback((interfaceName: string) => {
    if(interfaceName === GRAPH_TOTAL){
      return (datapoint: AWBitrateDataPoint): number | null => {
        if(!datapoint.link) {
          return null;
        }
        return datapoint.link.reduce((acc, nextValue) => {
          acc += nextValue.rx_bitrate;
          return acc;
        }, 0);
      }
    }
    return (datapoint: AWBitrateDataPoint): number | null => {
      if(!datapoint.link) {
        return null;
      }
      const link = datapoint.link.find(link => link.name === interfaceName);
      if(!link) {
        return null;
      }
      return link.rx_bitrate;
    }
  }, []);

  const linkTxDataKeyBuilder = useCallback((interfaceName: string) => {
    if(interfaceName === GRAPH_TOTAL){
      return (datapoint: AWBitrateDataPoint): number | null => {
        if(!datapoint.link) {
          return null;
        }
        return datapoint.link.reduce((acc, nextValue) =>{
          acc += nextValue.tx_bitrate;
          return acc;
        }, 0);
      }
    }
    return (datapoint: AWBitrateDataPoint): number | null => {
      if(!datapoint.link) {
        return null;
      }
      const link = datapoint.link.find(link => link.name === interfaceName);
      if(!link) {
        return null;
      }
      return link.tx_bitrate;
    }
  }, []);

  const linkRxDroppedPacketsDataKeyBuilder = useCallback((interfaceName: string) => {
    if(interfaceName === GRAPH_TOTAL){
      return (datapoint: AWBitrateDataPoint): number | null => {
        if(!datapoint.link) {
          return null;
        }
        return datapoint.link.reduce((acc, nextValue) =>{
          acc += nextValue.rx_lost_nb_packets_current;
          return acc;
        }, 0);
      }
    }
    return (datapoint: AWBitrateDataPoint): number | null => {
      if(!datapoint.link) {
        return null;
      }
      const link = datapoint.link.find(link => link.name === interfaceName);
      if(!link) {
        return null;
      }
      return link.rx_lost_nb_packets_current;
    }
  }, []);

  const linkTxDroppedPacketsDataKeyBuilder = useCallback((interfaceName: string) => {
    if(interfaceName === GRAPH_TOTAL){
      return (datapoint: AWBitrateDataPoint): number | null => {
        if(!datapoint.link) {
          return null;
        }
        return datapoint.link.reduce((acc, nextValue) =>{
          acc += nextValue.tx_lost_nb_packets;
          return acc;
        }, 0);
      }
    }
    return (datapoint: AWBitrateDataPoint): number | null => {
      if(!datapoint.link) {
        return null;
      }
      const link = datapoint.link.find(link => link.name === interfaceName);
      if(!link) {
        return null;
      }
      return link.tx_lost_nb_packets;
    }
  }, []);

  return (
      <ResponsiveContainer>
        <ComposedChart
          {...otherProps}
          data={data}
          margin={{
            top: 5,
            right: 15,
            left: 15,
            bottom: 5,
            ...margins
          }}
        >
          <CartesianGrid stroke={finalStyleOption.gridColor} />
          { legend &&
          <Legend layout={legend.layout}
                  verticalAlign={legend.verticalAlign}
                  align={legend.align}
                  height={36}/>
          }
          <XAxis dataKey="timestamp"
                 scale="time"
                 type="number"
                 domain={['auto', 'auto']}
                 interval={60}
                 tickFormatter={tickFormatter}
                 tickSize={0}
                 tick={{ fill: finalStyleOption.textColor, fontSize: finalStyleOption.textFontSize }}
                 stroke={finalStyleOption.gridColor}/>
          <YAxis yAxisId="bitrate"
                 name={axis?.bitrateLabel}
                 tickSize={0}
                 tick={{ fill: finalStyleOption.textColor, fontSize: finalStyleOption.textFontSize }}
                 stroke={finalStyleOption.gridColor}/>
          <YAxis yAxisId="droppedPackets"
                 name={axis?.droppedPacketsLabel}
                 orientation="right"
                 tickSize={0}
                 tick={{ fill: finalStyleOption.textColor, fontSize: finalStyleOption.textFontSize }}
                 stroke={finalStyleOption.gridColor}/>
          {/* BITRATE */}
          { audio &&
          <Line type="linear"
                legendType="circle"
                name={legend?.audioLabel}
                isAnimationActive={false}
                dataKey="audio.0.rx_bitrate"
                stroke={finalStyleOption.audioBitrateColor}
                yAxisId="bitrate"
                dot={false} />
          }
          { video &&
          <Line type="linear"
                legendType="circle"
                name={legend?.videoLabel}
                isAnimationActive={false}
                dataKey="video.0.rx_bitrate"
                stroke={finalStyleOption.videoBitrateColor}
                yAxisId="bitrate"
                dot={false} />
          }
          { mpegtsUp &&
          <Line type="linear"
                legendType="circle"
                name={legend?.mpegtsUpLabel}
                isAnimationActive={false}
                dataKey="mpegtsUp.0.rx_bitrate"
                stroke={finalStyleOption.mpegtsUpBitrateColor}
                yAxisId="bitrate"
                dot={false} />
          }
          { linkRx && linkInterfaceNames && linkInterfaceNames.map((interfaceName) => (
            <Line key={interfaceName}
                  type="linear"
                  legendType="circle"
                  name={interfaceName}
                  isAnimationActive={false}
                  dataKey={linkRxDataKeyBuilder(interfaceName)}
                  stroke={finalStyleOption.linkRxBitrateColors[interfaceName] ? finalStyleOption.linkRxBitrateColors[interfaceName] : getGraphRandomColor()}
                  yAxisId="bitrate"
                  dot={false} />
          ))}
          { linkTx && linkInterfaceNames && linkInterfaceNames.map((interfaceName) => (
            <Line key={interfaceName}
                  type="linear"
                  legendType="circle"
                  name={interfaceName}
                  isAnimationActive={false}
                  dataKey={linkTxDataKeyBuilder(interfaceName)}
                  stroke={finalStyleOption.linkTxBitrateColors[interfaceName] ? finalStyleOption.linkTxBitrateColors[interfaceName] : getGraphRandomColor()}
                  yAxisId="bitrate"
                  dot={false} />
          ))}
          {/* DROPS */}
          { audioDrops &&
          <Bar layout="vertical"
               legendType="circle"
               name={legend?.audioDroppedPacketsLabel}
               isAnimationActive={false}
               dataKey="audio.0.rx_dropped_packets"
               fill={finalStyleOption.audioDroppedPacketsColor}
               yAxisId="droppedPackets"
               barSize={5}/>
          }
          { videoDrops &&
          <Bar layout="vertical"
               legendType="circle"
               name={legend?.videoDroppedPacketsLabel}
               isAnimationActive={false}
               dataKey="video.0.rx_dropped_packets"
               fill={finalStyleOption.videoDroppedPacketsColor}
               yAxisId="droppedPackets"
               barSize={5}/>
          }
          { linkRxDrops && linkInterfaceNames && linkInterfaceNames.map((interfaceName) => (
            <Bar key={interfaceName}
                 layout="vertical"
                 legendType="circle"
                 name={interfaceName}
                 isAnimationActive={false}
                 dataKey={linkRxDroppedPacketsDataKeyBuilder(interfaceName)}
                 fill={finalStyleOption.linkRxDroppedPacketsColors[interfaceName] ? finalStyleOption.linkRxDroppedPacketsColors[interfaceName] : getGraphRandomColor()}
                 yAxisId="droppedPackets"
                 barSize={5}/>
          ))}
          { linkTxDrops && linkInterfaceNames && linkInterfaceNames.map((interfaceName) => (
            <Bar key={interfaceName}
                 layout="vertical"
                 legendType="circle"
                 name={interfaceName}
                 isAnimationActive={false}
                 dataKey={linkTxDroppedPacketsDataKeyBuilder(interfaceName)}
                 fill={finalStyleOption.linkTxDroppedPacketsColors[interfaceName] ? finalStyleOption.linkTxDroppedPacketsColors[interfaceName] : getGraphRandomColor()}
                 yAxisId="droppedPackets"
                 barSize={5}/>
          ))}
        </ComposedChart>
      </ResponsiveContainer>
  )
}

export default AWBitrateGraphRecharts;