import React, { FunctionComponent } from "react";

const AWIconReboot: FunctionComponent<{ [prop: string]: any }> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...props}>
      <path d="M7.5 1v7h1V1h-1z" />
      <path d="M3 8.8C3 7 4 5.3 5.6 4.4l-.5-.8c-2.9 1.6-3.9 5.3-2.3 8.2s5.3 3.9 8.2 2.3 3.9-5.3 2.3-8.2c-.5-1-1.3-1.7-2.2-2.3l-.5.9c2.4 1.4 3.2 4.4 1.8 6.8S8 14.5 5.6 13.1C4 12.2 3 10.6 3 8.8z" />
      <path d="M11.2 7l2.5-3c.1-.1.1-.3 0-.4 0 0-.1-.1-.2-.1h-3.1c-.1 0-.3.1-.3.2l.5 3c0 .1.2.2.3.2.2.2.3.1.3.1z" />
    </svg>
  )
};

export default AWIconReboot;
