import React, {FunctionComponent} from "react"

const AWIconToggleOff: FunctionComponent<{ [prop: string]: any }> = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 600 300"
            enableBackground="new 0 0 600 300"
             { ...props }>
            <path
                    d="M 3.00,150.00
                    C 3.00,66.90 69.90,0.00 153.00,0.00
                      153.00,0.00 447.00,0.00 447.00,0.00
                      530.10,0.00 597.00,66.90 597.00,150.00
                      597.00,233.10 530.10,300.00 447.00,300.00
                      447.00,300.00 153.00,300.00 153.00,300.00
                      69.90,300.0 3.00,233.10 3.00,150.00 Z
                    M 350.00,150.00
                    C 350.00,202.00 392.00,244.00 444.00,244.00
                      496.00,244.00 538.00,202.00 538.00,150.00
                      538.00,98.00 496.00,56.00 444.00,56.00
                      392.00,56.00 350.04,98.00 350.00,150.00 Z
                    M 132.50,91.00
                    C 101.80,91.00 77.00,116.00 77.00,147.00
                      77.00,178.00 101.80,203.00 132.50,203.00
                      163.20,203.00 188.00,178.00 188.00,147.00
                      188.00,116.00 163.20,91.00 132.50,91.00 Z
                    M 132.50,109.00
                    C 153.80,109.00 171.00,126.00 171.00,147.00
                      171.00,168.00 153.80,185.00 132.50,185.00
                      111.20,185.00 94.00,168.00 94.00,147.00
                      94.00,126.00 111.20,109.00 132.50,109.00 Z
                    M 206.00,203.00 225.00,203.00 225.00,133.00
                      303.00,208.00 303.00,91.00 284.00,91.00
                      284.00,161.00 206.00,86.00 Z" />
        </svg>
    )
}

export default AWIconToggleOff